import styled from 'styled-components';

export const InitTermsWrap = styled.div`
    width: 1100px;
    h2 button th td {
        margin: 0;
        padding: 0;
    }

    .toastui-editor-defaultUI-toolbar {
        background-color: rgb(238, 238, 238);
    }
`;
