import * as React from 'react'
import { Box, Button, FormControlLabel, Input , Checkbox} from '@mui/material';
import McTypography from 'carManagement/component/McTypography';

interface CarPrfomncChckUrlProps {
	carJagaImgUse:string,
	handleCarInfoData : (name:string) => (e:any) => void ,
	//handleCarJagaImageModal : (preview?:string) => (e:any) => void
} 

/* 성능점검표 */
const CarPrfomncChckImage = ({carJagaImgUse , handleCarInfoData }:CarPrfomncChckUrlProps) => {

	return (
		<>
			<Box sx={{ width: '100%', minWidth: 180 ,mb : 2 , borderBottom : 1}}>
				<McTypography variant='h5' text='성능점검기록부 이미지 등록'/>
				<FormControlLabel
						sx={{mt : 1 , mb : 1 , minWidth: 120}}
						control={
							<Checkbox 
								checked={Boolean(carJagaImgUse === '1')}
								onChange={handleCarInfoData('carJagaImgUse')}
								value={carJagaImgUse === '1' ? '0' : '1'}
							/>
						} 
					label={'상태진단 기록부를 이미지로 등록할 경우 체크하세요'}
					/>
			</Box>
			<Box sx={{backgroundColor : '#F9F9F9'}}>
				<Box sx={{ml:1 , mt:3 , mb:3}}>
				{/* <label htmlFor="contained-button-file"> */}
					<input 
						type="file"
						multiple={false}
						disabled={Boolean(carJagaImgUse === '0')}
						accept="image/jpg, image/png, image/jpeg"
						onChange={handleCarInfoData('carJagaImg')}
					/>
					
				{/* </label> */}
				</Box>
				{/* <Box sx={{mb:1}}>
					<Button onClick={handleCarJagaImageModal('')}>미리보기</Button>
				</Box> */}
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 첨부 이미지는 JPG, PNG, GIF파일 포맷을 가진 이미지만 업로드가 가능합니다.'}
						variant={'subtitle2'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 성능점검 기록부를 이미지로 등록하실 경우 2단계 상태의 상태진단기록으로 이동하지 않고 등록이 완료됩니다.'}
						variant={'subtitle2'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 성능점검 기록부를 URL과 혼합하여 사용할 수 없습니다.'}
						variant={'subtitle2'}
					/>
				</Box>
			</Box>
		</>
	)
}

export default CarPrfomncChckImage;