import React, { useState } from 'react';
import Title from 'home/Title';
import styled from 'styled-components';
import MeetchaEditor from './MeetchaEditor';
import { customAxios, testAxios } from 'axios/customAxios';
import { InitTermsWrap } from './styled/TermsManagementStyled';
import { Modal, Box, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';
import { dateToRegDateTime } from 'util/Util';

interface TermsProps {}

interface ToolTipProps {
    tip: string;
    required?: boolean;
}

interface TermsInfo {
    number: number;
    confName: string;
    confOutType: string;
    confValue: string;
    modDate: number;
    regDate: number;
}

const ToolTip = ({ tip, required }: ToolTipProps) => {
    return (
        <div
            style={{
                fontSize: 11,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247, 244, 244)',
                padding: '10px 10px',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    fontSize: 1,
                    color: '#FFFFFF',
                    padding: '4px 7px 2px 7px',
                    marginRight: 10,
                    backgroundColor: '#9ab341e6',
                    borderRadius: 4,
                }}
            >
                짧은설명
            </div>
            <div style={{ color: '#989898', marginRight: 10, paddingTop: 3 }}>{tip}</div>
            {required && <div style={{ color: '#ff0000', marginRight: 10, fontWeight: 'bold' }}>*필수</div>}
        </div>
    );
};

const Terms = ({}: TermsProps) => {
    const termsNameArr = [
        { type: 'MemberRegistAgreement', name: '회원약관설정' },
        { type: 'MemberRegistPrivate', name: '개인정보 보호정책 설정' },
        { type: 'MarketingAgreement', name: '마케팅 수신동의 약관' },
    ];

    const [memberRegistAgreement, setMemberRegistAgreement] = useState<Array<TermsInfo>>();
    const [memberRegistPrivate, setMemberRegistPrivate] = useState<Array<TermsInfo>>();
    const [marketingAgreement, setMarketingAgreement] = useState<Array<TermsInfo>>();

    const [memberRegistAgreementText, setMemberRegistAgreementText] = useState<string>('');
    const [memberRegistPrivateText, setMemberRegistPrivateText] = useState<string>('');
    const [marketingAgreementText, setmarketingAgreementText] = useState<string>('');

    React.useEffect(() => {
        for (let i = 0; i < termsNameArr.length; i++) fetchTerms(termsNameArr[i].type);
    }, []);

    const onMemberRegistAgreementTextChange = (event: any, editor: any) => {
        setMemberRegistAgreementText(editor.getData());
    };
    const onMemberRegistPrivateTextChange = (event: any, editor: any) => {
        setMemberRegistPrivateText(editor.getData());
    };
    const onMarketingAgreementTextChange = (event: any, editor: any) => {
        setmarketingAgreementText(editor.getData());
    };

    const onTermsChange = (e: any, termsType: string, arrNum: number) => {
        if (memberRegistAgreement && memberRegistPrivate && marketingAgreement) {
            switch (termsType) {
                case termsNameArr[0].type:
                    let memberRegistAgreementArr = [...memberRegistAgreement];
                    memberRegistAgreementArr[arrNum].confValue = e.target.value;
                    setMemberRegistAgreement(memberRegistAgreementArr);
                    break;
                case termsNameArr[1].type:
                    let memberRegistPrivateArr = [...memberRegistPrivate];
                    memberRegistPrivateArr[arrNum].confValue = e.target.value;
                    setMemberRegistPrivate(memberRegistPrivateArr);
                    break;
                case termsNameArr[2].type:
                    let marketingAgreementArr = [...marketingAgreement];
                    marketingAgreementArr[arrNum].confValue = e.target.value;
                    setMarketingAgreement(marketingAgreementArr);
                    break;
            }
        }
    };

    const fetchTerms = async (termsType: string) => {
        try {
            const res = await customAxios.get(`/meetcha/car/manager/getTerms?termsName=${termsType}`);
            if (res.status === 200 && res.data) {
                switch (termsType) {
                    case termsNameArr[0].type:
                        setMemberRegistAgreementText(res.data[termsNameArr[0].type][0].confValue);
                        setMemberRegistAgreement(res.data[termsNameArr[0].type]);
                        break;
                    case termsNameArr[1].type:
                        setMemberRegistPrivateText(res.data[termsNameArr[1].type][0].confValue);
                        setMemberRegistPrivate(res.data[termsNameArr[1].type]);
                        break;
                    case termsNameArr[2].type:
                        setmarketingAgreementText(res.data[termsNameArr[2].type][0].confValue);
                        setMarketingAgreement(res.data[termsNameArr[2].type]);
                        break;
                }
            }
        } catch (error) {
            alert(error);
        }
    };

    const appendTermsFormData = (form: FormData, element: TermsInfo, index: number, editorText: string) => {
        form.append(`happyConfigEntityListVO[${index}].number`, String(element['number']));
        form.append(`happyConfigEntityListVO[${index}].confName`, element['confName']);
        form.append(`happyConfigEntityListVO[${index}].confOutType`, element['confOutType']);
        if (index === 0) {
            form.append(`happyConfigEntityListVO[${index}].confValue`, memberRegistAgreementText);
        } else {
            form.append(`happyConfigEntityListVO[${index}].confValue`, element['confValue']);
        }
        form.append(`happyConfigEntityListVO[${index}].modDate`, dateToRegDateTime(element['modDate']));
        form.append(`happyConfigEntityListVO[${index}].regDate`, dateToRegDateTime(element['regDate']));
    };

    const fetchModifyTerms = async (termsType: string) => {
        if (memberRegistAgreement && memberRegistPrivate && marketingAgreement) {
            try {
                const config = { headers: { 'content-type': 'form-data' } };
                const formData = new FormData();

                switch (termsType) {
                    case termsNameArr[0].type:
                        memberRegistAgreement.forEach((element: TermsInfo, index: number) => {
                            appendTermsFormData(formData, element, index, memberRegistAgreementText);
                        });
                        break;
                    case termsNameArr[1].type:
                        memberRegistPrivate.forEach((element: TermsInfo, index: number) => {
                            appendTermsFormData(formData, element, index, memberRegistPrivateText);
                        });
                        break;
                    case termsNameArr[2].type:
                        marketingAgreement.forEach((element: TermsInfo, index: number) => {
                            appendTermsFormData(formData, element, index, marketingAgreementText);
                        });
                        break;
                }

                const res = await customAxios.post('/meetcha/car/manager/doSaveTerms', formData, config);

                if (res.status === 200 && res.data === 'success') {
                    alert(`${termsNameArr[termsNameArr.findIndex((item) => item.type === termsType)].name} 수정 완료`);
                } else {
                    alert(`${termsNameArr[termsNameArr.findIndex((item) => item.type === termsType)].name} 수정 실패`);
                }
            } catch (error) {
                alert(`${termsNameArr[termsNameArr.findIndex((item) => item.type === termsType)].name} 수정 실패`);
            }
        }
    };

    return (
        <InitTermsWrap>
            <Title>약관관리</Title>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{
                                    width: 200,
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(238, 238, 238)',
                                    textAlign: 'center',
                                }}
                            >
                                {termsNameArr[0].name}
                            </TableCell>
                            <TableCell>
                                <ToolTip tip={'회원가입시 출력될 회원이용약관 내용을 입력하여 설정하세요.'} />
                                <div style={{ marginTop: 20, height: 700 }}>
                                    <MeetchaEditor
                                        editorData={memberRegistAgreementText}
                                        onEditorDataChange={onMemberRegistAgreementTextChange}
                                    />
                                </div>
                                <div style={{ marginTop: 20, display: 'flex' }}>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>버전 : </CustomSpan>
                                        <CustomInput
                                            name="verison"
                                            type="text"
                                            widthSize="55px"
                                            value={memberRegistAgreement ? memberRegistAgreement[1].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[0].type, 1)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>시작일 : </CustomSpan>
                                        <CustomInput
                                            name="startdate"
                                            type="date"
                                            widthSize="120px"
                                            value={memberRegistAgreement ? memberRegistAgreement[2].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[0].type, 2)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>종료일 : </CustomSpan>
                                        <CustomInput
                                            name="enddate"
                                            type="date"
                                            widthSize="120px"
                                            value={memberRegistAgreement ? memberRegistAgreement[3].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[0].type, 3)}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                                    <CustomButton onClick={() => fetchModifyTerms(termsNameArr[0].type)}>
                                        설정을 저장합니다.
                                    </CustomButton>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    width: 200,
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(238, 238, 238)',
                                    textAlign: 'center',
                                }}
                            >
                                {termsNameArr[1].name}
                            </TableCell>
                            <TableCell>
                                <ToolTip tip={'회원가입시 출력될 개인정보보호정책 내용을 입력하여 설정합니다.'} />

                                <div style={{ marginTop: 20, height: 700 }}>
                                    <MeetchaEditor
                                        editorData={memberRegistPrivateText}
                                        onEditorDataChange={onMemberRegistPrivateTextChange}
                                    />
                                </div>
                                <div style={{ marginTop: 20, display: 'flex' }}>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>버전 : </CustomSpan>
                                        <CustomInput
                                            name="verison"
                                            type="text"
                                            widthSize="55px"
                                            value={memberRegistPrivate ? memberRegistPrivate[1].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[1].type, 1)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>시작일 : </CustomSpan>
                                        <CustomInput
                                            name="startdate"
                                            type="date"
                                            widthSize="120px"
                                            defaultValue={0}
                                            value={memberRegistPrivate ? memberRegistPrivate[2].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[1].type, 2)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>종료일 : </CustomSpan>
                                        <CustomInput
                                            name="enddate"
                                            type="date"
                                            widthSize="120px"
                                            value={memberRegistPrivate ? memberRegistPrivate[3].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[1].type, 3)}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                                    <CustomButton onClick={() => fetchModifyTerms(termsNameArr[1].type)}>
                                        설정을 저장합니다.
                                    </CustomButton>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    width: 200,
                                    fontWeight: 'bold',
                                    backgroundColor: 'rgb(238, 238, 238)',
                                    textAlign: 'center',
                                }}
                            >
                                {termsNameArr[2].name}
                            </TableCell>
                            <TableCell>
                                <ToolTip tip={'마케팅 수신동의 약관 설정'} />
                                <div style={{ marginTop: 20, height: 700 }}>
                                    <MeetchaEditor
                                        editorData={marketingAgreementText}
                                        onEditorDataChange={onMarketingAgreementTextChange}
                                    />
                                </div>
                                <div style={{ marginTop: 20, display: 'flex' }}>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>버전 : </CustomSpan>
                                        <CustomInput
                                            name="version"
                                            type="text"
                                            widthSize="55px"
                                            value={marketingAgreement ? marketingAgreement[1].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[2].type, 1)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>시작일 : </CustomSpan>
                                        <CustomInput
                                            name="startdate"
                                            type="date"
                                            widthSize="120px"
                                            defaultValue={0}
                                            value={marketingAgreement ? marketingAgreement[2].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[2].type, 2)}
                                        />
                                    </div>
                                    <div style={{ marginRight: 25 }}>
                                        <CustomSpan>종료일 : </CustomSpan>
                                        <CustomInput
                                            name="enddate"
                                            type="date"
                                            widthSize="120px"
                                            value={marketingAgreement ? marketingAgreement[3].confValue : ''}
                                            onChange={(e: any) => onTermsChange(e, termsNameArr[2].type, 3)}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                                    <CustomButton onClick={() => fetchModifyTerms(termsNameArr[2].type)}>
                                        설정을 저장합니다.
                                    </CustomButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </InitTermsWrap>
    );
};

export default Terms;

const CustomSpan = styled.span`
    color: #000000;
    text-align: center;
    font-size: 13.5px;
    line-height: 0;
`;

const CustomInput = styled.input.attrs(
    (props: { widthSize: string; minWidthSize: string; isVisible: boolean }) => props
)`
    height: 26.5px;
    width: ${(props) => props.widthSize};
    min-width: ${(props) => props.minWidthSize};
    margin-right: 10px;
    border: 1px solid #bdbdc0;
    background: #f3f3f3;
    :focus {
        outline: ${(props) => (props.isVisible ? 'none' : undefined)};
    }
`;

const CustomButton = styled.button`
    padding: 10px 18px;
    background: linear-gradient(to bottom, #a5a5a5, #000000);
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    outline: 0;
    cursor: pointer;
`;

// switch (termsType) {
//     case termsNameArr[0].type:
//         memberRegistAgreement.forEach((element: TermsInfo, index: number) => {
//             formData.append(`happyConfigEntityListVO[${index}].number`, String(element['number']));
//             formData.append(`happyConfigEntityListVO[${index}].confName`, element['confName']);
//             formData.append(`happyConfigEntityListVO[${index}].confOutType`, element['confOutType']);
//             if (index === 0) {
//                 formData.append(
//                     `happyConfigEntityListVO[${index}].confValue`,
//                     memberRegistAgreementText
//                 );
//             } else {
//                 formData.append(`happyConfigEntityListVO[${index}].confValue`, element['confValue']);
//             }
//             formData.append(
//                 `happyConfigEntityListVO[${index}].modDate`,
//                 dateToRegDateTime(element['modDate'])
//             );
//             formData.append(
//                 `happyConfigEntityListVO[${index}].regDate`,
//                 dateToRegDateTime(element['regDate'])
//             );
//         });
//         break;
//     case termsNameArr[1].type:
//         memberRegistPrivate.forEach((element: TermsInfo, index: number) => {
//             formData.append(`happyConfigEntityListVO[${index}].number`, String(element['number']));
//             formData.append(`happyConfigEntityListVO[${index}].confName`, element['confName']);
//             formData.append(`happyConfigEntityListVO[${index}].confOutType`, element['confOutType']);
//             if (index === 0) {
//                 formData.append(`happyConfigEntityListVO[${index}].confValue`, memberRegistPrivateText);
//             } else {
//                 formData.append(`happyConfigEntityListVO[${index}].confValue`, element['confValue']);
//             }
//             formData.append(
//                 `happyConfigEntityListVO[${index}].modDate`,
//                 dateToRegDateTime(element['modDate'])
//             );
//             formData.append(
//                 `happyConfigEntityListVO[${index}].regDate`,
//                 dateToRegDateTime(element['regDate'])
//             );
//         });
//         break;
//     case termsNameArr[2].type:
//         marketingAgreement.forEach((element: TermsInfo, index: number) => {
//             formData.append(`happyConfigEntityListVO[${index}].number`, String(element['number']));
//             formData.append(`happyConfigEntityListVO[${index}].confName`, element['confName']);
//             formData.append(`happyConfigEntityListVO[${index}].confOutType`, element['confOutType']);
//             if (index === 0) {
//                 formData.append(`happyConfigEntityListVO[${index}].confValue`, marketingAgreementText);
//             } else {
//                 formData.append(`happyConfigEntityListVO[${index}].confValue`, element['confValue']);
//             }
//             formData.append(
//                 `happyConfigEntityListVO[${index}].modDate`,
//                 dateToRegDateTime(element['modDate'])
//             );
//             formData.append(
//                 `happyConfigEntityListVO[${index}].regDate`,
//                 dateToRegDateTime(element['regDate'])
//             );
//         });
//         break;
// }
