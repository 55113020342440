import React from 'react';

export const EnvPath = {
	REACT_APP_MODE : process.env.REACT_APP_MODE ,
	REACT_APP_API_URL : process.env.REACT_APP_API_URL ,
	REACT_APP_CGI_API_URL : process.env.REACT_APP_CGI_API_URL ,
	REACT_APP_NOTICE_URL : process.env.REACT_APP_NOTICE_URL ,
	REACT_APP_EMERGENCY_NOTICE_URL : process.env.REACT_APP_EMERGENCY_NOTICE_URL ,
	REACT_APP_PLAYSTORE_URL : process.env.REACT_APP_PLAYSTORE_URL ,
	REACT_APP_COMPARE_ENCAR_API_URL : process.env.REACT_APP_COMPARE_ENCAR_API_URL ,
	REACT_APP_TERMS_OF_USE_URL : process.env.REACT_APP_TERMS_OF_USE_URL ,
	REACT_APP_PRIVACY_URL : process.env.REACT_APP_PRIVACY_URL ,
	REACT_APP_MARKETING_URL : process.env.REACT_APP_MARKETING_URL ,
	REACT_APP_PROCESS_PASS_URL : process.env.REACT_APP_PROCESS_PASS_URL ,
	REACT_APP_PUSH_API_URL : process.env.REACT_APP_PUSH_API_URL ,
	REACT_APP_CAR_IMG_DOMAIN_URL : process.env.REACT_APP_CAR_IMG_DOMAIN_URL ,
	REACT_APP_WEB_URL : process.env.REACT_APP_WEB_URL ,
}