import * as React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import TermsForm from 'signUp/component/TermsForm';
import TypeByButtonGroup from 'signUp/component/TypeByButtonGroup';
import { Link, useNavigate } from 'react-router-dom';
import { Path } from 'util/path/RoutePath';


interface MembershipTermsProps {

}
const MembershipTerms = () => {

	const isTerms = [
		{title : '이용약관' , gubun : 1 , checkLabel : '회원이용약관 내용에 동의합니다.'},
		{title : '개인정보 보호정책' , gubun : 2 , checkLabel : '개인정보 보호정책에 동의합니다.'},
		//{title : '개인정보 수집ㆍ이용' , gubun : 0 , checkLabel : '개인정보 수집ㆍ이용에 동의합니다.'}
	];

	const navigate = useNavigate();

	const [isTermsChecked , setIsTermsChecked] = React.useState<Array<string>>([]);

	//약관 동의 체크박스 클릭
	const handleIsTerms = (title:string) => () => {
		if(isTermsChecked.some(item => item === title)){
			setIsTermsChecked(isTermsChecked.filter(item => item !== title));
		}else {
			const arr = [...isTermsChecked];
			arr.push(title);
			setIsTermsChecked(arr);
		}
	}

	const handleButton = (group:string) => {
		if(isTermsChecked.length === 2){
			navigate(Path.JOIN_SIGN_UP_PATH , {state : group});
		}else {
			alert('이용약관에 동의하셔야 가입하실 수 있습니다.');
		}
	}
	//약관 동의 체크박스 상태 
	const checkedIsTerms = (title:string) => {
		return isTermsChecked.some(item => item === title);
	}

	const checkedIsTermsAll = () => {
		return isTermsChecked.length === 2
	}

	const handleIsTermsAll = () => {
		if(isTermsChecked.length === 2){
			setIsTermsChecked([]);
		}else {
			let arr:Array<string> = [];
			isTerms.map(item => arr.push(item.title));
			setIsTermsChecked(arr);
		}
	}

	return (
		<Box sx={{mb : 50}}>
			<Box sx={{display : 'flex' , flexDirection : 'row' , justifyContent : 'space-between'}}>
				<h2>
					회원가입 약관동의 
					<span style={{color : '#333' , fontWeight : 'normal' , fontSize : 13}}>회원가입에 필요한 약관을 확인해주세요.</span>
				</h2>
				<FormControlLabel
					control={
						<Checkbox 
							checked={checkedIsTermsAll()}
							onChange={handleIsTermsAll}
							size={'small'}
						/>
					}
					label={<Typography variant='body1'>전체동의</Typography>}
				/>
				{/* <Link to={'/signUp'} state={{name : 'yusuk' , seq : '12' , params : 'abcde'}}>hihi</Link> */}
			</Box>
			<Box sx={{borderTop : 2 , borderBottom : 2 , borderColor : '#AEAEAE' , backgroundColor : '#FAFAFA'}}>
				{
					isTerms.map(item => (
						<TermsForm 
							key={item.title}
							title={item.title}
							gubun={item.gubun}
							checkLabel={item.checkLabel} 
							checked={checkedIsTerms(item.title)} 
							handleCheckBox={handleIsTerms}
						/>
					))
				}
			</Box>
			<Box>
				<TypeByButtonGroup handleButton={handleButton}/>
			</Box>
		</Box>
	)
}
export default MembershipTerms;