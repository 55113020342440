import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import CarCategory from 'carManagement/main/CarCategory';
import CarGubunForm from 'carManagement/main/CarGubun';
import CarRegistration from 'carManagement/main/CarRegistration';
import DealerCarList from 'carManagement/main/DealerCarList';
import CarConfig from 'carManagement/main/CarConfig';
import CarOption from 'carManagement/main/CarOption';
interface CarRegistRouteProps {
    mainRef: any;
}
const CarRegistRoute = ({ mainRef }: CarRegistRouteProps) => {
    return (
        <Routes>
            <Route path="/carRegistration" element={<CarRegistration />} />
            <Route path="/dealerCarList" element={<DealerCarList mainRef={mainRef} />} />
            <Route path="/carGubun" element={<CarGubunForm />} />
            <Route path="/carCategory" element={<CarCategory />} />
            <Route path="/carConfig" element={<CarConfig />} />
            <Route path="/carOption" element={<CarOption />} />
        </Routes>
    );
};
export default CarRegistRoute;
