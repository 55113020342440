import React from 'react';
import { Typography, List, ListItem, ListItemText, Checkbox } from '@mui/material';

interface CarOptionCheckItemProps {
    data: Array<any>;
    name: 'car_option4' | 'car_option3' | 'car_option5' | 'car_option2' | 'car_option8';
    isChecked: (number: string) => boolean;
    onChange: (e: any) => void;
}

/**
 * 상세검색 옵션 설정 아이템(체크박스 + Text)
 * @param CarOptionCheckItemProps
 * @returns
 */
const CarOptionCheckItem = ({ data, name, isChecked, onChange }: CarOptionCheckItemProps) => {
    const headerTitle = () => {
        switch (name) {
            case 'car_option2':
                return '편의사양';
            case 'car_option3':
                return '내장사양';
            case 'car_option4':
                return '외관사양';
            case 'car_option5':
                return '안전사양';
            case 'car_option8':
                return '기타';
        }
    };

    return (
        <div>
            <List sx={{ width: '100%', minWidth: 100, padding: 0 }}>
                <ListItem sx={{ backgroundColor: '#F3F3F3' }}>
                    <ListItemText
                        primary={
                            <Typography align="center" fontWeight={'bold'}>
                                {headerTitle()}
                            </Typography>
                        }
                    />
                </ListItem>
                {data.map((item) => (
                    <ListItem
                        key={item.number + item.title}
                        style={{ border: '1px solid #F3F3F3' }}
                        secondaryAction={
                            <Checkbox
                                edge="start"
                                onChange={onChange}
                                checked={isChecked(String(item.number))}
                                value={String(item.number)}
                                inputProps={{ 'aria-labelledby': 'test' }}
                            />
                        }
                    >
                        <ListItemText
                            sx={{ pl: 1 }}
                            primary={
                                <>
                                    <Typography variant="body2">{item.title}</Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default CarOptionCheckItem;
