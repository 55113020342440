import * as React from 'react';
import axios from 'axios';

import { Box,Pagination,Stack, CircularProgress, Backdrop, Typography} from '@mui/material';

//import * as cookie from "util/cookie";

import DealerCarMenu from 'carManagement/carList/DealerCarMenu';
import McSnackbar from 'carManagement/component/McSnackbar'
import DealerCarItem from 'carManagement/carList/DealerCarItem';
import DealerCarSetting from 'carManagement/carList/DealerCarSetting';
import CarOptionPaymentModal from 'carManagement/carRegistration/CarOptionPaymentModal';
import { debounce } from 'lodash';
import DealerCarDetailModal from 'carManagement/carList/DealerCarDetailModal';
import {carData} from 'interface/CarListInterface'
import McDialog from 'carManagement/component/McDialog';
import DealerCarUpdatePriceModal from 'carManagement/carList/DealerCarUpdatePriceModal';
import { getAllStorageItem} from 'util/sessionStorage';
import { customAxios } from 'axios/customAxios';
import { EnvPath } from 'util/path/EnvPath';

interface DealerCarListProps {
	mainRef : any
}

//딜러 판매차량 리스트
const DealerCarList = ({mainRef}:DealerCarListProps) => {

	/* 토큰 및 dealerId */
	// const token = getStorageItem("token");
	// const userId = getStorageItem('id');
	// const group = getStorageItem('group');

	const {token , id , group} = getAllStorageItem();


	/** 차량 리스트를 담을 state 변수 */
	const [carList , setCarList] = React.useState<Array<carData>>([]);

	/* 차량 카운트를 관리하는 state 변수 */
	const [carCount , setCarCount] = React.useState({
		totalCnt : 0,
		saleCnt : 0,
		saleEndCnt : 0
	});

	/* 페이징에 사용되는 state 변수 */
	const [page , setPage] = React.useState<number>(1);

	//list 몇개씩 불러올지
	const pageSize = 10;
	//const [pageSize , setPageSize] = React.useState<number>(10);

	//유료결제 모달에 넘길 유저 정보
	const [dealerInfo , setDealerInfo] = React.useState({
		userNick : '',
		point : ''
	})

	/* 가격변경 , 노출 전환 , 판매 전환 , 삭제시 사용되는 state 변수 */
	const [postData , setPostData] = React.useState({
		api : '',
		data : '',
		linksNumber : ''
	});

	/* 유튜브 링크 복사시 띄워주는 스낵바 true / false */
	const [isSnackbar , setIsSnackbar] = React.useState<boolean>(false);
	/* api 호출 시 사용되는 로딩바 true / false */
	const [isLoading , setIsLoading] = React.useState<boolean>(false);
	/* 가격 변경을 제외한 다른 메뉴 버튼들 클릭시 안내 dialog true / false */
	const [isDialog , setIsDialog] = React.useState<boolean>(false);
	/* dialog 설명 문구 (메뉴마다 다르기때문에 state 변수로 관리) */
	const [dialogDesc , setDialogDesc] = React.useState<string>('');

	/* 유료결제 모달 true / false */
	const [isCarPaymentModal , setIsCarPaymentModal] = React.useState<boolean>(false);

	/* 차량 수정시 넘길 데이터 state로 관리 */
	const [carDetailData , setCarDetailData] = React.useState<any>(null);
	/* 차량 수정 모달 true / false */
	const [isCarDetailModal , setIsCarDetailModal] = React.useState<boolean>(false);
	
	/* 검색 메뉴 옵션 state */
	const [menuData , setMenuData]  = React.useState<any>({
		saleEnd : 'a',
		isBroadcast : 'a',
		showYn : 'a',
		saleType : 'a',
		searchText : '',
		isSearch : false
	});

	/* 초기화 버튼 관리용 변수 */
	const [isRefresh , setIsRefresh] = React.useState<boolean>(false);

	/* 가격 변경용 모달 true / false */
	const [isCarPriceModal , setIsCarPriceModal] = React.useState<boolean>(false);

	/* Pagination Event */
	const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};
	
	/* search selectBox1 Event */
	const handleMenuChange = (name:string) => (e:any) => { 
		setMenuData({
			...menuData,
			[name] : e.target.value
		})
	};
	
	/* 검색어 처리 이벤트 함수 */
	const handleInputText = (e:any) => {
		//debouncedSearch(e.target.value);
		setMenuData({
			...menuData,
			searchText : e.target.value
		})
	}
	//로딩시 사용되는 함수 
	const handleIsLoading = (state:boolean) =>{
		if(state){
			setIsLoading(true)
		}else {
			fetchCarList('update');
			
			setTimeout(() => {
				setIsLoading(false);
			}, 1500);
		}
	}
	//메뉴 버튼 클릭시 데이터 전달
	const handleIsDialog = (desc:string , api:string , data:string , number:string) => () =>{
		setIsDialog(true);
		setDialogDesc(desc);

		setPostData({
			...postData,
			api : api,
			data : data,
			linksNumber : number
		})
	}

	/* 차량 옵션 변경시 사용되는 api를 사용하는 switch문 (postData 사용)  */
	const handlePostApi = () => {
		setIsDialog(false);
		switch (postData.api) {
			case 'doShow':
				postDoShowSet(postData.data , postData.linksNumber)
				break;
			case 'doSaleEnd':
				postDoSaleEndSet(postData.data, postData.linksNumber)
				break;
			case 'doCarLinks':
				postDoCarLinksDelete(postData.linksNumber)
				break;
			default:
				break;
		}
	}

	/* debounce 사용시 textField value 관련 이슈 */
	//input의 onChange 사용시 잦은 렌더링으로 성능이슈가 생길 수 있으므로 debounce 함수로 딜레이를 걸어줌
	// const debouncedSearch = React.useMemo(() => debounce((text) => {
	// 	// 모든 호출이 아닌 
	// 	// 지정 간격 마다 리턴 값 받아서 state에 담고
	// 	setMenuData({
	// 		...menuData,
	// 		searchText : text
	// 	})
	
	// }, 200), [ menuData ]);

	//검색시 사용되는 이벤트
	const handleSearch = () => {
		if(!menuData.isBtn){
			setMenuData({
				...menuData,
				isSearch : true
			});
		}

		if(page > 1){
			setPage(1);
		}else {
			fetchCarList()
		}
	}
	
	//페이징 계산
	const pagingCount = () => {
		if((carCount.saleCnt % pageSize) > 0){
			return Math.floor(carCount.saleCnt / pageSize) + 1
		}else {
			return Math.floor(carCount.saleCnt / pageSize) 
		}
	}

	/*carList 호출 관련 함수 , page 값에 따라 다르게 처리 */
	const pageCheck = () => {
		if(page === 1){
			fetchCarList();
		}else {
			setPage(1);
		}
	}

	/* alert 처리 함수 */
	const errorAlert = (text:string) => {
		handleIsLoading(false);
		alert(text);
		pageCheck();
	}

	/* 초기화 버튼 클릭 이벤트 함수 */
	const handleRefreshBtn = () => {
		setMenuData({
			...menuData,
			isSearch : false,
			saleEnd : 'a',
			isBroadcast : 'a',
			showYn : 'a',
			saleType : 'a',
			searchText : '',
		});

		setIsRefresh(true);
	}

	/** 차량 리스트 호출 */
	const fetchCarList = async (state?:string) => {		
		const params = {
			params : {
				pageNo : page,
				pageSize : pageSize,
				isBroadcast : menuData.isBroadcast,
				sale_end : menuData.saleEnd === 'a' ? '' : menuData.saleEnd,
				sale_type : menuData.saleType === 'a' ? '' : menuData.saleType,
				search_word : menuData.searchText ? menuData.searchText : '',
				op2_search : menuData.searchText ? 'car_model' : '',
				showYn :  menuData.showYn  === 'a' ? '' : menuData.showYn
			}
		}
		try {

			const response = await customAxios.get('/meetcha/car/manager/list' , params);

			if(response.status === 200){
				if(response.data.apiinfo.status === "00"){					
					setCarList(response.data.data);
					setCarCount({
						...carCount ,
						totalCnt : response.data.apiinfo.sale_cnt,
						saleCnt : response.data.apiinfo.totalcnt,
						saleEndCnt : response.data.apiinfo.sale_end_cnt
					})

					if(!state){
						mainRef.current.scrollTo(0,0);
					}
					
				} else {
					alert(response.data.apiinfo.message);
				}        
			}

		} catch (error) {
			console.log(error);
		}
	}

	/** 딜러 정보 get */
	const fetchDealerInfo = async () => {
		try {
			//const response = await axios.get(`${REACT_APP_API_URL}/meetcha/car/manager/getDealerInfo?dealerId=${id}` , config);
			const response = await customAxios.get(`/meetcha/car/manager/getDealerInfo`);
			if(response.status === 200 && response.data){
				const {userNick , point} = response.data

				setDealerInfo({
					...dealerInfo,
					userNick : userNick,
					point : point
				})
			}
			
		} catch (error) {
			console.log(error);
		}
	}

	/** 노출 및 미노출 상태 변경 */
	const postDoShowSet = async (showYn:string , number:string) => {
		
		handleIsLoading(true);
		try {
			const response = await customAxios.post(`/meetcha/car/manager/doShowYnSet?showYn=${showYn}&linksNumber=${number}`);

			if(response.data === 'success'){
				handleIsLoading(false);
				alert(`${showYn === 'Y' ? '노출' : '미노출'} 상태로 변경되었습니다.`);
			}else {
				errorAlert(`ERROR_CODE : ${response.data.split('-')[1]} \n ${showYn === 'Y' ? '노출' : '미노출'} 상태로 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
				
			}
		} catch (error) {
			errorAlert(`${showYn === 'Y' ? '노출' : '미노출'} 상태로 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			console.log(error);
			
		}
	}
	/** 판매완료 및 판매중 변경 처리 */
	const postDoSaleEndSet = async (saleEnd:string , number:string) => {
		
		handleIsLoading(true);
		try {
			const response = await customAxios.post(`/meetcha/car/manager/doSaleEndSet?saleEnd=${saleEnd}&linksNumber=${number}`)

			if(response.data === 'success'){
				handleIsLoading(false);
				alert(`${saleEnd === '0' ? '판매' : '판매완료'} 상태로 변경되었습니다.`);
			}else {
				errorAlert(`ERROR_CODE : ${response.data.split('-')[1]} \n ${saleEnd === '0' ? '판매' : '판매완료'} 상태로 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			}
		} catch (error) {
			errorAlert(`${saleEnd === '0' ? '판매' : '판매완료'} 상태로 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			console.log(error);
			
		}
	}
	/** 차량 삭제 */
	const postDoCarLinksDelete = async ( number:string) => {
		
		setIsLoading(true);
		try {
			const response = await customAxios.post(`/meetcha/car/manager/doCarLinksDelete?linksNumber=${number}`)

			if(response.data === 'success'){
				pageCheck();

				setTimeout(() => {
					setIsLoading(false);
					alert(`차량이 삭제되었습니다.`)
				}, 1500);
			}else {
				errorAlert(`ERROR_CODE : ${response.data.split('-')[1]} \n 차량 삭제 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			}
		} catch (error) {
			errorAlert(`차량 삭제 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			console.log(error);
			
		}
	}
	/** 가격변경 */
	const postDoPriceSet = async ( price:string , addPrice:string , number:string) => {
		
		handleIsLoading(true);
		try {
			const response = await customAxios.post(`/meetcha/car/manager/doPriceSet?linksNumber=${number}&price=${price}&addPrice=${addPrice}`);

			if(response.data === 'success'){
				handleIsLoading(false);
				alert(`가격변경이 완료되었습니다.`);
			}else {
				errorAlert(`ERROR_CODE : ${response.data.split('-')[1]} \n 가격 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			}
		} catch (error) {
			errorAlert(`가격 변경 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			console.log(error);
			
		}
	}
	 
	/** 유료결제 */
	const postDoPayment = async (linksNumber:number , optNumber:number) => {
		handleIsLoading(true);
		try {
			const response = await customAxios.post(`/meetcha/car/manager/doPayment?linksNumber=${linksNumber}&optDetailNumber=${optNumber}`);
			if(response.data === 'success'){
				handleIsLoading(false);
				setIsCarPaymentModal(false);
				alert(`유료결제가 완료되었습니다.`);
			}else {
				errorAlert(`ERROR_CODE : ${response.data.split('-')[1]} \n 유료결제 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			}
		} catch (error) {
			errorAlert(`유료결제 중에 오류가 발생했습니다 . 관리자에게 문의해주세요.`);
			console.log(error);
		}
	}

	/**  클립보드에 유튜브 링크 복사  */
	const handleYoutubeLink = (link:string) => (e:any) => {
		navigator.clipboard.writeText(link)
		setIsSnackbar(true);
	}

	/** 모달 제어 함수 */
	const handleModal = (carInfo:any , name:string) => () => {
		setCarDetailData(carInfo); 

		switch (name) {
			//유료결제
			case 'carPayment':
				setIsCarPaymentModal(true);
				break;
			//차량수정
			case 'carUpdate':
				setIsCarDetailModal(true);
				break;
			//가격변경
			case 'carPrice':
				setIsCarPriceModal(true);
				break;
			default:
				break;
		}
	}

	/** 가격 변경 모달의 확인 버튼 클릭 이벤트 (api 호출) */
	const handleUpdatePrice = (price:string , addPrice:string , number:string) => {
		setIsCarPriceModal(false);
		postDoPriceSet(price,addPrice,number);
	}
	React.useEffect(() => {
		if(isRefresh){
			pageCheck();
			setIsRefresh(false);
		}
	},[isRefresh])

  	/* 진입시 호출 */
	React.useEffect(() => {
		fetchCarList();
	},[page])

	React.useEffect(() => {
		if(group !== '99'){
			fetchDealerInfo();
		}
	}, [])

  return (
    <div >
		{/* 상단 검색 메뉴 */}
		<DealerCarMenu 
			value={menuData} 
			onChange={handleMenuChange} 
			handleSearch={handleSearch}
			handleInputText={handleInputText}
			handleRefresh={handleRefreshBtn}
		/>
		{/* 차량 수 Text */}
		<Typography variant='h6'>{`검색된차량 : ${carCount.saleCnt}대 (전체등록차량: ${carCount.totalCnt}대 | 판매완료차량: ${carCount.saleEndCnt}대)`}</Typography>
      	{ 
			carList.length > 0 ?
				carList.map((carInfo:any) => (
					<Box
						sx={{
							display: 'flex',
							flexDirection: { xs: 'column', md: 'row' },
							alignItems: 'center',
							bgcolor: 'background.paper',
							overflow: 'hidden',
							borderRadius: '12px',
							boxShadow: 1,
							fontWeight: 'bold',
							marginBottom: 1.5,
						}}
						key={carInfo.number}
					>
						{/* 차량 item UI */}
						<DealerCarItem carInfo={carInfo} handleYoutubeLink={handleYoutubeLink}/>
						{/* 차량 item 우측 버튼 메뉴 */}
						{
							group !== '22' &&
							<DealerCarSetting 
								carInfo={carInfo} 
								handleModal={handleModal}
								handleIsDialog={handleIsDialog}
							/>

						}
					</Box>
        		))
				:
				<Box sx={{display : 'flex' , alignSelf : 'center'}}>차량이 없습니다.</Box>
      	}
		
		{/* 페이징 처리 */}
		<Stack spacing = {2} style= {{alignItems : 'center' , padding : 30}} >
			<Pagination count = {pagingCount()} defaultPage={1} page={page} onChange = {handlePaginationChange} color="secondary" variant = "outlined" showFirstButton showLastButton />   
		</Stack>
		{
			isLoading &&
			/* 로딩바 */
			<Box sx={{ width : '100%' , height : '100%' , display : 'flex' , alignItems : 'center' , justifyContent : 'center' }}>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
					//onClick={handleClose}
					>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Box>
		}
		{
			/* 유튜브 링크 복사시에 사용자에게 보여줄 문구 (snackbar) */
			isSnackbar &&
			<McSnackbar 
				message='클립보드에 복사되었습니다' 
				handleSnackbarClose={() => setIsSnackbar(false)}
			/>
		}
		{
			//유료결제 모달
			isCarPaymentModal &&
			<CarOptionPaymentModal
				userInfo={dealerInfo}
				carInfo={carDetailData}
				isRegistCar={true}
				postDoPayment={postDoPayment}
				onClose={() => setIsCarPaymentModal(false)}
			/>
		}
		{
			//차량상세 모달
			isCarDetailModal &&
			<DealerCarDetailModal 
				data={carDetailData}
				onClose={() => {
					pageCheck();
					setIsCarDetailModal(false);
				}}
			/>
		}
		{
			/* 가격 변경을 제외한 다른 메뉴 버튼의 dialog */
			isDialog &&
			<McDialog 
				title={'안내'}
				description={dialogDesc}
				handleClose={() => setIsDialog(!isDialog)}
				handleConfirm={handlePostApi}
			/>
		}
		{
			/* 가격 변경 모달 */
			isCarPriceModal &&
			<DealerCarUpdatePriceModal 
				carInfo={carDetailData}
				onClose={() => setIsCarPriceModal(false)}
				handleUpdatePrice={handleUpdatePrice}
			/>
		}
	  
    </div>
  );
}

export default DealerCarList;