import * as React from 'react';
import { FormControl , RadioGroup , FormLabel , FormControlLabel , Radio , Grid} from '@mui/material';
import McSelectItem from 'carManagement/component/McSelectItem';

interface CarPriceOptionGroupProps {
	carInfoData : any
	handleCarInfoData : (name:string) => (e:any) => void
}

/* 매매종류 , 할부 가능여부 , 보험이력 공개여부 */
const CarPriceOptionGroup = ({carInfoData , handleCarInfoData}:CarPriceOptionGroupProps) => {

	const carQouta = ['가능' , '불가능'];

	const carHistory = [
		{name : '비공개' , value : 0},
		{name : '공개' , value : 1}
	];

	const carTheme = [
		{name : '판매' , value : '1'},
		{name : '렌트' , value : '3'},
		{name : '구독' , value : '5'},
		{name : '판매&구독' , value : '7'},
	];

	return (
		<>
			<FormControl  sx={{minWidth: 120 }}>
				<McSelectItem data={carTheme} title={'매매종류'} id={'saleType'} value={carInfoData.saleType} label={'매매종류'} onChange={handleCarInfoData}/>
				<FormControl sx={{m:2 , mt:1}}>
					<FormLabel >할부 가능여부</FormLabel>
					<RadioGroup
						row
						name={'quota'}
						value={carInfoData.quota}
						onChange={handleCarInfoData('quota')}
					>
						{
							carQouta.map(item => (
								<FormControlLabel key={item} value={item} control={<Radio />} label={item} />
							))
						}
					</RadioGroup>
				</FormControl>
				<FormControl sx={{m:2 , mt:1}}>
					<FormLabel>보험이력 공개여부</FormLabel>
					<RadioGroup
						row
						name={'carhistory'}
						value={carInfoData.carhistory}
						onChange={handleCarInfoData('carhistory')}
					>
						{
							carHistory.map(item => (
								<FormControlLabel key={item.name} value={item.value} control={<Radio />} label={item.name} />
							))
						}
					</RadioGroup>
				</FormControl>
			</FormControl>
		</>
	)
}

export default CarPriceOptionGroup;