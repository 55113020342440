import React from 'react';
import { Grid, Button } from '@mui/material';
import { customAxios } from 'axios/customAxios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EnvPath } from 'util/path/EnvPath';
import CarOptionCheckItem from './CarOptionCheckItem';
import { isEqual } from 'lodash';
import McBackdrop from 'component/McBackdrop';

interface CarOptionCheckProps {
    data: Array<any>;
    optList: Array<string>;
}

/**
 * 상세검색 옵션 설정
 * @param data
 * @returns
 */
const CarOptionCheck = ({ data, optList }: CarOptionCheckProps) => {
    const queryClient = useQueryClient();

    const carConfListUrl = `/meetcha/car/manager/getListCarConfValue`;
    const carConfSaveUrl = `/meetcha/car/manager/doSaveCarConf`;
    const value = 'car_option_search_six_checked';
    //const carOptNameArr = ['car_option4', 'car_option3', 'car_option5', 'car_option2', 'car_option8'];

    /* 옵션별로 저장할 state */
    const [optionData, setOptionData] = React.useState<{
        [prop: string]: any;
    }>({
        car_option4: [],
        car_option3: [],
        car_option5: [],
        car_option2: [],
        car_option8: [],
    });

    /* 체크된 option의 number 배열 */
    const [checkData, setCheckData] = React.useState<Array<any>>([]);

    //api get
    const checkOptList = useQuery([value], () => customAxios.get(`${carConfListUrl}?carConfTitle=${value}`), {
        //활성화 시간 60분
        staleTime: 60 * 60 * 1000,
        retry: false,
    });

    //api post
    const upsert = useMutation((param: any) => customAxios.post(`${carConfSaveUrl}`, param), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(value);
        },
        onError: () => {
            alert('오류가 발생하였습니다. 재시도해주세요.');
            queryClient.invalidateQueries(value);
        },
    });

    /**
     * formData 변환 함수
     * @param data
     * @returns
     */
    const ArrayToFormData = (data: Array<any>) => {
        const formData: any = new FormData();
        formData.append('carConfTitle', value);
        data.forEach((item) => {
            formData.append('carConfList[]', item);
        });
        return formData;
    };

    /**
     * 완료 버튼 클릭 이벤트
     */
    const handleCompleteBtn = () => {
        upsert.mutate(ArrayToFormData(checkData));
    };

    /**
     * 체크 true , false
     * @param value
     * @returns
     */
    const isChecked = (value: string) => {
        return checkData.some((item: any) => item === value);
    };

    /**
     * 체크박스 클릭 이벤트
     * @param e
     */
    const handleCheckSelectOption = (e: any) => {
        let data: any = [...checkData];

        if (data.some((item: any) => item === e.target.value)) {
            setCheckData(data.filter((item: any) => item !== e.target.value));
        } else {
            data.push(e.target.value);
            setCheckData(data);
        }
    };

    React.useEffect(() => {
        const result: any = (key: string) => data.filter(({ data }: any) => data.queryKey === key)[0].data;
        setOptionData({
            car_option4: result('car_option4').data,
            car_option3: result('car_option3').data,
            car_option5: result('car_option5').data,
            car_option2: result('car_option2').data,
            car_option8: result('car_option8').data,
        });
    }, []);

    React.useEffect(() => {
        if (checkOptList.data?.data) {
            setCheckData(checkOptList.data?.data);
        }
    }, [checkOptList.data?.data]);

    return (
        <div>
            <Grid
                container
                sx={{ p: 2, border: 1, borderColor: '#CCCCCC', mt: 2 }}
                columns={{ xs: 5, sm: 10, md: 15 }}
                justifyContent={'center'}
            >
                {optList.map((item: any) =>
                    checkData.length && optionData[item].length ? (
                        <Grid item xs={5} sm={5} md={3} key={item}>
                            <CarOptionCheckItem
                                data={optionData[item]}
                                name={item}
                                isChecked={isChecked}
                                onChange={handleCheckSelectOption}
                            />
                        </Grid>
                    ) : null
                )}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <Button sx={{ mr: 1 }} variant="contained" onClick={handleCompleteBtn}>
                    저장
                </Button>
                <Button variant="contained" color={'error'} onClick={() => setCheckData(checkOptList.data?.data)}>
                    취소
                </Button>
            </div>
            {(checkOptList.isLoading || upsert.isLoading) && (
                <div>
                    <McBackdrop />
                </div>
            )}
        </div>
    );
};

export default CarOptionCheck;
