import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface McDialogProps {
    title: string;
    description: any;
    handleClose: () => void;
    handleConfirm: any;
}
const McDialog = ({ title, description, handleClose, handleConfirm }: McDialogProps) => {
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-wrap' }}>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} autoFocus>
                        확인
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        취소
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default McDialog;
