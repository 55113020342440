import * as React from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Box, Grid } from '@mui/material';
import McTypography from 'carManagement//component/McTypography';

interface MarkdownEditorProps {
    updateMain: string | undefined;
    innerRef: any;
}

const MarkdownEditor = ({ innerRef, updateMain }: MarkdownEditorProps) => {
    // const handleHtmlTag = (type:any , ev:any) => {
    // 	if(ev.key === 'Enter'){
    // 		const getHtml = innerRef.current?.getInstance().getHTML()
    // 		console.log(innerRef.current?.getInstance().getHTML());
    // 	}
    // }

    /* 수정시 main값을 넣어줌 */
    React.useEffect(() => {
        if (updateMain) {
            innerRef.current?.getInstance().setHTML(updateMain);
        }
    }, []);

    return (
        <>
            <Box sx={{ width: '100%', minWidth: 180, mb: 2, borderBottom: 1 }}>
                <McTypography variant="h5" text="차량설명" />
            </Box>
            <Grid container columns={{ xs: 2, sm: 4, md: 6, lg: 12 }}>
                <Grid item xs={1} sm={3.5} md={6} lg={12}>
                    <Editor
                        autofocus={false}
                        ref={innerRef}
                        placeholder="내용을 입력해주세요."
                        hideModeSwitch={true}
                        usageStatistics={false}
                        previewStyle="vertical" // 미리보기 스타일 지정
                        height="300px" // 에디터 창 높이
                        initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                        toolbarItems={[
                            // 툴바 옵션 설정
                            ['heading', 'bold', 'italic', 'strike'],
                            ['hr', 'quote'],
                            ['ul', 'ol', 'task', 'indent', 'outdent'],
                            //['table', 'image', 'link'],
                            //['code', 'codeblock']
                        ]}
                        // onChange={handleHtmlTag}
                        //onKeydown={handleHtmlTag}
                    />
                </Grid>
                <Grid item xs={1} sm={0} md={0} lg={0} />
            </Grid>
        </>
    );
};
export default MarkdownEditor;
