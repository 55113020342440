import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserList from 'user/main/UserList';
import SubManagerList from 'user/manager/sub/SubManagerList';
import Terms from 'user/terms/Terms';

interface UserRouteProps {}
const UserRoute = () => {
    return (
        <Routes>
            <Route path="/userList" element={<UserList />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/submanager/list" element={<SubManagerList />} />
        </Routes>
    );
};
export default UserRoute;
