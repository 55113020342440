import * as React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    TextareaAutosize,
    Theme,
    SxProps,
    FormHelperText,
    TextField,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';
import DaumPostCodeModal from 'signUp/component/DaumPostCodeModal';
import AddressForm from 'signUp/component/AddressForm';
import ComAddressForm from 'signUp/component/ComAddressForm';
import UserInfoForm from 'signUp/component/UserInfoForm';
import { SignUpUserFormInfo, UserDetailFormInfo } from 'interface/SignUpUserInfo';
import { customAxios } from 'axios/customAxios';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';
import UserInfoUpdateForm from 'signUp/component/UserInfoUpdateForm';

interface SignUpProps {
    userInfo?: any;
    postCode?: any;
    profileImage?: string;
    handleClosedetailModal?: (isDealer?: boolean) => void;
}

const SignUp = ({ userInfo, postCode, profileImage, handleClosedetailModal }: SignUpProps) => {
    const boxStyle: SxProps<Theme> = { ml: 1, mt: 2, mb: 2, borderTop: 1, borderColor: '#e7e7e7' };
    const location = useLocation();
    const navi = useNavigate();
    //딜러 그룹 코드
    const { state }: any = location;
    const { REACT_APP_MODE } = EnvPath;

    const {
        register,
        handleSubmit,
        watch,
        control,
        setFocus,
        setValue,
        formState: { errors },
    } = useForm<SignUpUserFormInfo | UserDetailFormInfo>({
        defaultValues: userInfo
            ? userInfo
            : {
                  userId: '',
                  userPass: '',
                  userPassCom: '',
                  userName: '',
                  userNick: '',
                  extra10: '',
                  userEmail: '',
                  userPhone: '',
                  userHphone: '',
                  userFax: '',
                  extra9: '',
                  comName: '',
                  photo1: '',
                  message: '',
                  userComSi: '',
                  userComGu: '',
                  userComDong: '',
                  userComAddr: '',
                  emailFowarding: false,
                  smsFowarding: false,
                  stateOpen: false,
              },
    });

    const { photo1, point }: any = errors;

    const [isPostcode, setIsPostCode] = React.useState(false);
    const [postAddr, setPostAddr] = React.useState({
        zonecode: '',
        address: '',
        detailAddress: '',
    });
    const onSubmit = async (data: any) => {
        const userInfoCheck = await fetchUserInfoCheck(data.userId, data.userNick);
        if (userInfoCheck && !userInfo) {
            if (userInfoCheck.userId && userInfoCheck.userNick) {
                alert('이미 사용 중인 아이디 , 닉네임입니다.');
                setFocus('userId', { shouldSelect: true });
                return;
            } else if (userInfoCheck.userId) {
                alert('이미 사용 중인 아이디입니다.');
                setFocus('userId', { shouldSelect: true });
                return;
            } else if (userInfoCheck.userNick) {
                alert('이미 사용 중인 닉네임입니다.');
                setFocus('userNick', { shouldSelect: true });
                return;
            }
        }
        //그룹 설정
        if (!userInfo) {
            data.group = state;
        }

        //전화번호 하이픈 추가
        if (data.userPhone) {
            data.userPhone = data.userPhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }
        //핸드폰번호 하이픈 추가
        if (data.userHphone) {
            data.userHphone = data.userHphone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }
        //팩스번호 하이픈 추가
        if (data.userFax) {
            data.userFax = data.userFax.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }

        //주소값 설정
        if (postAddr.zonecode) {
            data.userZip = postAddr.zonecode;
            data.userAddr1 = postAddr.address;
            data.userAddr2 = postAddr.detailAddress;
        }

        //회사주소 설정
        if (data.userComSi && data.userComGu && data.userComDong) {
            data.extra8 = `${data.userComSi.split(',')[0]}/${data.userComGu}/${data.userComDong}/${data.userComAddr}`;
        }

        //이미지 파일 설정
        if (data.photo1) {
            data.photo1 = data.photo1[0];
        }

        //이메일 주소 설정
        if (data.userEmail) {
            if (data.userEmailSite === '직접입력') {
                if (data.userEmailDirectInput) {
                    data.userEmail = `${data.userEmail}@${data.userEmailDirectInput}`;
                }
            } else {
                data.userEmail = `${data.userEmail}@${data.userEmailSite}`;
            }
        }

        /* 업데이트 상태에서 photo , password 의 값을 안넣어주면 삭제 */
        if (userInfo) {
            if (!data.photo1) {
                delete data.photo1;
            }
            if (!data.userPass) {
                delete data.userPass;
            }

            if (data.group === 20 || data.group === 7) {
                if (!data.extra8) {
                    data.extra8 = '///';
                }
                if (!data.comName) {
                    data.comName = '';
                }
            }
        }
        //api 호출
        doSignUp(data);
    };

    /**
     * 회원가입 및 회원정보수정 api
     * @param data
     */
    const doSignUp = async (data: any) => {
        const formConfig = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        try {
            const resposne = await customAxios.post('/meetcha/manager/user/doSignUp', data, formConfig);
            if (resposne.data.result === 'success') {
                if (userInfo) {
                    alert('회원정보 업데이트를 완료했습니다. ');
                    handleClosedetailModal!(true);
                } else {
                    alert('회원가입이 완료되었습니다. ');
                    navi(Path.ROOT_PATH);
                }
            } else {
                if (userInfo) {
                    alert('회원정보 업데이트중에 오류가 발생하였습니다. ');
                } else {
                    alert('회원가입중에 오류가 발생하였습니다.');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * 유저의 이름 , 닉네임 중복체크 함수
     * @param userId
     * @param userNick
     * @returns
     */
    const fetchUserInfoCheck = async (userId: string, userNick: string) => {
        try {
            const resposne = await customAxios.get(
                `/meetcha/manager/user/userInfoCheck?userId=${userId}&userNick=${userNick}`
            );
            if (resposne.data !== 'false' && resposne.status === 200) {
                return resposne.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * 그룹별 title return
     * @param name
     * @returns
     */
    const groupTitle = (name: string) => {
        switch (name) {
            case '21':
                return '매입상담사';
            case '20':
                return '카스타일러';
            case '8':
                return '딜러회원';
            case '22':
                return '영업제휴회원';
            case '7':
                return '일반회원';
            default:
                return '';
        }
    };

    // const handleSelectComAddr = (name:string) => (e:any) => {
    // 	setComAddr({
    // 		...comAddr ,
    // 		[name] : e.target.value
    // 	})
    // }

    // const handleComDetailAddr:any = React.useCallback((e:any) => {
    // 	  const { value } = e.target;
    // 	  setComAddr({
    // 		...comAddr ,
    // 		addr : value
    // 	})},
    // 	[comAddr]
    // );

    //지번 분리
    const jibunAddressText = (jibunAddress: string) => {
        if (!jibunAddress) {
            return '';
        }
        let jibunArr = jibunAddress.split(' ').slice(0, -1);
        let jibun = '';

        jibunArr.map((item) => {
            jibun = jibun + `${item} `;
        });

        return jibun;
    };

    //주소선택 함수
    const handleSelectPostCode = (e: any) => {
        //autoJibunAddress = 지번에 데이터가 없을 때 사용
        const { zonecode, jibunAddress, autoJibunAddress } = e;

        let addr = jibunAddressText(jibunAddress ? jibunAddress : autoJibunAddress);
        const detailAddr = jibunAddress
            ? jibunAddress.split(' ')[jibunAddress.split(' ').length - 1]
            : autoJibunAddress.split(' ')[autoJibunAddress.split(' ').length - 1];

        setIsPostCode(false);
        setPostAddr({
            ...postAddr,
            zonecode: zonecode,
            address: addr,
            detailAddress: detailAddr,
        });
    };

    //상세주소 입력 함수
    const handleDetailAddress = (e: any) => {
        setPostAddr({
            ...postAddr,
            detailAddress: e.target.value,
        });
    };

    const handleResetPostAddr = () => {
        setPostAddr({
            zonecode: '',
            address: '',
            detailAddress: '',
        });
    };

    const handleResetComAddr = () => {
        setValue('userComAddr', '');
        setValue('userComDong', '');
        setValue('userComGu', '');
        setValue('userComSi', '');
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);

        //상세모달
        if (postCode) {
            setPostAddr({
                zonecode: postCode.zonecode,
                address: postCode.address,
                detailAddress: postCode.detailAddress,
            });
        }
    }, []);

    // https://react-hook-form.com/kr/get-started#Applyvalidation
    return (
        <div>
            <Card sx={{ mb: userInfo ? 0 : 50 }}>
                <CardContent>
                    {/* 회원가입 , 회원상세정보(수정) Title 구분 */}
                    {userInfo ? (
                        <Typography variant="h4">회원상세정보</Typography>
                    ) : (
                        <Typography variant="h4">
                            {`${groupTitle(state)} 약관동의`}
                            <span
                                onClick={() => navi(Path.ROOT_PATH)}
                                style={{ color: '#333', fontWeight: 'normal', fontSize: 13 }}
                            >
                                회원가입에 필요한 정보를 입력해주세요.
                            </span>
                        </Typography>
                    )}
                </CardContent>
                <CardContent sx={{ borderTop: 1, borderColor: '#E7E7E7' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography sx={{ mt: 1, ml: 1 }} fontWeight={'bold'} fontSize={16} variant="body1">
                            회원정보
                        </Typography>
                        {userInfo ? (
                            <UserInfoUpdateForm
                                register={register}
                                errors={errors}
                                watch={watch}
                                group={userInfo.group}
                            />
                        ) : (
                            <UserInfoForm register={register} errors={errors} watch={watch} />
                        )}
                        <AddressForm
                            addressData={postAddr}
                            handleSearhAddress={() => setIsPostCode(true)}
                            onChangeDetailAddress={handleDetailAddress}
                        />
                        <Button sx={{ ml: 1 }} variant="contained" onClick={handleResetPostAddr}>
                            초기화
                        </Button>
                        <ComAddressForm
                            register={register}
                            watch={watch}
                            errors={errors}
                            setValue={setValue}
                            isDetail={userInfo ? true : false}
                            group={userInfo ? userInfo.group : ''}
                        />
                        <Button sx={{ ml: 1 }} variant="contained" onClick={handleResetComAddr}>
                            초기화
                        </Button>
                        <Box sx={boxStyle}>
                            <Typography sx={{ mb: 2, mt: 1 }} variant="body1" fontWeight={'bold'} fontSize={16}>
                                프로필 이미지
                            </Typography>
                            <input
                                {...register('photo1', {
                                    required: userInfo ? false : '프로필 이미지를 등록해주세요.',
                                })}
                                type="file"
                                multiple={false}
                                accept="image/jpg, image/png, image/jpeg"
                                style={{ marginBottom: 8, display: 'block' }}
                            />
                            {errors.photo1 && (
                                <FormHelperText sx={{ mt: 1, ml: 1, alignSelf: 'flex-end', color: 'red' }}>
                                    {photo1.message}
                                </FormHelperText>
                            )}
                            {profileImage && !watch('photo1') && (
                                <img alt="프로필 이미지" src={profileImage} style={{ width: 120 }} />
                            )}
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={{ mb: 1, mt: 1 }} variant="body1" fontWeight={'bold'} fontSize={16}>
                                자기소개
                            </Typography>
                            <TextareaAutosize
                                {...register('message')}
                                minRows={10}
                                // placeholder="자기소개"
                                style={{ maxWidth: 400, marginTop: 5 }}
                            />
                        </Box>
                        {/* 유저상세정보 모달에서만 노출되는 결제정보 (포인트) */}
                        {userInfo && (
                            <Box sx={{ ml: 1, mt: 2, mb: 2, borderTop: 1, borderColor: '#e7e7e7' }}>
                                <Typography sx={{ mb: 1, mt: 1 }} variant="body1" fontWeight={'bold'} fontSize={16}>
                                    결제정보
                                </Typography>

                                <TextField
                                    variant="standard"
                                    type={'text'}
                                    sx={{ width: 300 }}
                                    size="small"
                                    label={'포인트'}
                                    {...register('point', {
                                        required: false,
                                        pattern: { value: /^[0-9]+$/, message: '숫자만 입력가능합니다.' },
                                    })}
                                    error={Boolean(point)}
                                />
                            </Box>
                        )}
                        {/* <UserConsentsCheck 
							register={register}
							watch={watch}
						/> */}
                        <Box sx={boxStyle}>
                            <Button sx={{ m: 1, mt: 2 }} variant="contained" type="submit">
                                {userInfo ? '수정' : '저장'}
                            </Button>
                            {userInfo && (
                                <Button
                                    sx={{ m: 1, mt: 2 }}
                                    variant="contained"
                                    color={'error'}
                                    onClick={() => handleClosedetailModal!()}
                                >
                                    취소
                                </Button>
                            )}
                        </Box>
                    </form>
                    {/* {REACT_APP_MODE !== 'production' && <DevTool control={control} />} */}
                </CardContent>
            </Card>
            {isPostcode && <DaumPostCodeModal onClose={() => setIsPostCode(false)} onComplete={handleSelectPostCode} />}
        </div>
    );
};
export default SignUp;
