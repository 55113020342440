import * as React from 'react';
import {
    Grid,
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import { customAxios } from 'axios/customAxios';
import { SignUpUserFormInfo, SignUpUserRequireInfo } from 'interface/SignUpUserInfo';
import { UseFormRegister, UseFormWatch, UseFormSetValue, FieldErrorsImpl, DeepRequired } from 'react-hook-form';
interface ComAddressFormProps {
    register: UseFormRegister<SignUpUserFormInfo>;
    watch: UseFormWatch<SignUpUserFormInfo>;
    errors: FieldErrorsImpl<DeepRequired<SignUpUserRequireInfo>>;
    setValue: UseFormSetValue<SignUpUserFormInfo>;
    isDetail?: boolean;
    group?: any;
}
const ComAddressForm = ({ register, watch, errors, setValue, isDetail, group }: ComAddressFormProps) => {
    const { userComSi, userComGu, userComDong, userComAddr }: any = errors;

    const [siGuDong, setSiiGuDong] = React.useState({
        si: [],
        gu: [],
        dong: [],
    });

    const selectSiGuDongData = (addr: string) => {
        const copySiGuDong: any = { ...siGuDong };

        if (addr === 'si') {
            return siGuDong.si;
        } else if (addr === 'gu') {
            return copySiGuDong.gu.filter(
                (item: any) => `${item.si}` === `${watch('userComSi') ? watch('userComSi').split(',')[1] : ''}`
            );
        } else {
            const arr: Array<any> = copySiGuDong.dong.filter(
                (item: any) => `${item.guNumber}` === `${watch('userComGu')}`
            );
            if (arr.length) {
                if (arr[0].dongTitle.includes('\r\n')) {
                    return arr[0].dongTitle.split('\r\n');
                } else {
                    return arr[0].dongTitle.split('\n');
                }
            } else {
                return [];
            }
        }
    };

    //sigudong api 호출
    const fetchCarSiGuDong = async () => {
        try {
            const response = await customAxios.get(`/meetcha/car/manager/getCarAddressInfo`);

            if (response.status === 200 && response.data) {
                const { carSi, carSiGu, carSiGuDong } = response.data.carAddressInfo;
                /* 
					지역(Si) 는 value 값이 ex)서울,1 상태여서 조합이 필요함 
				*/
                if (isDetail && watch('userComSi')) {
                    const siValue = carSi.filter((i: any) => i.si === watch('userComSi'))[0];
                    setValue('userComSi', `${siValue.si},${siValue.number}`);
                }
                setSiiGuDong({
                    ...siGuDong,
                    si: carSi,
                    gu: carSiGu,
                    dong: carSiGuDong,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleErrText = () => {
        if (userComSi) {
            return (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userComSi)}>
                    {userComSi.message}
                </FormHelperText>
            );
        } else if (!userComSi && userComGu) {
            return (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userComGu)}>
                    {userComGu.message}
                </FormHelperText>
            );
        } else if (!userComSi && !userComGu && userComDong) {
            return (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userComDong)}>
                    {userComDong.message}
                </FormHelperText>
            );
        } else if (!userComSi && !userComGu && !userComDong && userComAddr) {
            return (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userComAddr)}>
                    {userComAddr.message}
                </FormHelperText>
            );
        } else {
            return <></>;
        }
    };

    React.useEffect(() => {
        fetchCarSiGuDong();
    }, []);

    return (
        <div>
            {/* select box 마다 defaultValue 추가 (회원상세정보 폼 진입시 초기데이터 삽입필요) */}
            {siGuDong.si.length && siGuDong.gu.length && siGuDong.dong.length ? (
                <Grid sx={{ mt: 2, mb: 2, borderTop: 1, borderColor: '#e7e7e7' }}>
                    <Box sx={{ ml: 1, mt: 1 }}>
                        <Typography fontWeight={'bold'} fontSize={16} variant="body1">
                            {`회사주소${group !== 7 ? '*' : ''}`}
                        </Typography>
                    </Box>
                    <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size={'small'}>
                        <InputLabel>지역선택</InputLabel>
                        <Select
                            {...register('userComSi', { required: group === 7 ? false : '지역을 선택해주세요' })}
                            value={watch('userComSi')}
                            label={'지역선택'}
                            error={Boolean(userComSi)}
                        >
                            {selectSiGuDongData('si').map((item: any) => (
                                <MenuItem value={`${item.si},${item.number}`} key={item.number}>
                                    {item.si}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size={'small'}>
                        <InputLabel>구선택</InputLabel>
                        <Select
                            {...register('userComGu', { required: group === 7 ? false : '구 를 선택해주세요' })}
                            label={'지역선택'}
                            value={watch('userComGu')}
                            error={Boolean(userComGu)}
                        >
                            {selectSiGuDongData('gu').map((item: any) => (
                                <MenuItem value={item.number} key={item.number}>
                                    {item.gu}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, mt: 2, minWidth: 120 }} size={'small'}>
                        <InputLabel>동선택</InputLabel>
                        <Select
                            {...register('userComDong', { required: group === 7 ? false : '동을 선택해주세요' })}
                            label={'지역선택'}
                            value={watch('userComDong')}
                            error={Boolean(userComDong)}
                        >
                            {selectSiGuDongData('dong').map((item: any) => (
                                <MenuItem value={item.trim()} key={item}>
                                    {item.trim()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ ml: 1, mt: 1 }}
                        {...register('userComAddr', { required: group === 7 ? false : '상세주소를 입력해주세요.' })}
                        error={Boolean(userComAddr)}
                        label={'상세 주소'}
                        variant={'standard'}
                        size={'small'}
                    />
                    {handleErrText()}
                </Grid>
            ) : null}
        </div>
    );
};
export default ComAddressForm;

/* 

	<Box>
					<McSelectItem 
						data={selectSiGuDongData('si')} 
						title={'지역선택'} 
						id={'comSi'} 
						value={comAddr.comSi} 
						label={'지역선택'} 
						onChange={handleSelectComAddr} 
						defaultMenu={true}
						size={'small'}
					/>
					
					<McSelectItem 
						data={selectSiGuDongData('gu')} 
						title={'구선택'} 
						id={'gu'} 
						value={comAddr.gu} 
						label={'구선택'} 
						disabled={!Boolean(comAddr.comSi)} 
						onChange={handleSelectComAddr} 
						defaultMenu={true}
						size={'small'}
					/>
					<McSelectItem 
						data={selectSiGuDongData('dong')} 
						title={'동선택'} 
						id={'dong'} 
						value={comAddr.dong === '0' ? '0' : comAddr.dong} 
						label={'동선택'}
						disabled={!Boolean(comAddr.gu)} 
						onChange={handleSelectComAddr} 
						defaultMenu={true}
						size={'small'}
					/>
					<TextField 
						sx={{ml:1}} 
						id={'addr'} 
						label={'상세 주소'} 
						variant={'standard'} 
						value={comAddr.addr} 
						onChange={handleComDetailAddr} 
						size={'small'}
					/>
				</Box>
*/
