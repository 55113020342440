import * as React from 'react';
import {
    Box,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    Typography,
    InputAdornment,
} from '@mui/material';
import { UseFormRegister, ValidationRule, Validate } from 'react-hook-form';

interface SignUpTextFieldProps {
    label: string;
    name: string;
    register: UseFormRegister<any>;
    required: boolean | string;
    type: React.HTMLInputTypeAttribute;
    validate?: Validate<any> | Record<string, Validate<any>> | undefined;
    pattern?: ValidationRule<RegExp> | undefined;
    helperText?: string;
    error?: boolean;
    watch?: any;
    disabled?: boolean;
}
export const SignUpTextField = ({
    label,
    name,
    register,
    helperText,
    required,
    type,
    validate,
    pattern,
    error = false,
    disabled = false,
}: SignUpTextFieldProps) => {
    return (
        <Box sx={{ m: 1, mt: 2, display: 'flex', flexDirection: 'row' }}>
            <FormControl>
                <TextField
                    disabled={disabled}
                    variant="standard"
                    type={type}
                    sx={{ width: 300 }}
                    size="small"
                    label={label}
                    {...register(name, { required: required, pattern: pattern, validate: validate })}
                    error={error}
                />
            </FormControl>
            {helperText && <FormHelperText sx={{ ml: 1, alignSelf: 'flex-end' }}>{helperText}</FormHelperText>}
        </Box>
    );
};
// email form
export const EmailTextField = ({
    label,
    name,
    register,
    helperText,
    required,
    type,
    validate,
    pattern,
    error = false,
    watch,
}: SignUpTextFieldProps) => {
    const emailList = ['naver.com', 'daum.com', 'nate.com', 'gmail.com', '직접입력'];
    const [selectMail, setSelectMail] = React.useState('naver.com');
    const [emailSite, setEmailSite] = React.useState('');

    const handleSelectItem = (e: any) => {
        setSelectMail(e.target.value);
        if (e.target.value === '직접입력') {
            setEmailSite('');
        } else {
            setEmailSite(e.target.value);
        }
    };

    React.useEffect(() => {
        if (watch) {
            if (watch('userEmailSite')) {
                setSelectMail(watch('userEmailSite'));
                setEmailSite(watch('userEmailDirectInput'));
            }
        }
    }, []);

    return (
        <Box sx={{ m: 1, mt: 2 }}>
            <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                    variant="standard"
                    type={type}
                    sx={{ width: 300 }}
                    size="small"
                    label={label}
                    {...register(name, { required: required, pattern: pattern, validate: validate })}
                    error={error}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{'@'}</InputAdornment>,
                    }}
                />
                <TextField
                    variant="standard"
                    sx={{ width: 150, ml: 2, alignSelf: 'flex-end' }}
                    disabled={selectMail === '직접입력' ? false : true}
                    size="small"
                    label={''}
                    {...register('userEmailDirectInput')}
                    onChange={(e) => setEmailSite(e.target.value)}
                    value={selectMail === '직접입력' ? emailSite : selectMail}
                />
                <Select
                    sx={{ ml: 2 }}
                    size={'small'}
                    {...register('userEmailSite')}
                    onChange={handleSelectItem}
                    value={selectMail}
                >
                    {emailList.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
