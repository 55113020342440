import * as React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LogoutIcon from '@mui/icons-material/Logout';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { useNavigate, useLocation } from 'react-router-dom';
import AlertDialog from '../../home/AlertDialog';
//import * as cookie from "../util/cookie";
import { getStorageItem, removeStorageItem } from 'util/sessionStorage';
import {
    AdminGroupMenu,
    CarManagementGroupMenu,
    CarMemberGroupMenu,
    SellCarDealerGroupMenu,
} from 'route/menuItem/GroupItems';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';

// type MainListItemsProps =  {
//   moveNavigate: Function
// }

interface MainListItemsProps {
    isMainDrawerMenuVisible?: boolean;
}

export default function MainListItems({ isMainDrawerMenuVisible }: MainListItemsProps) {
    const { REACT_APP_MODE } = EnvPath;

    const group = getStorageItem('group');

    const [open, setOpen] = React.useState<boolean>(false);

    const navigate = useNavigate();

    //const [expandOpen, setExpandOpen] = React.useState<boolean>(false);

    const [expandOpen, setExpandOpen] = React.useState<boolean>(false);
    const [carMenuOpen, setCarMenuOpen] = React.useState<boolean>(false);
    const [userMenuOpen, setUserMenuOpen] = React.useState<boolean>(false);
    const [smsMenuOpen, setSmsMenuOpen] = React.useState<boolean>(false);
    const [siteMenuOpen, setSiteMenuOpen] = React.useState<boolean>(false);

    const handleAdminMenuExpand = (name: string) => () => {
        switch (name) {
            case 'carManagement':
                setCarMenuOpen(!carMenuOpen);
                break;
            case 'sellCar':
                setExpandOpen(!expandOpen);
                break;
            case 'userManagement':
                setUserMenuOpen(!userMenuOpen);
                break;
            case 'smsManagement':
                setSmsMenuOpen(!smsMenuOpen);
                break;
            case 'siteManagement':
                setSiteMenuOpen(!siteMenuOpen);
                break;
            default:
                break;
        }
    };

    const isAdminMenuExpand = (name: string) => {
        switch (name) {
            case 'carManagement':
                return carMenuOpen;
            case 'sellCar':
                return expandOpen;
            case 'userManagement':
                return userMenuOpen;
            case 'smsManagement':
                return smsMenuOpen;
            case 'siteManagement':
                return siteMenuOpen;
            default:
                return false;
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAccept = () => {
        removeStorageItem('token');
        removeStorageItem('id');
        removeStorageItem('group');
        setOpen(false);
        navigate(Path.ROOT_PATH);
    };

    const menuByGroup = () => {
        switch (group) {
            //내 차 팔기용 딜러 계정
            case '21':
                return (
                    <SellCarDealerGroupMenu
                        isMainDrawerMenuVisible={isMainDrawerMenuVisible}
                        handleMainMenu={() => setCarMenuOpen(!carMenuOpen)}
                        isExpand={carMenuOpen}
                        navigate={navigate}
                    />
                );
            //차량 판매용 딜러 계정
            case '8':
                return (
                    <CarManagementGroupMenu
                        isMainDrawerMenuVisible={isMainDrawerMenuVisible}
                        handleMainMenu={() => setExpandOpen(!expandOpen)}
                        isExpand={expandOpen}
                        navigate={navigate}
                    />
                );
            //차량 판매용 딜러 계정
            case '22':
                return (
                    <CarMemberGroupMenu
                        isMainDrawerMenuVisible={isMainDrawerMenuVisible}
                        handleMainMenu={() => setExpandOpen(!expandOpen)}
                        isExpand={expandOpen}
                        navigate={navigate}
                    />
                );
            //관리자 계정
            case '99':
                return (
                    <AdminGroupMenu
                        isMainDrawerMenuVisible={isMainDrawerMenuVisible}
                        handleMenu={handleAdminMenuExpand}
                        isExpand={isAdminMenuExpand}
                        navigate={navigate}
                    />
                );
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <ListItemButton
                onClick={() => {
                    navigate(Path.HOME_PATH);
                }}
            >
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="홈" />
            </ListItemButton>
            {menuByGroup()}
            <ListItemButton
                onClick={() => {
                    setOpen(true);
                }}
            >
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="로그아웃" />
            </ListItemButton>

            {open ? (
                <AlertDialog
                    handleClose={handleClose}
                    handleAccept={handleAccept}
                    title={'로그아웃'}
                    description={`로그아웃 하시겠습니까? 로그아웃 하시면 로그인 창으로 돌아가게 됩니다.`}
                />
            ) : null}
        </React.Fragment>
    );
}
