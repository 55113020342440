import React from 'react';

//배열 순서변경  함수 + sort 값 변경
export const arrayMoveMutate = (array: Array<any>, from: number, to: number) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;
        let toSort = array[to].sort;

        const [item]: any = array.splice(from, 1);
        array.splice(endIndex, 0, item);
        //바뀐값만 sort값 변경
        //array[to].sort = toSort;
    }
};

export const arrayMove = (array: Array<any>, from: number, to: number) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
};

export const dateToRegDateTime = (date?: any, uptcheck?: boolean) => {
    let result = new Date().toISOString().split('.')[0].replace('T', ' ');
    if (uptcheck) {
        if (date) {
            const isDate = new Date(date).toString() !== 'Invalid Date';
            const isTime = new Date(date).toString() !== 'Invalid time value';

            if (isDate && isTime) {
                result = new Date(date!).toISOString().split('.')[0].replace('T', ' ');
            }
        }
    } else {
        result = new Date().toISOString().split('.')[0].replace('T', ' ');
    }
    return result;
};

export const formatDate = (date?: any) => {
    let result;
    if (date) {    
        result = new Date(date!).toISOString().split('T')[0].trim();    
    } else {
        result = new Date().toISOString().split('T')[0].trim();    
    }
    return result;
};
