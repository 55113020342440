import * as React from 'react'

import {Box, Button , ButtonGroup} from '@mui/material'
//import * as cookie from 'util/cookie';
import { getStorageItem } from 'util/sessionStorage';
import { EnvPath } from 'util/path/EnvPath';

interface DealerCarSettingProps {
	carInfo : any ,
	handleModal : (carInfo:any , name:string) => () => void,
	// handleIsLoading : (state:boolean) => void,
	handleIsDialog : (desc:string , api:string, data:string , linksNumber:string) => () =>  void,
}
/* 유료결제 , 차량 정보 수정 등 메뉴 */
const DealerCarSetting = ({carInfo , handleModal ,handleIsDialog }:DealerCarSettingProps) => {

	const group = getStorageItem('group');

	const token = getStorageItem("token");

	const [orientation , setOrientation] = React.useState<'vertical' | 'horizontal'>('vertical');
	
	const handleResize = () => {
		const {innerWidth} = window;

		if(innerWidth > 900){
			setOrientation('vertical');
		}else {
			setOrientation('horizontal');
		}
	}


	//화면비율에 따라 이미지리스트의 cols 를 줄여줌  
	React.useEffect(() => {
		window.addEventListener('resize' , handleResize);
		return () => {
			window.removeEventListener('resize' , handleResize)
		}
	}, [])

	//화면비율에 따라 이미지리스트의 cols 를 줄여줌  
	React.useEffect(() => {
		handleResize()
	}, [])

	return (
		<ButtonGroup variant="contained" orientation={orientation} sx={{mb : orientation === 'horizontal' ? 3 : 0 }}>
			
			<Button color='primary' onClick={handleModal(carInfo , 'carUpdate')}>차량정보수정</Button>
			{
				group === '99' &&
				<Button color='primary' onClick={handleModal(carInfo , 'carPayment')} disabled={carInfo.option_str.includes('opendealer2') ? true : false} >유료결제</Button>
			}
			<Button color='primary' disabled={carInfo.sale_end === '1' ? true : false} onClick={handleModal(carInfo , 'carPrice')}>가격 변경</Button>
			{
				carInfo.show_yn === 'Y' ?
				<Button color='primary' onClick={handleIsDialog('미노출 상태로 전환하시겠습니까?' , 'doShow' , 'N' ,carInfo.number)}>미노출 전환</Button>
				:
				<Button color='primary' onClick={handleIsDialog('노출 상태로 전환하시겠습니까?' , 'doShow' , 'Y' ,carInfo.number)}>노출 전환</Button>
			}
			{
				carInfo.sale_end === '0' ?
				<Button color='primary' onClick={handleIsDialog('판매완료 상태로 전환하시겠습니까?' , 'doSaleEnd', '1'  ,carInfo.number)}>판매완료 전환</Button>
				:
				<Button color='primary' onClick={handleIsDialog('판매 상태로 전환하시겠습니까?' , 'doSaleEnd', '0' ,carInfo.number)}>판매 전환</Button>
			}
			<Button color='error' onClick={handleIsDialog('차량을 삭제하시겠습니까?' , 'doCarLinks' , '' ,carInfo.number)}>삭제</Button>
		</ButtonGroup>  
	)
}
export default DealerCarSetting;