import * as React from 'react';

import { Box, Modal , Grid, Button} from '@mui/material';
import axios from 'axios';

import CarPaymentTitle from 'carManagement/carRegistration/carOptionPayment/CarPaymentTitle' 
import CarPaymentOptionPrice from 'carManagement/carRegistration/carOptionPayment/CarPaymentOptionPrice' 
import { getStorageItem } from 'util/sessionStorage';
import { customAxios } from 'axios/customAxios';
import { EnvPath } from 'util/path/EnvPath';

interface CarOptionPaymentModalProps {
	userInfo : any,
	carInfo : any,
	isRegistCar : boolean,
	postDoPayment : (linksNumber:number , optNumber:number) => Promise<void>,
	onClose : () => void
} 

const CarOptionPaymentModal = ({userInfo , carInfo , isRegistCar , onClose , postDoPayment}:CarOptionPaymentModalProps) => {
	
	const group = getStorageItem('group');
	const token = getStorageItem("token");

	const modalHeight = () => {
		if(window.innerWidth < 500){
			return  window.innerHeight * 0.8 ;
		}else {
			return  window.innerHeight * 0.8 ;
		}
	}

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: window.innerWidth * 0.8,
		height : modalHeight() ,
		bgcolor: 'background.paper',
		overflowY :'scroll',
		//border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}

	const [paymentArray ,setPaymentArray] = React.useState<Array<any>>([]);

	const [paymentData , setPaymentData] = React.useState<any>({
		exposurePrice : '',
		popularityPrice : ''
	});

	//const price = Number(paymentData.exposurePrice) + Number(paymentData.popularityPrice) 

	const handlePaymentData = (name:string) => (e:any) => {

		if(paymentData[name] === e.target.value){
			setPaymentData({
				...paymentData,
				[name] : ''
			})
		}else {
			setPaymentData({
				...paymentData,
				[name] : e.target.value
			})
		}
	}

	const handlePayment = () => {
		if(paymentData.exposurePrice){
			postDoPayment(Number(carInfo.number) ,Number(paymentData.exposurePrice));
		}else {
			alert('결제 항목을 선택해주세요.');
		}
		
	}

	const fetchPaymentList = async () => {
		try {
			const response = await customAxios.get(`/meetcha/car/manager/getPaymentList`);
			if(response.status === 200 && response.data){
				setPaymentArray([response.data[0]]);
			}
			
		} catch (error) {
			
		}
	}

	React.useEffect(() => {
		fetchPaymentList()
	},[])

	return (
		<div>
			<Modal 
				open={true}
				onClose={onClose}
			>
				<Box sx={style}>
					{
						paymentArray.length ? 
							<>
								<CarPaymentTitle />
								<Grid container spacing={ {xs: 2, sm : 4 ,md: 6}} columns={{ xs: 2, sm : 4 ,md: 6 }}>
									{
										paymentArray.map((item:any) => (
											<Grid key={item.number} item xs={2} sm={2} md={3}>
												<CarPaymentOptionPrice										
													data={item}
													paymentData={paymentData}
													onChange={handlePaymentData}
												/>
											</Grid>
										))
									}
								</Grid>
								<Box sx={{mt : 10 , borderTop : 1}} />
								{/* <Box sx={{mt : 5 , minWidth : 200 , textAlign : 'right' }}>
									<Typography variant='caption' fontSize={25} textAlign={'center'} mr={window.innerWidth < 500 ? 0 : 30}>보유 포인트</Typography>
									<Typography variant='caption' fontSize={25} fontWeight={'bold'}>{userInfo.point.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
									<Typography variant='caption' fontSize={25} > 원</Typography>
								</Box> */}
								{/* <Box sx={{mt : 5 , minWidth : 200 , textAlign : 'right' }}>
									<Typography variant='caption' fontSize={25} textAlign={'center'} mr={window.innerWidth < 500 ? 0 : 30}>총 결제금액</Typography>
									<Typography variant='caption' color={Number(userInfo.point) < price ? 'red' : 'ButtonText'} fontSize={25} fontWeight={'bold'}>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
									<Typography variant='caption' fontSize={25} > 원</Typography>
								</Box> */}
								<Box sx={{mt : 5, display : 'center' , justifyContent : 'flex-end'}}>
									<Button
										variant='outlined'
										color='inherit'
										size='large'
										style={{ width : window.innerWidth * 0.4 , height : 50}}
										onClick={handlePayment}
									>
										결제
									</Button>
								</Box>
							</>
							:
							null
					}
				</Box>
			</Modal>
		</div>
	)
}

export default CarOptionPaymentModal;