import * as React from 'react';
import Typography from '@mui/material/Typography';

interface McTypographyProps {
	variant : "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined,
	text : string
} 

/* Typography (차량 기본정보 ) */
const McTypography = ({text , variant}:McTypographyProps) => {
	return (
		<Typography variant={variant}>
			{text}
		</Typography>
	)
}

export default McTypography;