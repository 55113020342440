import 'prismjs/themes/prism.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/chart/dist/toastui-chart.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import '@toast-ui/editor/dist/i18n/ko-kr';

import React from 'react';
import styled from 'styled-components';
import JMeditor from 'jm-custom-editor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
interface MeetchaEditorProps {
    editorData: string;
    editorToolbarBgColor?: string;
    editorMainContentHeight?: string;
    onEditorDataChange: (event: any, editor: any) => void;
}

const MeetchaEditor = ({
    editorData,
    onEditorDataChange,
    editorToolbarBgColor,
    editorMainContentHeight,
}: MeetchaEditorProps) => {
    return (
        <EditorWrap>
            <CKEditor editor={JMeditor} data={editorData} onChange={onEditorDataChange} />{' '}
        </EditorWrap>
    );
};

export default MeetchaEditor;

const EditorWrap = styled.div.attrs(
    (props: { editorToolbarBgColor: string; editorMainContentHeight: string }) => props
)`
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        height: ${(props) => (props.editorMainContentHeight ? props.editorMainContentHeight : '570px')};
        overflow-y: scroll;
    }

    .ck.ck-editor__main > .ck-editor__editable {
        height: ${(props) => (props.editorMainContentHeight ? props.editorMainContentHeight : '570px')};
        overflow-y: scroll;
    }

    .ck-source-editing-area {
        height: ${(props) => (props.editorMainContentHeight ? props.editorMainContentHeight : '570px')};
    }

    .ck-source-editing-area textarea {
        color: #3d3dff;
        overflow-y: scroll;
    }

    .ck-reset_all :not(.ck-reset_all-excluded *),
    .ck.ck-reset_all {
        background-color: rgb(247, 244, 244);
        background-color: ${(props) =>
            props.editorToolbarBgColor ? props.editorToolbarBgColor : 'rgb(247, 244, 244)'};
    }
`;

// @toast-ui/react-editor
// import { Editor } from '@toast-ui/react-editor';
// interface MeetchaEditorProps {
//     editorRef: any;
//     initData: any;
// }
// React.useEffect(() => {
//     if (initData) editorRef.current?.getInstance().setHTML(initData);
// }, [initData]);
// <Editor
//     ref={editorRef}
//     height="100%"
//     language="ko-KR"
//     previewStyle="vertical"
//     hideModeSwitch
//     usageStatistics={false}
//     initialEditType="markdown"
//     toolbarItems={[
//         ['heading', 'bold', 'italic', 'strike'],
//         ['hr', 'quote'],
//         ['ul', 'ol', 'task', 'indent', 'outdent'],
//         ['table'],
//         ['code', 'codeblock'],
//     ]}
//     plugins={[chart, codeSyntaxHighlight, colorSyntax, tableMergedCell, uml]}
// />
