import * as React from 'react';
import { FormControl , TextField , InputAdornment, Box} from '@mui/material';
import McTextField from 'carManagement/component/McTextField';

interface CarPriceGroupProps {
	carInfoData : any
	handleInputText : (name:string) => (e:any) => void
}
/* 구매시 가격 , 판매 희망가격 , 판매 증감액 */
const CarPriceGroup = ({carInfoData , handleInputText}:CarPriceGroupProps) => {

	return (
		<FormControl sx={{ minWidth: 120 }}>
			<Box sx={{m:1}}>
				<McTextField 
					id={'oldPrice'}
					label={'구매시 가격'} 
					variant={'outlined'}
					onChange={handleInputText}
					value={carInfoData['oldPrice']}
					endAdornment={'만원'}
					placeholder={''}
				/>
			</Box>
			<Box sx={{m:1}}>
				<McTextField 
					id={'price'}
					label={'판매 희망가격'} 
					variant={'outlined'}
					onChange={handleInputText}
					value={carInfoData['price']}
					endAdornment={'만원'}
					placeholder={''}
				/>
			</Box>
			<Box sx={{m:1}}>
				<McTextField 
					id={'addPrice'}
					label={'판매 증감액'} 
					variant={'outlined'}
					onChange={handleInputText}
					value={carInfoData['addPrice']}
					endAdornment={'만원'}
					placeholder={''}
				/>
			</Box>
			
			{/* <TextField 
				id={'oldPrice'}
				value={carInfoData['oldPrice']}
				sx={{m:2}}
				label={'구매시 가격'} 
				variant={'outlined'}
				placeholder={''}
				InputProps={{
					endAdornment: <InputAdornment position="end">{'만원'}</InputAdornment>,
				}}
				onChange={handleInputText('oldPrice')}
			/>
			<TextField 
				id={'price'}
				value={carInfoData['price']}
				sx={{m:2}}
				label={'판매 희망가격'} 
				variant={'outlined'}
				placeholder={''}
				InputProps={{
					endAdornment: <InputAdornment position="end">{'만원'}</InputAdornment>,
				}}
				onChange={handleInputText('price')}
			/>
			<TextField 
				id={'addPrice'} 
				value={carInfoData['addPrice']}
				sx={{m:2}}
				label={'판매 증감액'} 
				variant={'outlined'}
				placeholder={''}
				InputProps={{
					endAdornment: <InputAdornment position="end">{'만원'}</InputAdornment>,
				}}
				onChange={handleInputText('addPrice')}
			/> */}
		</FormControl>
	)
}

export default CarPriceGroup;