import * as React from 'react'
import { Container } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import MembershipTerms from '../signUp/main/MembershipTerms'
import SignUp from '../signUp/main/SignUp'
interface JoinProps {

}
const JoinRoute = () => {
  return (
	<Routes>
		<Route path='/terms' element = {<Container maxWidth="md"><MembershipTerms/></Container> } />
		<Route path='/signUp' element = {<Container maxWidth="md"><SignUp/></Container> } />
	</Routes>    
  )
}
export default JoinRoute