import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface CategoryItemProps {
    info: { name: string; index: number; isSelect: boolean };
    data: any;
    handleDragEnd: (dragId: any, currentId: any) => void;
    //post: (from: number, to: number, number: any) => void;
    handleCategory: any;
    handleSelectData: (item: any) => void;
}

const CategoryItem = React.memo(
    ({ info, data, handleDragEnd, handleCategory, handleSelectData }: CategoryItemProps) => {
        const { number, categoryName, sort } = data;
        const { name, index, isSelect } = info;
        const [{ isDragging }, dragRef, previewRef] = useDrag(
            () => ({
                type: name,
                item: { number, index, sort },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(), //isDragging 변수가 현재 드래깅중인지 아닌지를 리턴해주는 부분.
                }),
                end: (item: any, monitor) => {
                    const didDrop = monitor.didDrop();
                    if (!didDrop && item.index !== index) {
                        //api 호출
                        // post(item.index, index, number);
                        handleDragEnd(item.number, item.index);
                    }
                },
            }),
            [data, index, handleDragEnd]
        );

        const [, dropRef] = useDrop(
            () => ({
                accept: name,
                canDrop: () => false,
                hover(item: any) {
                    // if (startNumber !== number) {
                    //     moveArray(startNumber, index);
                    // }\
                    if (item.index !== index) {
                        item.index = index;
                    }
                },
            }),
            [index]
        );

        const opacity = isDragging ? 0 : 1;

        return (
            <div ref={previewRef}>
                <div ref={dropRef} style={{ opacity: opacity }}>
                    <div
                        ref={dragRef}
                        style={{
                            color: isSelect ? '#FFFFFF' : '#000000',
                            backgroundColor: isSelect ? 'rgb(129 143 151)' : '#FFFFFF',
                            padding: '5px 1px 5px 0px',
                            cursor: 'grab',
                        }}
                        onClick={() => {
                            handleCategory();
                            handleSelectData(data);
                        }}
                    >
                        {categoryName}
                    </div>
                </div>
            </div>
        );
    }
);

export default CategoryItem;
