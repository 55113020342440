import * as React from 'react'
import { Box } from '@mui/material';
import McTypography from 'carManagement/component/McTypography';
import McTextField from 'carManagement/component/McTextField';

interface CarPrfomncChckUrlProps {
	prfomncChckUrl: string
	handleInputText : (name:string) => (e:any) => void ,
} 

/* 성능점검표 */
	const CarPrfomncChckUrl = ({prfomncChckUrl , handleInputText}:CarPrfomncChckUrlProps) => {
		
	return (
		<>
			<Box sx={{ width: '100%', minWidth: 180 ,mb : 2 , borderBottom : 1}}>
				<McTypography variant='h5' text='성능점검기록부 URL 등록'/>
			</Box>
			<Box sx={{backgroundColor : '#F9F9F9'}}>
				<Box sx={{'& > :not(style)': {width: '92%' }}}>
					<McTextField 
						id={'prfomncChckUrl'}
						label={''}
						value={prfomncChckUrl}
						variant={'outlined'}
						onChange={handleInputText}
						size={'small'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 성능점검 위탁업체가 성능점검기록부를 웹페이지로(URL)로 제공 시 사용하실 수 있습니다.'}
						variant={'subtitle2'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 성능점검 기록부를 URL로 등록하실 경우 2단계 상태의 상태진단기록으로 이동하지 않고 등록이 완료됩니다.'}
						variant={'subtitle2'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- URL은 "http://" 를 포함한 영문/숫자로만 허용하며 1000 글자를 초과할 수 없습니다.'}
						variant={'subtitle2'}
					/>
				</Box>
				<Box sx={{m:1}}>
					<McTypography 
						text={'- 성능점검 기록부를 이미지와 혼합하여 사용할 수 없습니다.'}
						variant={'subtitle2'}
					/>
				</Box>
			</Box>
		</>
	)
}

export default CarPrfomncChckUrl;