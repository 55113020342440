import React from 'react';
import Title from 'home/Title';
import {
    Box,
    Button,
    Paper,
    Stack,
    Pagination,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import AddBannerModal from './AddBannerModal';
import ModifyBannerModal from './ModifyBannerModal';
import { customAxios, testAxios } from 'axios/customAxios';
import { InitWrap } from 'siteManagement/styled/SiteManagementStyled';

const PAGESIZE: number = 10;

export interface BannerInfo {
    number: number;
    groupid: string;
    title: string;
    mode: string;
    content: string;
    img: string;
    link: string;
    linkTarget: string;
    width: number;
    height: number;
    viewcount: number;
    linkcount: number;
    regdate: number;
    editdate: number;
    viewdate: number;
    linkdate: number;
    startdate: number;
    enddate: number;
    display: string;
}

interface BannerState {
    pageNo: number;
    pageSize: number;
    groupid: string;
    searchKeyword: string;
    searchSendStDt: string;
    searchSendEdDt: string;
}

interface BannerInfoList extends Array<BannerInfo> {}

const convertTimestampToDateTime = (timeString: any) => {
    let lTimestamp;
    if (typeof timeString === 'string') {
        lTimestamp = new Date(Number(timeString));
    } else {
        lTimestamp = new Date(timeString);
    }
    lTimestamp.setHours(lTimestamp.getHours() + lTimestamp.getTimezoneOffset() / 60);
    let lMonth = lTimestamp.getMonth() + 1;
    let lDate = lTimestamp.getDate();
    let lHour = lTimestamp.getHours();
    let lMin = lTimestamp.getMinutes();
    let returnVal = [
        lTimestamp.getFullYear(),
        lMonth.toString().padStart(2, '0'),
        lDate.toString().padStart(2, '0'),
    ].join('-');
    returnVal = returnVal + ' ' + lHour.toString().padStart(2, '0') + ':' + lMin.toString().padStart(2, '0');
    return returnVal;
};

const confirmAlert = (message: string, onAlertConfirm: any, onAlertCancel: any) => {
    if (!message || message === '') return;
    if (!onAlertConfirm || typeof onAlertConfirm !== 'function') return;
    if (!onAlertCancel || typeof onAlertCancel !== 'function') return;
    const confirmAction = () => {
        if (window.confirm(message)) {
            onAlertConfirm();
        } else {
            onAlertCancel();
        }
    };
    confirmAction();
};

const IntegratedBannerList = () => {
    const [page, setPage] = React.useState<number>(1);
    const [isRefresh, setIsRefresh] = React.useState<boolean>(false);
    const [addBannerOpen, setAddBannerOpen] = React.useState<boolean>(false);
    const [modifyBannerOpen, setModifyBannerOpen] = React.useState<boolean>(false);
    const [modifyBnanerInfo, setModifyBnanerInfo] = React.useState<BannerInfo | null>(null);
    const [ogriginGroupidList, setOgriginGroupidList] = React.useState<string[]>([]);
    const [groupidList, setGroupidList] = React.useState<string[]>(['전체']);
    const [bannerList, setBannerList] = React.useState<BannerInfoList>();
    const [bannerTotalCnt, setBannerTotalCnt] = React.useState<number>(0);
    const [bannerSate, setBannerState] = React.useState<BannerState>({
        pageNo: 1,
        pageSize: PAGESIZE,
        groupid: '전체',
        searchKeyword: '',
        searchSendStDt: '',
        searchSendEdDt: '',
    });
    const { pageNo, pageSize, groupid, searchKeyword, searchSendStDt, searchSendEdDt } = bannerSate;

    React.useEffect(() => {
        fetchBannerList();
    }, [page]);

    React.useEffect(() => {
        if (isRefresh) {
            fetchBannerList();
            setIsRefresh(false);
        }
    }, [isRefresh]);

    const handleBannerStateOnChange = (e: any, type: string) => {
        switch (type) {
            case 'groupid':
                setBannerState({ ...bannerSate, groupid: e.target.value });
                break;
            case 'searchSendStDt':
                setBannerState({ ...bannerSate, searchSendStDt: e.target.value });
                break;
            case 'searchSendEdDt':
                setBannerState({ ...bannerSate, searchSendEdDt: e.target.value });
                break;
            case 'searchKeyword':
                setBannerState({ ...bannerSate, searchKeyword: e.target.value });
                break;
        }
    };

    const fetchBannerList = async () => {
        const config = {
            params: {
                ...bannerSate,
                pageNo: page,
                groupid: groupid === '전체' ? '' : groupid,
                searchSendStDt: searchSendStDt === '' ? '' : `${searchSendStDt.replace('T', ' ')}:00`,
                searchSendEdDt: searchSendEdDt === '' ? '' : `${searchSendEdDt.replace('T', ' ')}:59`,
            },
        };
        try {
            const response = await customAxios.get('/meetcha/manager/site/integrated/banner/list', config);
            // const response = await testAxios.get(
            //     'http://192.168.10.91:8080/meetcha/manager/site/integrated/banner/list',
            //     config
            // );
            if (response.status === 200 && response.data) {
                setBannerList(response.data.data);
                setBannerTotalCnt(response.data.apiinfo.totalcnt);
                if (groupidList.length === 1) {
                    setGroupidList(groupidList.concat(response.data.groupids));
                    setOgriginGroupidList(response.data.groupids);
                }
            }
        } catch (error) {
            alert('네트워크 에러가 발생하였습니다.');
        }
    };

    const pagingCount = () => {
        if (bannerTotalCnt % pageSize > 0) {
            return Math.floor(bannerTotalCnt / pageSize) + 1;
        } else {
            return Math.floor(bannerTotalCnt / pageSize);
        }
    };

    const handleBannerStateReset = () => {
        setBannerState({
            ...bannerSate,
            groupid: '전체',
            searchKeyword: '',
            searchSendStDt: '',
            searchSendEdDt: '',
        });
        setPage(1);
        setIsRefresh(true);
    };

    const handleSearch = () => {
        if (searchKeyword.trim() !== '' && searchKeyword.trim().length < 2) {
            alert('검색어는 2글자 이상 입력 하세요.');
            return;
        }
        if (page > 1) {
            setPage(1);
        } else {
            fetchBannerList();
        }
    };

    const openModifyBannerModal = (info: BannerInfo) => {
        setModifyBnanerInfo(info);
        setModifyBannerOpen(true);
    };

    const maintainPageRefresh = () => {
        setIsRefresh(true);
    };

    const deleteBanner = (info: BannerInfo) => {
        const check = async () => {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            const jsonData = {
                ...info,
            };
            const formData = new FormData();
            formData.append('process', 'DELETE');
            formData.append('data', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));
            try {
                const response = await customAxios.post('/meetcha/manager/site/modify/banner', formData, config);
                if (response.status === 200 && response.data === 'success') {
                    maintainPageRefresh();
                    alert('배너삭제 성공');
                } else {
                    alert('배너삭제 실패');
                }
            } catch (error) {
                alert('배너삭제 실패');
            }
        };
        const cancel = () => {};
        confirmAlert('배너를 삭제하시겠습니까?', check, cancel);
    };

    return (
        <InitWrap>
            <Title>통합배너 관리</Title>
            <Box sx={{ mb: 2, mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <FormControl sx={{ m: 1, ml: 0, minWidth: 150 }} size="small">
                        <InputLabel id="demo-select-small">배너그룹</InputLabel>
                        <Select
                            id="demo-select-small"
                            label="배너그룹"
                            labelId="demo-select-small"
                            value={groupid}
                            onChange={(e: any) => handleBannerStateOnChange(e, 'groupid')}
                        >
                            {groupidList.map((item) => (
                                <MenuItem key={`${item}-${item}`} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }} size="small">
                        <TextField
                            id="date"
                            label="배너시작일"
                            type="datetime-local"
                            size="small"
                            sx={{ minWidth: 230, width: 230 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={searchSendStDt}
                            onChange={(e: any) => handleBannerStateOnChange(e, 'searchSendStDt')}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }} size="small">
                        <TextField
                            id="date"
                            label="배너종료일"
                            type="datetime-local"
                            size="small"
                            sx={{ minWidth: 230, width: 230 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={searchSendEdDt}
                            onChange={(e: any) => handleBannerStateOnChange(e, 'searchSendEdDt')}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }} size="small">
                        <TextField
                            id="outlined-size-small"
                            size="small"
                            label="배너명 검색"
                            sx={{ width: 200 }}
                            value={searchKeyword}
                            onChange={(e: any) => handleBannerStateOnChange(e, 'searchKeyword')}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button variant="contained" style={{ height: 40 }} color="info" onClick={handleSearch}>
                            검색
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button
                            variant="contained"
                            style={{ height: 40 }}
                            color="error"
                            onClick={handleBannerStateReset}
                        >
                            초기화
                        </Button>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button
                            variant="contained"
                            style={{ height: 40, minWidth: 90 }}
                            color="success"
                            onClick={() => setAddBannerOpen(true)}
                        >
                            배너등록
                        </Button>
                    </FormControl>
                </Box>
            </Box>

            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{`전체 : ${bannerTotalCnt} 건`}</Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>번호</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>그룹명</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>배너제목</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>배너시작일</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>배너마감일</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>노출여부</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>설정</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bannerList &&
                            bannerList.map((bannerInfo: BannerInfo, index: number) => (
                                <TableRow key={bannerInfo.number}>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {bannerTotalCnt - (page - 1) * PAGESIZE - index}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{bannerInfo.groupid}</TableCell>
                                    <TableCell style={{ textAlign: 'center', minWidth: 100, maxWidth: 300 }}>
                                        {bannerInfo.title}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {convertTimestampToDateTime(bannerInfo.startdate)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {convertTimestampToDateTime(bannerInfo.enddate)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{bannerInfo.display}</TableCell>
                                    <TableCell style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                                        <Button
                                            onClick={() => openModifyBannerModal(bannerInfo)}
                                            variant="contained"
                                            color="warning"
                                            size="small"
                                            style={{ margin: '0 5px' }}
                                        >
                                            수정
                                        </Button>
                                        <Button
                                            onClick={() => deleteBanner(bannerInfo)}
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            style={{ margin: '0 5px' }}
                                        >
                                            삭제
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={2} style={{ alignItems: 'center', padding: 30 }}>
                <Pagination
                    count={pagingCount()}
                    defaultPage={1}
                    page={page}
                    onChange={(e: React.ChangeEvent<unknown>, page: number) => {
                        setPage(page);
                    }}
                    color="secondary"
                    variant="outlined"
                    showFirstButton
                    showLastButton
                />
            </Stack>
            {addBannerOpen && (
                <AddBannerModal
                    addBannerOpen={addBannerOpen}
                    setAddBannerOpen={setAddBannerOpen}
                    groupidList={ogriginGroupidList}
                    handleBannerStateReset={handleBannerStateReset}
                />
            )}
            {modifyBannerOpen && (
                <ModifyBannerModal
                    data={modifyBnanerInfo}
                    modifyBannerOpen={modifyBannerOpen}
                    setModifyBannerOpen={setModifyBannerOpen}
                    groupidList={ogriginGroupidList}
                    maintainPageRefresh={maintainPageRefresh}
                />
            )}
        </InitWrap>
    );
};

export default IntegratedBannerList;
