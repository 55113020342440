import * as React from 'react';

import { ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LogoutIcon from '@mui/icons-material/Logout';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavigateFunction } from 'react-router-dom';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';
import SmsIcon from '@mui/icons-material/Sms';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import PolicyIcon from '@mui/icons-material/Policy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import BuildIcon from '@mui/icons-material/Build';

const { REACT_APP_MODE } = EnvPath;

interface AdminProps {
    isMainDrawerMenuVisible: boolean | undefined;
    handleMenu: (name: string) => () => void;
    isExpand: (name: string) => boolean;
    navigate: NavigateFunction;
}

interface DealerProps {
    isMainDrawerMenuVisible: boolean | undefined;
    handleMainMenu: () => void;
    isExpand: boolean;
    navigate: NavigateFunction;
}

//통합관리자용 메뉴
export const AdminGroupMenu = ({ isMainDrawerMenuVisible, handleMenu, isExpand, navigate }: AdminProps) => {
    return (
        <>
            <ListItemButton onClick={handleMenu('sellCar')}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="내차팔기" />
                {isExpand('sellCar') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand('sellCar')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.SELL_MY_CAR_TOTAL_LIST_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="전체 리스트" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.SELL_MY_CAR_ORDER_LIST_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ContactPhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary="상담중인 리스트" />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton onClick={handleMenu('carManagement')}>
                <ListItemIcon>
                    <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="차량관리" />
                {isExpand('carManagement') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand('carManagement')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate(Path.CAR_DEALER_LIST_PATH)}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="차량리스트" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.CAR_REGISTRATION_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <AddBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary="차량등록" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.CAR_GUBUN_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <SmsFailedIcon />
                        </ListItemIcon>
                        <ListItemText primary="차종별 구분 설정" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.CAR_CATEGORY_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <NoCrashIcon />
                        </ListItemIcon>
                        <ListItemText primary="카테고리 설정" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.CAR_CONFIG_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="등록폼 옵션 설정" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.CAR_OPTION_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <BuildIcon />
                        </ListItemIcon>
                        <ListItemText primary="옵션 설정" />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton onClick={handleMenu('userManagement')}>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="회원관리" />
                {isExpand('userManagement') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand('userManagement')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate(Path.USER_LIST_PATH)}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="회원리스트" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.USER_TERMS_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <PolicyIcon />
                        </ListItemIcon>
                        <ListItemText primary="약관관리" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.SUB_MANAGER_LIST_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary="관리자관리" />
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton onClick={handleMenu('smsManagement')}>
                <ListItemIcon>
                    <SmsIcon />
                </ListItemIcon>
                <ListItemText primary="SMS관리" />
                {isExpand('smsManagement') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand('smsManagement')} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate('/sms/manageList')}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary="수신자관리" />
                    </ListItemButton>

                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate('/sms/smsSendList')}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ForwardToInboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="발송이력" />
                    </ListItemButton>
                </List>
            </Collapse>
            {/* 디자인관리 */}
            <ListItemButton onClick={handleMenu('siteManagement')}>
                <ListItemIcon>
                    <DisplaySettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="사이트관리" />
                {isExpand('siteManagement') ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand('siteManagement')} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    // subheader={isMainDrawerMenuVisible && <ListSubheader>배너 관리</ListSubheader>}
                    disablePadding
                >
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate(Path.SITE_BANNER_INTEGRATEDLIST_PATH)}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewCarouselOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="통합배너 관리" />
                    </ListItemButton>
                    {/* <ListItemButton onClick={() => navigate(Path.BANNER_ADD_PATH)}>
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <AddToPhotosOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="배너정보 추가" />
                    </ListItemButton> */}
                </List>
                {/* TODO: 추후 쓰세용 */}
                {/* {isMainDrawerMenuVisible && (
                    <>
                        <List
                            sx={{ pl: 1 }}
                            component="div"
                            subheader={<ListSubheader>이미지 관리</ListSubheader>}
                            disablePadding
                        />
                        <List
                            sx={{ pl: 1 }}
                            component="div"
                            subheader={<ListSubheader>기타 설정</ListSubheader>}
                            disablePadding
                        />
                    </>
                )} */}
            </Collapse>
        </>
    );
};

//내차팔기 딜러용 메뉴
export const SellCarDealerGroupMenu = ({
    isMainDrawerMenuVisible,
    handleMainMenu,
    isExpand,
    navigate,
}: DealerProps) => {
    return (
        <>
            <ListItemButton onClick={handleMainMenu}>
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="내차팔기" />
                {isExpand ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.SELL_MY_CAR_TOTAL_LIST_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="전체 리스트" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => {
                            navigate(Path.SELL_MY_CAR_ORDER_LIST_PATH);
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ContactPhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary="상담중인 리스트" />
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
};

//차량관리 딜러용 메뉴
export const CarManagementGroupMenu = ({
    isMainDrawerMenuVisible,
    handleMainMenu,
    isExpand,
    navigate,
}: DealerProps) => {
    return (
        <>
            <ListItemButton onClick={handleMainMenu}>
                <ListItemIcon>
                    <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="차량관리" />
                {isExpand ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate(Path.CAR_DEALER_LIST_PATH)}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="차량리스트" />
                    </ListItemButton>
                    {/* <ListItemButton sx={{ pl: isMainDrawerMenuVisible ? 5 : 3  }} onClick={() => {navigate('/car/carRegistration')}}>
						<ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
							<AddBoxIcon />
						</ListItemIcon>
						<ListItemText primary="차량등록" />
					</ListItemButton> */}
                </List>
            </Collapse>
        </>
    );
};

export const CarMemberGroupMenu = ({ isMainDrawerMenuVisible, handleMainMenu, isExpand, navigate }: DealerProps) => {
    return (
        <>
            <ListItemButton onClick={handleMainMenu}>
                <ListItemIcon>
                    <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="차량관리" />
                {isExpand ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: isMainDrawerMenuVisible ? 5 : 3 }}
                        onClick={() => navigate(Path.CAR_DEALER_LIST_PATH)}
                    >
                        <ListItemIcon sx={{ justifyContent: isMainDrawerMenuVisible ? 'center' : 'normal' }}>
                            <ViewListIcon />
                        </ListItemIcon>
                        <ListItemText primary="차량리스트" />
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
};
