import * as React from 'react'
import { Box, Button, Grid, TextField , InputAdornment } from '@mui/material';
import McTypography from 'carManagement/component/McTypography';
import McTextField from 'carManagement/component/McTextField';
import axios from 'axios';
import McSelectItem from 'carManagement/component/McSelectItem';
import { getStorageItem } from 'util/sessionStorage';
import { customAxios } from 'axios/customAxios';
import { EnvPath } from 'util/path/EnvPath';

interface CarSellerInfoProps {
	carInfoData : any,
	siguData:any,
	handleCarInfoData : (name:string) => (e:any) => void,
	handleInputText : (name:string) => (e:any) => void ,
	handleCarInfoSiGu : (si:string,gu:string) => void,
	handleCarSellerSearchModal : () => void 
}

const CarSellerInfo = ({carInfoData , siguData , handleCarInfoData , handleInputText , handleCarInfoSiGu , handleCarSellerSearchModal}:CarSellerInfoProps) => {

	const group = getStorageItem('group');

	const [siGuDong , setSiiGuDong] = React.useState({
		si : [],
		gu : [],
		dong : []
	});

	const selectSiGuDongData = (addr:string) => {

		const copySiGuDong:any = {...siGuDong};

		if(addr === 'si'){
			return siGuDong.si
		}else if(addr === 'gu'){
			return copySiGuDong.gu.filter((item:any) => item.si.toString() === carInfoData.si.toString());
		}else {
			const arr:Array<any> = copySiGuDong.dong.filter((item:any) => item.guNumber.toString() === carInfoData.gu.toString());
			if(arr.length){
				if(arr[0].dongTitle.includes('\r\n')){
					return arr[0].dongTitle.split('\r\n');
				}else {
					return arr[0].dongTitle.split('\n');
				}
			}else {
				return [];
			}
		}
	}	

	//carFilterData api 호출
	const fetchCarSiGuDong = async () => {
		try {
			
			const response = await customAxios.get(`/meetcha/car/manager/getCarAddressInfo`);

			if(response.status === 200 && response.data){
				const {carSi , carSiGu , carSiGuDong} = response.data.carAddressInfo;
				setSiiGuDong({
					...siGuDong,
					si : carSi,
					gu : carSiGu,
					dong : carSiGuDong
				});
				if(siguData[0] && siguData[1]){
					let si = carSi.find((item:any) => item.si.includes(siguData[0])).number;	
					let gu = '0'
					if(si){
						gu = siguData[1];
					}						
					handleCarInfoSiGu(si , gu);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}
	
	React.useEffect(() => {
		fetchCarSiGuDong();
	}, [siguData])
	
	return (
		<>
			<Box sx={{ width: '100%', minWidth: 180 ,mb : 2 , borderBottom : 1}}>
				<McTypography variant='h5' text='판매자 정보'/>
			</Box>
			<Grid container spacing={{ xs: 2, sm: 2, md: 2 }} columns={{ xs: 2, sm : 4 ,md: 6 , lg:12}}>
				{/* 구매시 가격 , 판매 희망가격 , 판매 증감액 */}
				<Grid item xs={2} sm={2} md={3} lg={6}>
					{
						group === '99' ?
							<>
								<TextField 
									sx={{m:1}}
									id={'id'} 
									label={'아이디'} 
									variant={'outlined'} 
									value={carInfoData.id} 
									onChange={handleInputText('id')} 
									size={'small'}
									disabled={true}
								/>
								<Button variant='contained' sx={{m:1 , ml : 0 , height : 40}} onClick={handleCarSellerSearchModal}>검색</Button>
							</>
						:
							<TextField sx={{m:1}} id={'id'} label={'아이디'} variant={'outlined'} value={carInfoData.id} onChange={handleInputText('id')} size={'small'} disabled={true}/>
					}
					<TextField sx={{m:1}} id={'phone1'} label={'핸드폰'} variant={'outlined'} value={carInfoData.phone1} onChange={handleInputText('phone1')} size={'small'} />
				</Grid >
				{/* 매매종류 , 할부 가능여부 , 보험이력 공개여부 */}
				<Grid item xs={2} sm={2} md={3} lg={6}>
					<TextField sx={{m:1}} id={'sangho'} label={'상사명'} variant={'outlined'} value={carInfoData.sangho} onChange={handleInputText('sangho')} size={'small'} disabled={true}/>
					<TextField sx={{m:1}} id={'phone2'} label={'전화번호'} variant={'outlined'} value={carInfoData.phone2} onChange={handleInputText('phone2')} size={'small'}/>
				</Grid >
				<Grid item xs={2} sm={4} md={6} lg={12} sx={{'& > :not(style)': {width: '92%' }}}>
					<TextField sx={{m:1}} id={'email'} label={'이메일 주소'} variant={'outlined'} value={carInfoData.email} onChange={handleInputText('email')} size={'small'} disabled={true}/>
				</Grid >
					{
						(siGuDong.si.length && siGuDong.gu.length && siGuDong.dong.length) ?
						<Grid item xs={2} sm={4} md={12}>
							<Box sx={{ml:1}}>
								<McTypography text='소재지' variant='body1'/>
							</Box>
							<Box>
								<McSelectItem data={selectSiGuDongData('si')} title={'지역선택'} id={'si'} value={carInfoData.si} label={'지역선택'} onChange={handleCarInfoData} defaultMenu={true} disabled={true}/>
								<McSelectItem data={selectSiGuDongData('gu')} title={'구선택'} id={'gu'} value={carInfoData.gu} label={'구선택'} disabled={true} onChange={handleCarInfoData} defaultMenu={true}/>
								<McSelectItem data={selectSiGuDongData('dong')} title={'동선택'} id={'dong'} value={carInfoData.gu === '0' ? '0' : carInfoData.dong} label={'동선택'} disabled={true} onChange={handleCarInfoData} defaultMenu={true}/>
								<TextField sx={{m:1}} id={'addr'} label={'추가 주소'} variant={'outlined'} value={carInfoData.addr} onChange={handleInputText('addr')} />
							</Box>
						</Grid >
						:
						null
					}
			</Grid>
		</>
	)
}

export default CarSellerInfo;