import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Path } from 'util/path/RoutePath';

interface CarGubunModalProps {
    data: any;
    onClose: () => void;
    handleCarGubunValue: (code: string, name: string) => () => void;
}

const CarGubunModal = ({ data, onClose, handleCarGubunValue }: CarGubunModalProps) => {
    const navigate = useNavigate();
    const style: any = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        minHeight: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal open={true} onClose={onClose}>
                <Box style={style}>
                    <Box sx={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF', p: 2 }}>
                        <Typography fontWeight={'bold'} sx={{ ml: 2 }}>
                            해당모델의 차종을 선택해주세요
                        </Typography>
                        {data.length
                            ? data.map((item: any) => (
                                  <Button
                                      key={item.conf_code}
                                      onClick={handleCarGubunValue(item.conf_code, item.conf_name)}
                                      sx={{ m: 2, color: '#000000' }}
                                  >
                                      {item.conf_name}
                                  </Button>
                              ))
                            : null}
                        <Button onClick={() => navigate(Path.CAR_GUBUN_PATH)} sx={{ m: 2 }}>
                            차종 추가
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CarGubunModal;
