import * as React from 'react'

import { Box } from '@mui/material';
import McTypography from 'carManagement/component/McTypography';


interface CarPaymentTitleProps {

}

const CarPaymentTitle = () => {

	return (
		<Box sx={{backgroundColor : '#F9F9F9' , p : 2 , mb : 2}}>
			<Box sx={{ mb : 1}}>
				<McTypography text='매물 유료옵션 결제' variant='h5'/>
			</Box>
			<Box sx={{mb : 1}}>
				<li>유료옵션 결제를 통해 현재 차량정보를 눈에 잘 보이게 광고하여 구매효과를 볼 수 있습니다.</li>
				<li>매물유료옵션은 판매중인 차량 페이지에서 원하시는 차량을 선택하여 결제하실 수 있습니다.</li>
			</Box>
		</Box>
	)
}

export default CarPaymentTitle;