import * as React from 'react';
import Container from '@mui/material/Container';
import SignIn from './signUp/main/SignIn';
import Home from './home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import * as cookie from 'util/cookie';
import { getStorageItem } from 'util/sessionStorage';
import { useNavigate } from 'react-router-dom';

import { QueryClientProvider, QueryClient } from 'react-query';
import MembershipTerms from 'signUp/main/MembershipTerms';
import SignUp from 'signUp/main/SignUp';
import JoinRoute from 'route/JoinRoute';
import MainRoute from 'route/MainRoute';
import { Path } from 'util/path/RoutePath';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

export default function App() {
    const navigate = useNavigate();

    /** 신규 탭을 열었을 경우에도 로그인을 했었다면 로그인 처리를 해준다. */
    //   React.useEffect(() => {
    //     if(cookie.getCookie("token") !== undefined) {
    //       navigate("/home");
    //     } else {
    //       navigate("/");
    //     }

    //      /** 뒤로가기 및 앞으로가기 이벤트 감지 */
    //      window.onpopstate = function(event) {
    //       if(event) {

    //         if(cookie.getCookie("token") === undefined) {
    //           navigate("/");
    //         }
    //       }
    //     }
    //   }, [cookie.getCookie("token")]);
    React.useEffect(() => {
        if (getStorageItem('token')) {
            navigate(Path.HOME_PATH);
        } else {
            navigate(Path.ROOT_PATH);
        }

        /** 뒤로가기 및 앞으로가기 이벤트 감지 */
        window.onpopstate = function (event) {
            if (event) {
                if (!getStorageItem('token')) {
                    navigate(Path.ROOT_PATH);
                }
            }
        };
    }, [getStorageItem('token')]);

    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Container maxWidth="sm">
                            <SignIn />
                        </Container>
                    }
                />
                <Route path="/join/*" element={<JoinRoute />} />
                <Route path="/*" element={<MainRoute />} />
            </Routes>
        </QueryClientProvider>
    );
}
