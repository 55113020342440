import moment from 'moment';
import * as React from 'react';
import Title from 'home/Title';
import styled from 'styled-components';
import { customAxios } from 'axios/customAxios';
import { InitWrap } from 'siteManagement/styled/SiteManagementStyled';
import { Modal, Box, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';

interface AddBannerModalProps {
    addBannerOpen: boolean;
    setAddBannerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    groupidList: string[];
    handleBannerStateReset: () => void;
}

interface AddBannerState {
    groupid: string;
    title: string;
    mode: string;
    link: string;
    linkTarget: string;
    width: number;
    height: number;
    startdate: string;
    enddate: string;
    display: string;
}

interface ToolTipProps {
    tip: string;
    required?: boolean;
}

interface linkTargetOption {
    type: string;
    value: string;
}

const linkTargetOptions: linkTargetOption[] = [
    { type: '', value: '현재창에서' },
    { type: 'window', value: '새창으로' },
    { type: 'C', value: '차량상세' },
    { type: 'B', value: '방송상세' },
    { type: 'I', value: '브라우저' },
    { type: 'N', value: '공지사항' },
    { type: 'T', value: '전화걸기' },
    { type: 'S', value: '스크린이동' },
];

const ToolTip = ({ tip, required }: ToolTipProps) => {
    return (
        <div
            style={{
                fontSize: 11,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247, 244, 244)',
                padding: '10px 10px',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    fontSize: 1,
                    color: '#FFFFFF',
                    padding: '4px 7px 2px 7px',
                    marginRight: 10,
                    backgroundColor: '#9ab341e6',
                    borderRadius: 4,
                }}
            >
                짧은설명
            </div>
            <div style={{ color: '#989898', marginRight: 10 }}>{tip}</div>
            {required && <div style={{ color: '#ff0000', marginRight: 10, fontWeight: 'bold' }}>*필수</div>}
        </div>
    );
};

const AddBannerModal = ({
    addBannerOpen,
    setAddBannerOpen,
    groupidList,
    handleBannerStateReset,
}: AddBannerModalProps) => {
    const [addBannerState, setAddBannerState] = React.useState<AddBannerState>({
        groupid: '',
        title: '',
        mode: 'image',
        link: '',
        linkTarget: '',
        width: 0,
        height: 0,
        startdate: '',
        enddate: '',
        display: 'Y',
    });
    const { groupid, title, mode, link, linkTarget, width, height, startdate, enddate, display } = addBannerState;

    const [bannerImgFile, setBannerImgFile] = React.useState<any>(null);
    const [isDisplayVisible, setIsDisplayVisible] = React.useState<boolean>(false);
    const [bannerImgSrc, setBannerImgSrc] = React.useState<string | ArrayBuffer | null>(null);
    const [isGroupidInputReadOnlyVisible, setIsGroupidInputReadOnlyVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        const nowDate = new Date();
        const initStartDate: string = moment(nowDate).format().slice(0, -9);
        const initEndDate: string = moment(nowDate.setDate(nowDate.getDate() + 30))
            .format()
            .slice(0, -9);
        setAddBannerState({ ...addBannerState, startdate: initStartDate, enddate: initEndDate });
        return () => {
            setAddBannerState({
                groupid: '',
                title: '',
                mode: 'image',
                link: '',
                linkTarget: '',
                width: 0,
                height: 0,
                startdate: '',
                enddate: '',
                display: 'Y',
            });
            setBannerImgSrc(null);
            setBannerImgFile(null);
        };
    }, []);

    const handleAddBannerStateOnChange = (e: any, type: string) => {
        switch (type) {
            case 'groupid':
                setAddBannerState({ ...addBannerState, groupid: e.target.value });
                break;
            case 'title':
                setAddBannerState({ ...addBannerState, title: e.target.value });
                break;
            case 'link':
                setAddBannerState({ ...addBannerState, link: e.target.value });
                break;
            case 'linkTarget':
                setAddBannerState({ ...addBannerState, linkTarget: e.target.value });
                break;
            case 'width':
                setAddBannerState({ ...addBannerState, width: e.target.value });
                break;
            case 'height':
                setAddBannerState({ ...addBannerState, height: e.target.value });
                break;
            case 'startdate':
                setAddBannerState({ ...addBannerState, startdate: e.target.value });
                break;
            case 'enddate':
                setAddBannerState({ ...addBannerState, enddate: e.target.value });
                break;
            case 'display':
                setAddBannerState({ ...addBannerState, display: e.target.value });
                break;
        }
    };

    const onClose = () => setAddBannerOpen(false);

    const onCustomOptionClick = (e: any) => {
        if (e.target.options.selectedIndex === 0) {
            setIsGroupidInputReadOnlyVisible(false);
        } else {
            setIsGroupidInputReadOnlyVisible(true);
        }
    };

    const handleChangeBannerImageFile = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const fileTypeCheck = (file: FileList) => {
            const passType = ['image/jpeg', 'image/png', 'image/gif'];
            return passType.some((val) => val === file[0].type);
        };

        const fileSizeCheck = (file: File) => {
            const maxSize = 1024 * 300;
            return maxSize > file.size ? true : false;
        };

        if (!fileTypeCheck(e.target.files)) {
            setBannerImgFile(null);
            setBannerImgSrc(null);
            alert(' GIF, JPG, JPEG, PNG 이미지파일만 업로드 가능합니다.');
            e.target.files = null;
            return;
        }

        if (!fileSizeCheck(e.target.files[0])) {
            setBannerImgFile(null);
            setBannerImgSrc(null);
            alert(`파일 크기가 300KB가 넘어가면 업로드 할 수 없습니다.`);
            return;
        }

        if (e.target.files[0]) {
            setBannerImgFile(e.target.files[0]);
            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = () => {
                setBannerImgSrc(reader.result);
            };
        }
    }, []);

    const addBannerValidationCheck = () => {
        if (groupid === '') {
            alert('그룹명직접입력 시 그룹명을 입력하세요');
            return false;
        } else if (title.trim() === '') {
            alert('배너제목을 입력하세요');
            return false;
        } else if (!bannerImgFile) {
            alert('배너업로드 이미지 파일을 선택해 주세요');
            return false;
        }
        return true;
    };

    const addBanner = async () => {
        if (!addBannerValidationCheck()) return;

        const config = { headers: { 'content-type': 'multipart/form-data' } };

        const jsonData = {
            ...addBannerState,
            startdate: startdate === '' ? null : new Date(`${startdate.replace('T', ' ')}:00`),
            enddate: enddate === '' ? null : new Date(`${enddate.replace('T', ' ')}:59`),
        };

        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(jsonData)], { type: 'application/json' }));
        formData.append('file', bannerImgFile);

        try {
            const response = await customAxios.post('/meetcha/manager/site/add/banner', formData, config);
            if (response.status === 200 && response.data === 'success') {
                alert('배너등록 성공');
                handleBannerStateReset();
                onClose();
            } else {
                alert('배너등록 실패');
            }
        } catch (error) {
            alert('배너등록 실패');
        }
    };

    return (
        <Modal open={addBannerOpen} onClose={onClose} sx={{ zIndex: 1300 }}>
            <InitWrap>
                <Box sx={style}>
                    <Title>배너등록</Title>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            그룹명
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={
                                                    '배너의 그룹을 선택 하거나 입력하세요. (그룹명직접입력시 글자수 120자 이하)'
                                                }
                                                required
                                            />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="text"
                                                    maxLength={120}
                                                    widthSize="130px"
                                                    isVisible={isGroupidInputReadOnlyVisible}
                                                    readOnly={isGroupidInputReadOnlyVisible}
                                                    value={groupid}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'groupid')}
                                                />
                                                <CustomSelectBox
                                                    onClick={onCustomOptionClick}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'groupid')}
                                                >
                                                    <CustomOption key="" value="">
                                                        그룹명직접입력
                                                    </CustomOption>
                                                    {groupidList.map((item) => (
                                                        <CustomOption key={item} value={item}>
                                                            {item}
                                                        </CustomOption>
                                                    ))}
                                                </CustomSelectBox>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            배너제목
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'배너의 제목을 입력하세요. (글자수 120자 이하)'} required />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="text"
                                                    maxLength={120}
                                                    widthSize="350px"
                                                    value={title}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'title')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            배너업로드
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={
                                                    '업로드할 배너를 선택하세요. (파일사이즈: 300kb이하, 형식: GIF, JPG, JPEG, PNG)'
                                                }
                                                required
                                            />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="file"
                                                    widthSize="76px"
                                                    accept="image/*"
                                                    onChange={handleChangeBannerImageFile}
                                                />
                                            </div>
                                            {typeof bannerImgSrc === 'string' && (
                                                <div style={{ marginTop: 10 }}>
                                                    <img
                                                        src={bannerImgSrc}
                                                        style={{ maxWidth: 350, maxHeight: 350 }}
                                                        alt="banner-upload-image"
                                                    />
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            링크
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={'배너 클릭시 이동할 주소를 입력 하세요. (글자수 120자 이하)'}
                                            />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="text"
                                                    maxLength={120}
                                                    widthSize="500px"
                                                    value={link}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'link')}
                                                />
                                                <CustomSelectBox
                                                    value={linkTarget}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'linkTarget')}
                                                >
                                                    {linkTargetOptions.map((item: linkTargetOption) => (
                                                        <CustomOption key={item.type} value={item.type}>
                                                            {item.value}
                                                        </CustomOption>
                                                    ))}
                                                </CustomSelectBox>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            가로크기
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'입력하지 않으면, 이미지의 원본사이즈로 저장됩니다.'} />
                                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <CustomInput
                                                    type="number"
                                                    widthSize="55px"
                                                    min="0"
                                                    max="9999"
                                                    value={width}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'width')}
                                                />
                                                <CustomSpan>px</CustomSpan>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            세로크기
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'입력하지 않으면, 이미지의 원본사이즈로 저장됩니다.'} />
                                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <CustomInput
                                                    type="number"
                                                    widthSize="55px"
                                                    min="0"
                                                    max="9999"
                                                    value={height}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'height')}
                                                />
                                                <CustomSpan>px</CustomSpan>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            시작시간
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={
                                                    '배너출력 시작일과 시간을 설정할 수 있습니다. 입력하지 않으면, 등록하는 시간을 시작시간으로 간주합니다.'
                                                }
                                            />
                                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <CustomInput
                                                    type="datetime-local"
                                                    widthSize="200px"
                                                    value={startdate}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'startdate')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            종료시간
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={
                                                    '배너출력 종료하는 날짜와 시간을 설정할 수 있습니다. 입력하지 않으면 출력이 되지 않습니다.'
                                                }
                                            />
                                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <CustomInput
                                                    type="datetime-local"
                                                    widthSize="200px"
                                                    value={enddate}
                                                    onChange={(e: any) => handleAddBannerStateOnChange(e, 'enddate')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            출력여부
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip
                                                tip={'선택하지 않으면, 기본적으로 출력 하는 것으로 입력 됩니다.'}
                                            />
                                            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    <CustomRadio
                                                        type="radio"
                                                        value="Y"
                                                        checked={!isDisplayVisible}
                                                        onClick={() => setIsDisplayVisible(false)}
                                                        onChange={(e: any) =>
                                                            handleAddBannerStateOnChange(e, 'display')
                                                        }
                                                    />
                                                    <CustomSpan>출력</CustomSpan>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginRight: 10,
                                                    }}
                                                >
                                                    <CustomRadio
                                                        type="radio"
                                                        value="N"
                                                        checked={isDisplayVisible}
                                                        onClick={() => setIsDisplayVisible(true)}
                                                        onChange={(e: any) =>
                                                            handleAddBannerStateOnChange(e, 'display')
                                                        }
                                                    />
                                                    <CustomSpan>출력안함</CustomSpan>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px 0',
                        }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            style={{ marginRight: 50 }}
                            onClick={addBanner}
                        >
                            배너등록
                        </Button>
                        <Button variant="contained" size="large" color="error" onClick={onClose}>
                            취소
                        </Button>
                    </div>
                </Box>
            </InitWrap>
        </Modal>
    );
};

export default AddBannerModal;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 1300,
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    overflow: 'scroll',
    boxShadow: 24,
    p: 4,
};

const CustomRadio = styled.input`
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 15px;
    height: 15px;
    margin-right: 5px;
`;

const CustomSpan = styled.span`
    color: #000000;
    text-align: center;
    font-size: 13.5px;
    line-height: 0;
`;

const CustomInput = styled.input.attrs(
    (props: { widthSize: string; minWidthSize: string; isVisible: boolean }) => props
)`
    height: 26.5px;
    width: ${(props) => props.widthSize};
    min-width: ${(props) => props.minWidthSize};
    margin-right: 10px;
    border: 1px solid #bdbdc0;
    background: #f3f3f3;
    :focus {
        outline: ${(props) => (props.isVisible ? 'none' : undefined)};
    }
`;

const CustomSelectBox = styled.select`
    height: 26.5px;
`;
const CustomOption = styled.option``;
