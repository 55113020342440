import { Modal, Box, TextField, Button } from '@mui/material';
import React from 'react';

interface CarGubunUpsertModalProps {
    carGubunInput: { code: string; name: string };
    type: string;
    handleInput: (e: any) => void;
    handleConfirm: (type: any) => void;
    handleCanCel: () => void;
}

const CarGubunUpsertModal = ({
    carGubunInput,
    type,
    handleInput,
    handleConfirm,
    handleCanCel,
}: CarGubunUpsertModalProps) => {
    const style: any = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 120,
        maxHeight: 300,
        // width: modalWidth(),
        // height: modalHeight(),
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal open={true} onClose={handleCanCel}>
                <Box sx={style}>
                    <Box
                        sx={{
                            height: '90%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <TextField
                            sx={{ m: 1, maxWidth: 300 }}
                            name={'code'}
                            value={carGubunInput.code}
                            label={'번호'}
                            onChange={handleInput}
                            size={'small'}
                            disabled={Boolean(type === 'update')}
                        />
                        <TextField
                            sx={{ m: 1, maxWidth: 300 }}
                            name={'name'}
                            value={carGubunInput.name}
                            label={'차종'}
                            onChange={handleInput}
                            size={'small'}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {type === 'add' ? (
                            <Button
                                sx={{ m: 1 }}
                                onClick={() => handleConfirm('add')}
                                variant="contained"
                                color="primary"
                            >
                                추가
                            </Button>
                        ) : (
                            <Button
                                sx={{ m: 1 }}
                                onClick={() => handleConfirm('update')}
                                variant="contained"
                                color="primary"
                            >
                                수정
                            </Button>
                        )}
                        <Button sx={{ m: 1 }} onClick={handleCanCel} variant="contained" color="error">
                            취소
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CarGubunUpsertModal;
