import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type AlertDialogProps = {
    handleClose: any;
    handleAccept: any;
    title: string;
    description: string;
};

export default function AlertDialog({ handleClose, handleAccept, title, description }: AlertDialogProps) {
    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };
    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>취소</Button>
                    <Button onClick={handleAccept} autoFocus>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
