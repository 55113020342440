import * as React from 'react';
import { FormHelperText, Box, FormControl, TextField } from '@mui/material';
import { SignUpUserFormInfo, SignUpUserRequireInfo } from 'interface/SignUpUserInfo';
import { FieldErrorsImpl, UseFormRegister, DeepRequired, UseFormWatch } from 'react-hook-form';
import { EmailTextField, SignUpTextField } from './SignUpTextField';

interface UserInfoFormProps {
    register: UseFormRegister<SignUpUserFormInfo>;
    errors: FieldErrorsImpl<DeepRequired<SignUpUserRequireInfo>>;
    watch: UseFormWatch<SignUpUserFormInfo>;
    group: any;
}
const UserInfoUpdateForm = ({ register, errors, watch, group }: UserInfoFormProps) => {
    const { userId, userPass, userPassCom, userName, userNick, userEmail, userPhone, userHphone, comName }: any =
        errors;

    const userGroupByText = (group: number) => {
        switch (group) {
            case 8:
                return '딜러회원';
            case 21:
                return '매입상담사';
            case 22:
                return '영업제휴회원';
            case 20:
                return '카스타일러';
            case 7:
                return '일반회원';
            default:
                return '';
        }
    };

    return (
        <div>
            {/* 회원상세정보 폼에서 아이디는 disabled 처리 */}
            <SignUpTextField
                label={'아이디*'}
                name={'userId'}
                register={register}
                type={'text'}
                required={false}
                error={Boolean(userId)}
                disabled={true}
            />
            <Box sx={{ m: 1, mt: 2, display: 'flex', flexDirection: 'row' }}>
                <FormControl>
                    <TextField
                        disabled={true}
                        variant="standard"
                        sx={{ width: 300 }}
                        size="small"
                        label={'그룹'}
                        value={userGroupByText(group)}
                    />
                </FormControl>
            </Box>
            {/* 회원상세정보 폼에서는 비밀번호 validation 조건 제거 (비밀번호 변경 x 인 케이스) */}
            <SignUpTextField
                label={'비밀번호'}
                name={'userPass'}
                type={'password'}
                register={register}
                helperText={''}
                required={false}
            />
            <SignUpTextField
                label={'이름*'}
                name={'userName'}
                type={'text'}
                register={register}
                required={'이름을 입력해 주세요.'}
                error={Boolean(userName)}
            />
            {userName && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userName)}>
                    {userName.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'닉네임*'}
                name={'userNick'}
                type={'text'}
                register={register}
                required={'닉네임을 입력해 주세요.'}
                error={Boolean(userNick)}
            />
            {userNick && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userNick)}>
                    {userNick.message}
                </FormHelperText>
            )}
            <SignUpTextField label={'해시태그'} name={'extra10'} type={'text'} register={register} required={false} />
            <EmailTextField
                label={'이메일'}
                name={'userEmail'}
                type={'text'}
                register={register}
                required={false}
                error={Boolean(userEmail)}
                watch={watch}
            />
            {userEmail && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userEmail)}>
                    {userEmail.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'연락처'}
                name={'userPhone'}
                type={'text'}
                register={register}
                required={false}
                pattern={{ value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/, message: '전화번호 형식을 맞춰주세요' }}
                error={Boolean(userPhone)}
            />
            {userPhone && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userPhone)}>
                    {userPhone.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'휴대폰'}
                name={'userHphone'}
                type={'text'}
                register={register}
                required={false}
                pattern={{
                    value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                    message: '휴대폰 번호 형식을 맞춰주세요.',
                }}
                error={Boolean(userHphone)}
            />
            {userHphone && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userHphone)}>
                    {userHphone.message}
                </FormHelperText>
            )}
            <SignUpTextField label={'팩스 번호'} name={'userFax'} type={'text'} register={register} required={false} />
            <SignUpTextField label={'사원증 번호'} name={'extra9'} type={'text'} register={register} required={false} />
            <SignUpTextField
                label={'상사명'}
                name={'comName'}
                type={'text'}
                register={register}
                required={false}
                error={Boolean(comName)}
            />
        </div>
    );
};
export default UserInfoUpdateForm;
