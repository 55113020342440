import React from 'react';
import {
    Box,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    ButtonGroup,
    IconButton,
} from '@mui/material';
import { EnvPath } from 'util/path/EnvPath';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CategoryItem from './CategoryItem';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { dateToRegDateTime } from 'util/Util';
import { CarBrandCategory } from 'interface/CarCategoryInterface';
import { customAxios } from 'axios/customAxios';
import McBackdrop from 'component/McBackdrop';
import McDialog from 'carManagement/component/McDialog';
import arrayMove from 'array-move';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const containerStyle = {
    border: 2,
    borderColor: '#f3f3f3',
    //width: 300,
    height: 400,
    overflowY: 'auto',
};
//카테고리 1 ~ 5 단계 리스트 추가 및 UI 적용 , 순서변경 라이브러리 적용
interface CarBrandCategoryFormProps {
    brand: { number: string; name: string };
    handleCategory: (name: string, number: string, categoryName: string) => void;
    handleCategoryInput: (name: string) => (e: any) => void;
    removeCategoryData: (deps: string) => void;
}

const CarBrandCategoryForm = ({
    brand,
    handleCategory,
    handleCategoryInput,
    removeCategoryData,
}: CarBrandCategoryFormProps) => {
    const queryClient = useQueryClient();

    const [forData, setForData] = React.useState<Array<CarBrandCategory>>([]);
    const [korData, setKorData] = React.useState<Array<CarBrandCategory>>([]);
    const [typeNation, setTypeNation] = React.useState('0');

    const [selectData, setSelectData] = React.useState<CarBrandCategory | null>(null);

    const [isDialog, setIsDialog] = React.useState(false);

    const getListCarCategory1 = useQuery(
        [`/meetcha/car/manager/getListCarCategory1`],
        () => customAxios.get(`/meetcha/car/manager/getListCarCategory1`),
        {
            refetchOnWindowFocus: false,
            //staleTime: 10 * 60 * 1000,
            onSuccess: ({ data }) => {
                setKorData(data.filter((item: CarBrandCategory) => item.typeNation === '0'));
                setForData(data.filter((item: CarBrandCategory) => item.typeNation === '1'));
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    const upsert = useMutation((param) => customAxios.post(`/meetcha/car/manager/doSaveCarCategory1`, param), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(`/meetcha/car/manager/getListCarCategory1`);
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const remove = useMutation((param) => customAxios.post(`/meetcha/car/manager/doDeleteCarCategory`, param), {
        onSuccess: (data) => {
            setSelectData(null);
            setIsDialog(false);
            /* 
				삭제한 카테고리의 쿼리 ~ 하위 카테고리 초기화
				ex) 2단계 삭제시 -> 2 , 3 , 4 , 5 초기화 
			*/
            let arr = new Array(5);
            arr.fill('').forEach((i, index: number) => {
                let cId = index + 1;
                queryClient.invalidateQueries(`/meetcha/car/manager/getListCarCategory${cId}`);
            });
            removeCategoryData('1');
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const upsertList: any = useMutation(
        (param) => customAxios.post(`/meetcha/car/manager/doSaveCarCategoryList1`, param),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([`/meetcha/car/manager/getListCarCategory1`]);
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    // const post = (from: number, to: number, number: any) => {
    //     //정방향 , 역방향 구분처리
    //     // let compareFromTo = from < to;
    //     // let interval = to - from;

    //     // postData.forEach((item, index) => {
    //     //     if (compareFromTo) {
    //     //         //정방향
    //     //         if (inRange(index, from, to)) {
    //     //             if (item.number !== number) {
    //     //                 item.sort = Number(item.sort) - 1;
    //     //             }
    //     //         }
    //     //     } else {
    //     //         if (interval === -1) {
    //     //             //역방향 , 한칸 이동
    //     //             if (inRange(index, to, from + 1)) {
    //     //                 if (item.number !== number) {
    //     //                     item.sort = Number(item.sort) + 1;
    //     //                 }
    //     //             }
    //     //         } else {
    //     //             //역방향 , 여러칸 이동
    //     //             if (inRange(index, to + 1, from + 1)) {
    //     //                 if (item.number !== number) {
    //     //                     item.sort = Number(item.sort) + 1;
    //     //                 }
    //     //             }
    //     //         }
    //     //     }
    //     // });
    //     const postData: Array<CarBrandCategory> = typeNation === '0' ? [...korData] : [...forData];

    //     postData.forEach((i, index) => {
    //         i.sort = index + 1;
    //     });

    //     let arr = postData;
    //     if (typeNation === '0') {
    //         setKorData(postData);
    //         arr = arr.concat(forData);
    //     } else {
    //         setForData(postData);
    //         arr = arr.concat(korData);
    //     }

    //     const formData: any = new FormData();
    //     arr.forEach((item, index) => {
    //         formData.append(`carCategoryEntityList[${index}].number`, item['number']);
    //         formData.append(`carCategoryEntityList[${index}].categoryName`, item['categoryName']);
    //         formData.append(`carCategoryEntityList[${index}].regCount`, item['regCount']);
    //         formData.append(`carCategoryEntityList[${index}].regDatetime`, dateToRegDateTime(item.regDatetime));
    //         formData.append(`carCategoryEntityList[${index}].sort`, item['sort']);
    //         formData.append(`carCategoryEntityList[${index}].typeNation`, item['typeNation']);
    //     });

    //     upsertList.mutate(formData);
    // };

    const handleDragEnd = (dragId: any, toIndex: any) => {
        let index = 0;
        let copyData = [];
        //배열 순서변경
        if (typeNation === '0') {
            index = korData.findIndex((item: any) => item.number === dragId);
            copyData = arrayMove([...korData], index, toIndex);
        } else {
            index = forData.findIndex((item: any) => item.number === dragId);
            copyData = arrayMove([...forData], index, toIndex);
        }

        //배열 sorting
        copyData.forEach((i, index) => {
            i.sort = index + 1;
        });

        //배열 합치기
        if (typeNation === '0') {
            setKorData(copyData);
            copyData = copyData.concat(forData);
        } else {
            setForData(copyData);
            copyData = copyData.concat(korData);
        }

        const formData: any = new FormData();

        copyData.forEach((item, index) => {
            formData.append(`carCategoryEntityList[${index}].number`, item['number']);
            formData.append(`carCategoryEntityList[${index}].categoryName`, item['categoryName']);
            formData.append(`carCategoryEntityList[${index}].regCount`, item['regCount']);
            formData.append(`carCategoryEntityList[${index}].regDatetime`, dateToRegDateTime(item.regDatetime, true));
            formData.append(`carCategoryEntityList[${index}].sort`, item['sort']);
            formData.append(`carCategoryEntityList[${index}].typeNation`, item['typeNation']);
        });

        upsertList.mutate(formData);
    };

    const addBrandData = (type: 'add' | 'update') => {
        let sort = typeNation === '0' ? korData.length + 1 : forData.length + 1;

        const formData: any = new FormData();
        if (type === 'update') {
            formData.append('number', selectData!.number);
            formData.append('regCount', selectData!.regCount);
            formData.append('regDatetime', dateToRegDateTime(selectData!.regDatetime, true));
            formData.append('sort', `${selectData!.sort}`);
        } else {
            formData.append('regCount', '0');
            formData.append('regDatetime', dateToRegDateTime());
            formData.append('sort', `${sort}`);
        }
        formData.append('categoryName', brand.name);
        formData.append('typeNation', typeNation);

        upsert.mutate(formData);

        if (type === 'add') {
            removeCategoryData('1');
            setSelectData(null);
        }
    };

    const removeDepsByCategoryData = () => {
        const formData: any = new FormData();
        formData.append('depth', '1');
        formData.append('number', selectData!.number);

        remove.mutate(formData);
    };

    const handleSelectData = (item: CarBrandCategory) => {
        setSelectData(item);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box>
                <Box
                    sx={{
                        //width: 300,
                        backgroundColor: '#f3f3f3',
                        p: 0.5,
                    }}
                >
                    <Typography fontSize={'21'} fontWeight={'bold'} align={'center'}>
                        제조사
                    </Typography>
                </Box>
                <Box sx={containerStyle} display={typeNation === '1' ? 'none' : 'block'}>
                    {korData.length
                        ? korData.map((data: any, index: number) => (
                              <CategoryItem
                                  key={data.number}
                                  info={{
                                      name: 'category_kor',
                                      index: index,
                                      isSelect: Boolean(brand.number === data.number),
                                  }}
                                  data={data}
                                  handleDragEnd={handleDragEnd}
                                  handleCategory={() => handleCategory('brand', data.number, data.categoryName)}
                                  handleSelectData={handleSelectData}
                                  //post={post}
                              />
                          ))
                        : null}
                </Box>
                <Box sx={containerStyle} display={typeNation === '0' ? 'none' : 'block'}>
                    {forData.length
                        ? forData.map((data: any, index: number) => (
                              <CategoryItem
                                  key={data.number}
                                  info={{
                                      name: 'category_for',
                                      index: index,
                                      isSelect: Boolean(brand.number === data.number),
                                  }}
                                  data={data}
                                  handleDragEnd={handleDragEnd}
                                  handleCategory={() => handleCategory('brand', data.number, data.categoryName)}
                                  handleSelectData={handleSelectData}
                                  //post={post}
                              />
                          ))
                        : null}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormControl sx={{ alignSelf: 'flex-start', ml: 0 }}>
                        <RadioGroup
                            row
                            name={'nation'}
                            value={typeNation}
                            onChange={(e) => setTypeNation(e.target.value)}
                        >
                            <FormControlLabel value={'0'} control={<Radio />} label="국산차" />
                            <FormControlLabel value={'1'} control={<Radio />} label="수입차" />
                        </RadioGroup>
                    </FormControl>
                    <TextField fullWidth value={brand.name} onChange={handleCategoryInput('brand')} size={'small'} />
                    <ButtonGroup variant="contained" sx={{ mt: 2 }}>
                        <Button disabled={!Boolean(brand.name)} onClick={() => addBrandData('add')}>
                            추가
                        </Button>
                        <Button
                            disabled={!Boolean(brand.name) || Boolean(brand.name === selectData?.categoryName)}
                            onClick={() => addBrandData('update')}
                            sx={{ backgroundColor: '#5E95D2' }}
                        >
                            수정
                        </Button>
                        <Button
                            disabled={!Boolean(selectData)}
                            sx={{ backgroundColor: '#094E9B' }}
                            onClick={() => setIsDialog(true)}
                        >
                            삭제
                        </Button>
                    </ButtonGroup>
                    <Button
                        onClick={() => removeCategoryData('1')}
                        sx={{ mt: 1 }}
                        variant={'contained'}
                        color={'error'}
                    >
                        초기화
                    </Button>
                    {/* <Button sx={{ backgroundColor: 'red' }}>
                        <AutorenewIcon sx={{ color: '#FFFFFF' }} />
                    </Button> */}
                </Box>
            </Box>
            {isDialog && (
                <McDialog
                    title={'경고'}
                    description={
                        <>
                            {`선택한 카테고리를 삭제시 하위 카테고리를 포함하여 삭제되며 차량정보의 카테고리 정보가 초기화되고 미노출상태로 전환 됩니다.\n`}
                            <span style={{ color: 'red' }}>삭제 하시겠습니까?</span>
                        </>
                    }
                    handleClose={() => setIsDialog(false)}
                    handleConfirm={() => removeDepsByCategoryData()}
                />
            )}
            {Boolean(
                remove.isLoading ||
                    upsert.isLoading ||
                    upsertList.isLoading ||
                    getListCarCategory1.isLoading ||
                    getListCarCategory1.isFetching
            ) && <McBackdrop />}
        </DndProvider>
    );
};

export default CarBrandCategoryForm;
