import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface McCheckBoxProps {
	checked : boolean,
	onChange : (name:string) => (e:any) => void,
	value : any,
	name : string,
	label : string
} 

/* CheckBox (1인소유) */
const McCheckBox = ({onChange ,value ,name ,label , checked}:McCheckBoxProps) => {

	return (
		<>
			<FormControlLabel
				sx={{m : 1 ,  minWidth: 120}}
				control={
					<Checkbox 
						checked={checked}
						name={name}
						value={value}
						onChange={onChange(name)}
					/>
				} 
				label={label} 
			/>
		</>
	)
}

export default McCheckBox;