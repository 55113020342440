import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface McSnackbarProps {
	message : string
	handleSnackbarClose : () => void 
} 

const McSnackbar = ({message , handleSnackbarClose}:McSnackbarProps) => {

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={true}
		anchorOrigin={{ vertical : 'bottom', horizontal : 'center' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={message}
        action={action}
      />
    </div>
  );
}
export default McSnackbar ;