import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
//import { useCookies } from "react-cookie";
//import * as cookie from "./util/cookie";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { getStorageItem, setStorageItem } from 'util/sessionStorage';
import { customAxios } from 'axios/customAxios';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';

interface Header {
    apiinfo: any;
    data: any;
}

type JwtToken = {
    userId: string;
    group: number;
    iss: string;
    iat: number;
    exp: number;
};

const theme = createTheme();

export default function SignIn() {
    const { REACT_APP_API_URL } = EnvPath;

    // const [cookies , setCookie , removeCookie] = useCookies();
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // now 를 전역 변수로 두면 큰일난다. 사이트 들어오는 시간으로 시작 시간을 측정 하게 된다.
        // 로그인 버튼을 클릭 했을 시 시간을 시작으로 하기 위해 handleSubmit 메서드 안에서 지역변수로 설정한다.
        let now = new Date();
        let after1h = new Date();
        // console.log(cookie.getCookie("token"));
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            const response = await axios.post(
                `${REACT_APP_API_URL}/meetcha/sell/consult/login`,
                {
                    userId: data.get('email'),
                    userPw: data.get('password'),
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            if (response.status === 200) {
                let jwt: JwtToken = jwt_decode(response.data.data);

                // console.log(jwt.group);
                //after1h.setMinutes(now.getMinutes() + 60); // after1h를 현재시간의 60분뒤로 정의
                // console.log(response.data);
                //   cookie.setCookie("token" , response.data.data , {path : "/" });
                //   cookie.setCookie("id" , data.get('email') + "" , {path : "/" });
                //   cookie.setCookie("group" , jwt.group+"", {path : "/" });
                setStorageItem('token', response.data.data);
                setStorageItem('id', data.get('email') + '');
                setStorageItem('group', jwt.group + '');

                // 로그인시 customAxios 에 header 를 실어줌
                customAxios.interceptors.request.use((config) => {
                    config.headers!.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
                    return config;
                });

                navigate(Path.HOME_PATH);
            } else {
                alert(response.data.apiinfo.message);
                // console.log(response.data.apiinfo.message);
            }
        } catch (error) {
            alert(`Error : ${error}`);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        MeetCha Manager
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="ID"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            로그인
                        </Button>
                        {/* <Grid container>
              <Grid item xs>
                <MLink href="#" variant="body2">
                  Forgot password?
                </MLink>
              </Grid>
              <Grid item>
                <MLink href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </MLink>
              </Grid>
            </Grid> */}
                    </Box>
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    color="error"
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                    onClick={() => navigate(Path.JOIN_MEMBERSHIP_TERMS_PATH)}
                >
                    회원가입
                </Button>
                {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            </Container>
        </ThemeProvider>
    );
}
