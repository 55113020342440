import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface McSelectItemProps {
	data : Array<any>,
	title : string,
	id : string,
	value : string,
	label : string,
	onChange : (name:string) => (e:any) => void,
	defaultMenu ?: boolean,
	disabled ?: boolean,
	size ?: 'medium' | 'small'
} 

/* SelectItem (용도 , 년 , 월 , 색상 , 연료 , 변속기 , 연비등급 , 테마종류선택 , 노출여부) */
const McSelectItem = ({title, id, value, label , onChange, data , defaultMenu = false , disabled = false , size = 'medium'}:McSelectItemProps) => {

	const menuItemText = (item:any) => {
		return <MenuItem value={item.value} key={item.value} >{item.name}</MenuItem>
	}

	const marginSize = () => {
		if(title === '매매종류'){
			return 2
		}else {
			return 1
		}
	}

	return (
		<FormControl sx={{ m : marginSize() ,  minWidth: 120}} disabled={disabled} size={size}>
			<InputLabel id="demo-simple-select-label">{title}</InputLabel>
			<Select
				id={id}
				value={value}
				label={label}
				onChange={onChange(id)}
			>
				{
					defaultMenu ?
						<MenuItem value={''} >선택안함</MenuItem>
						:
						null
				}
				{
					data.map(item => (
						menuItemText(item)
					))
				}
			</Select>
		</FormControl>
	)
}

export default McSelectItem;