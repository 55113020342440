import React from 'react';
import { Modal, Box } from '@mui/material';
import SignUp from 'signUp/main/SignUp';
import { UserDetailFormInfo, UserDetailInfo } from 'interface/SignUpUserInfo';
import { EnvPath } from 'util/path/EnvPath';

interface UserDetailModalProps {
    data: UserDetailInfo;
    onClose: (isClear?: boolean) => void;
}

/**
 * 회원상세정보 모달
 * @param param0
 * @returns
 */
const UserDetailModal = ({ data, onClose }: UserDetailModalProps) => {
    const style: any = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '80%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
    };

    //유저 정보
    const [userInfo, setUserInfo] = React.useState<any>(null);

    //지번주소 정보
    const [postAddr, setPostAddr] = React.useState({
        zonecode: '',
        address: '',
        detailAddress: '',
    });

    const imgCheck = (photo: string) => {
        if (photo) {
            if (photo.includes(EnvPath.REACT_APP_CAR_IMG_DOMAIN_URL!)) {
                return `${photo}&w=350`;
            } else {
                return `${photo}`;
            }
        } else {
            return '';
        }
    };

    //모달 노출시 form 데이터 형식에 맞게 분리 후 setState해줌
    React.useEffect(() => {
        let obj: UserDetailFormInfo = {
            number: data.number,
            userId: data.userId,
            userName: data.userName,
            userNick: data.userNick,
            comName: data.comName,
            extra9: data.extra9,
            extra10: data.extra10,
            message: data.message,
            group: data.group,
            userPass: '',
            userPassCom: '',
            point: '0',
            userEmail: '',
            userEmailSite: '',
            userEmailDirectInput: '',
            userComSi: '',
            userComGu: '',
            userComDong: '',
            userComAddr: '',
            userFax: '',
            userHphone: '',
            userPhone: '',
            emailFowarding: false,
            smsFowarding: false,
            stateOpen: false,
            photo1: '',
        };

        //포인트
        if (data.point) {
            obj.point = data.point;
        }

        /* 
			이메일 분리
			[test]@[naver.com]
			emailSiteList 에 없는 메일이라면 [직접입력] 처리
		*/
        if (data.userEmail) {
            let emailSiteList = ['naver.com', 'daum.com', 'nate.com', 'gmail.com'];
            let separateEmail = data.userEmail.split('@');
            obj.userEmail = separateEmail[0];
            if (emailSiteList.some((i) => i === separateEmail[1])) {
                obj.userEmailSite = separateEmail[1];
            } else {
                obj.userEmailSite = '직접입력';
                obj.userEmailDirectInput = separateEmail[1];
            }
        }

        /* 상사주소 분리 [시]/[구]/[동]/[상세주소] */
        if (data.extra8) {
            let separateComAddr = data.extra8.split('/');
            if (separateComAddr[0]) {
                obj.userComSi = separateComAddr[0];
            }
            if (separateComAddr[1]) {
                obj.userComGu = separateComAddr[1];
            }
            if (separateComAddr[2]) {
                obj.userComDong = separateComAddr[2];
            }
            if (separateComAddr[3]) {
                obj.userComAddr = separateComAddr[3];
            }
        }

        /* 
			api로 보낼 데이터를 조합할 때 - 를 추가해주기때문에
			연락처 관련 폼은 - 제거
		*/
        if (data.userFax) {
            obj.userFax = data.userFax.replaceAll('-', '');
        }
        if (data.userHphone) {
            obj.userHphone = data.userHphone.replaceAll('-', '');
        }
        if (data.userPhone) {
            obj.userPhone = data.userPhone.replaceAll('-', '');
        }

        setUserInfo(obj);
        /* 지번주소를 넣어줌 */
        setPostAddr({
            address: data.userAddr1,
            detailAddress: data.userAddr2,
            zonecode: data.userZip,
        });
    }, [data]);

    return (
        <div>
            <Modal open={true} onClose={() => onClose()}>
                <Box style={style}>
                    {/* <Box sx={{ width: '100%', backgroundColor: '#FFFFFF', p: 2, overflowY: 'scroll' }}> */}
                    {userInfo && (
                        <SignUp
                            userInfo={userInfo}
                            postCode={postAddr}
                            profileImage={imgCheck(data.photo1)}
                            handleClosedetailModal={onClose}
                        />
                    )}
                    {/* </Box> */}
                </Box>
            </Modal>
        </div>
    );
};

export default UserDetailModal;
