import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

interface RadioButtonProps {
	onChange : (name:string) => (e:any) => void,
	value : string,
	name : string,
	data : Array<string>
} 

/* 사고유무 RadioGroup 처리 */
const CarSagoRadioGroup = ({onChange ,value ,name , data}:RadioButtonProps) => {
	return (
		<FormControl  sx={{ m : 2 ,  minWidth: 120 }}>
			<RadioGroup
				row
				name={name}
				value={value}
				onChange={onChange(name)}
			>
				{
					data.map(item => (
						<FormControlLabel key={item} value={item} control={<Radio />} label={item} />
					))
				}
			</RadioGroup>
		</FormControl>
	)
}

export default CarSagoRadioGroup;