import { FormControl, TextField, SxProps, Theme } from '@mui/material';
import React from 'react';

interface McDatePickerProps {
    info: {
        id: string;
        label: string;
        formSx: SxProps<Theme> | undefined;
        datePickerSx: SxProps<Theme> | undefined;
        size: 'small' | 'medium' | undefined;
    };
    value: string;
    required?: boolean;
    onChange: (e: any) => void;
}

/**
 * datePicker component
 * @param param0
 * @returns
 */
const McDatePicker = ({ info, value, required = false, onChange }: McDatePickerProps) => {
    return (
        <FormControl sx={info.formSx} size="small">
            <TextField
                id={info.id}
                label={info.label}
                type="date"
                value={value}
                sx={info.datePickerSx}
                InputLabelProps={{
                    shrink: true,
                }}
                size={info.size}
                onChange={onChange}
                required={required}
            />
        </FormControl>
    );
};

export default McDatePicker;
