import * as React from "react";

import { Button, ButtonGroup, Box, SxProps, Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { getStorageItem } from "util/sessionStorage";
import { EnvPath } from "util/path/EnvPath";

interface DealerCarItemProps {
    carInfo: any;
    handleYoutubeLink: (link: string) => (e: any) => void;
}

//차량리스트 아이템
const DealerCarItem = ({ carInfo, handleYoutubeLink }: DealerCarItemProps) => {
    const { REACT_APP_CAR_IMG_DOMAIN_URL } = EnvPath;

    const group = getStorageItem("group");

    // 이미지가 없을 때 기본 이미지
    const defaultImage = require(`../../res/images/noImage.png`);
    // 유튜브 링크에 들어가는 유튜브 이미지
    const youtubeImage = require(`../../res/images/youtube.png`);

    // text 배경색이 들어간 style 재활용하기위해 사용
    const multiTextStyle: SxProps<Theme> = {
        mt: 1.5,
        p: 0.5,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        borderRadius: "5px",
        color: "primary.main",
        fontWeight: "medium",
        display: "flex",
        fontSize: 15,
        alignItems: "center",
        "& svg": {
            fontSize: 21,
            mr: 0.5,
        },
    };
    const dealerInfoStyle: SxProps<Theme> = {
        mt: 1.5,
        p: 0.5,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        borderRadius: "5px",
        color: "primary.main",
        fontWeight: "medium",
        fontSize: 15,
        alignItems: "center",
        "& svg": {
            fontSize: 21,
            mr: 0.5,
        },
    };
    // 데이터 순서 및 스타일
    const carInfoArr: Array<any> = [
        {
            name: "show_yn",
            style: {
                fontSize: 15,
                color: "red",
                top: 0,
                alignSelf: "flex-end",
            },
        },
        { name: "title", style: { fontSize: 17, mt: 1, cursor: "pointer" } },
        { name: "car_number", style: { fontSize: 16, mt: 1 } },
        { name: "danzi", style: multiTextStyle },
        //{name : 'id' , style : { fontSize: 16, mt: 1 } },
        { name: "ym", style: multiTextStyle },
        (group === "99" || group === "22") && {
            name: "dealer",
            style: dealerInfoStyle,
        },
        { name: "add_price", style: multiTextStyle },
        {
            name: "price",
            style: { color: "primary.main", fontSize: 22, mt: 1.5 },
        },
    ];

    //차량 빈 이미지 체크
    const emptyImageDivision = (data: any) => {
        //img1 ~ 20
        const carImageArr = [
            data.img1,
            data.img2,
            data.img3,
            data.img4,
            data.img5,
            data.img6,
            data.img7,
            data.img8,
            data.img9,
            data.img10,
            data.img11,
            data.img12,
            data.img13,
            data.img14,
            data.img15,
            data.img16,
            data.img17,
            data.img18,
            data.img19,
            data.img20,
        ];

        //값이 '' 인 데이터를 필터링 후 0번째 값을 return 해줌
        let img = carImageArr.filter((item) => item !== "");

        if (img[0]) {
            // cgimall 대신 cloudfront 로 바꾸면서 mpark 매물인것과 아닌것 분기 처리(아닌거에는 w=270 파라미터를 붙여야 하기 때문)
            return img[0].includes(REACT_APP_CAR_IMG_DOMAIN_URL)
                ? `${img[0]}&w=270`
                : img[0];
        } else {
            return defaultImage;
        }
    };

    //이미지 onError event
    const onErrorImg = (e: any) => {
        e.target.src = defaultImage;
    };
    //판매가 , 증감액 계산 (추후에 api 수정 예정)
    const carPriceText = (price: string, addPrice: string) => {
        return `판매가: ${price.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
        )}만원 / 증감액: ${addPrice}만원`;
    };

    const carSaleTypeText = (saleType: string) => {
        let saleTypeText = "매매종류 : ";

        if (saleType === "1") {
            return (saleTypeText = `${saleTypeText} 판매`);
        } else if (saleType === "3") {
            return (saleTypeText = `${saleTypeText} 렌트`);
        } else if (saleType === "5") {
            return (saleTypeText = `${saleTypeText} 구독`);
        } else {
            return (saleTypeText = `${saleTypeText} 판매&구독`);
        }
    };

    const carDanziText = (danzi: string, homeServiceYn: string) => {
        let danziText = "";

        if (homeServiceYn === "Y") {
            return (danziText = `매물종류 : ${danzi} 홈서비스 /`);
        } else {
            if (danzi) {
                return (danziText = `매물종류 : ${danzi} 일반매물 /`);
            } else {
                return (danziText = `매물종류 : MEETCHA 일반매물 /`);
            }
        }
    };

    //매물종류 및 유료결제 Text
    const showDealerIdText = (
        optionStr: string,
        danzi: string,
        saleType: string,
        homeServiceYn: string
    ) => {
        let saleTypeText = carSaleTypeText(saleType);
        let danziText = carDanziText(danzi, homeServiceYn);

        if (optionStr) {
            if (optionStr.includes("opendealer2")) {
                return `${danziText} 유료결제 : 딜러노출 / ${saleTypeText}`;
            } else {
                return `${danziText} 유료결제 : 없음 / ${saleTypeText}`;
            }
        } else {
            return `${danziText} 유료결제 : 없음 / ${saleTypeText}`;
        }
    };

    //carInfoArr을 반복문 돌려서 name에 맞는 text 값 리턴
    const carInfoTextItem = (name: string, carInfo: any) => {
        switch (name) {
            case "show_yn":
                return `${carInfo[name] === "N" ? "미노출" : ""}`;
            case "title":
                return `${carInfo[name]}`;
            case "car_number":
                return `차량번호 : ${carInfo.car_number} / 지역 : ${
                    carInfo.siName
                } / 등록일 : ${carInfo.reg_date.split(" ")[0]}`;
            case "dealer":
                return (
                    <>
                        {`딜러명 : ${carInfo.user_name}(${carInfo.id}) / 상사 : ${carInfo.sangho} / 연락처 : `}
                        {carInfo.phone2 && carInfo.phone2 !== "--" ? (
                            <a href={`tel:${carInfo.phone2}`}>
                                {carInfo.phone2}
                            </a>
                        ) : (
                            `없음`
                        )}
                    </>
                );
            case "danzi":
                return showDealerIdText(
                    carInfo.option_str,
                    carInfo.danzi,
                    carInfo.sale_type,
                    carInfo.h_service_yn
                );
            case "ym":
                return `연식: ${carInfo.ym} / 주행거리: ${carInfo.mileage}km / 연료: ${carInfo.fuel} / 변속기: ${carInfo.atmt}`;
            case "add_price":
                if (carInfo.sale_end === "0") {
                    return carPriceText(carInfo.price, carInfo.add_price);
                } else {
                    return null;
                }
            case "price":
                return `${carInfo.totalPrice}${
                    carInfo.totalPrice === "판매완료" ||
                    carInfo.sale_end === "1"
                        ? ""
                        : "만원"
                }`;
            default:
                return;
        }
    };

    const handleTitle = (name: string) => () => {
        if (name === "title") {
            window.open(
                `https://api.meetcha.co.kr/mccarinfo/carDetail?number=${carInfo.number}`
            );
        }
    };

    return (
        <>
            {/* 이미지  */}
            <img
                src={emptyImageDivision(carInfo)}
                alt={"차량 이미지"}
                style={{ width: 300, height: 225, cursor: "pointer" }}
                onError={onErrorImg}
                onClick={() =>
                    window.open(
                        `https://api.meetcha.co.kr/mccarinfo/carDetail?number=${carInfo.number}`,
                        "_blank"
                    )
                }
            />
            {/* title ~ price 까지 영역  */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "flex-start" },
                    m: 3,
                    minWidth: { md: 350 },
                }}
            >
                {carInfoArr.map((item) => (
                    <Box
                        key={item.name}
                        component="span"
                        sx={
                            item.name === "add_price" &&
                            carInfo.sale_end === "1"
                                ? {}
                                : item.style
                        }
                        onClick={handleTitle(item.name)}
                    >
                        {carInfoTextItem(item.name, carInfo)}
                    </Box>
                ))}
                <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                    {carInfo.youtube_id && (
                        <ButtonGroup
                            variant="contained"
                            color="primary"
                            orientation={"horizontal"}
                        >
                            <Button
                                onClick={() =>
                                    window.open(
                                        `https://www.youtube.com/watch?v=${carInfo.youtube_id}`,
                                        "_blank"
                                    )
                                }
                                style={{ textTransform: "none", fontSize: 14 }}
                                startIcon={
                                    <img
                                        src={youtubeImage}
                                        alt="Youtube 이미지"
                                        width={30}
                                        height={30}
                                    />
                                }
                            >
                                {`링크 이동`}
                            </Button>
                            <Button
                                onClick={handleYoutubeLink(
                                    `https://www.youtube.com/watch?v=${carInfo.youtube_id}`
                                )}
                                style={{ textTransform: "none", fontSize: 14 }}
                            >
                                {`링크 복사`}
                            </Button>
                        </ButtonGroup>
                    )}
                    <ButtonGroup
                        sx={{ ml: carInfo.youtube_id ? 2 : 0 }}
                        variant="contained"
                        color="primary"
                        orientation={"horizontal"}
                    >
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() =>
                                window.open(
                                    `https://api.meetcha.co.kr/mccarinfo/carDetail?number=${carInfo.number}`,
                                    "_blank"
                                )
                            }
                            style={{ textTransform: "none", fontSize: 14 }}
                        >
                            {`차량보기`}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Box>
        </>
    );
};

export default DealerCarItem;
