import React from 'react';
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { EnvPath } from 'util/path/EnvPath';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customAxios } from 'axios/customAxios';
import McDialog from 'carManagement/component/McDialog';
import CarGubunUpsertModal from 'carManagement/carGubun/CarGubunUpsertModal';
import McBackdrop from 'component/McBackdrop';

const CarGubunForm = () => {
    const queryClient = useQueryClient();

    /**
     * fetch get getCarGubunList
     */
    const { data, isLoading, isFetching } = useQuery(
        [`${EnvPath.REACT_APP_API_URL}/meetcha/car/manager/getCarGubunList`],
        () => customAxios.get(`/meetcha/car/manager/getCarGubunList`),
        {
            refetchOnWindowFocus: false,
            staleTime: 10 * 60 * 1000,
            //onSuccess: (data) => {},
            onError: (err) => {
                console.log(err);
            },
        }
    );
    //add && update
    const mutation = useMutation(
        (params: FormData) => customAxios.post(`/meetcha/car/manager/doSaveCarGubun`, params),
        {
            onSuccess: (data) => {
                if (data.data !== 'success') {
                    let resCode = data.data.split('-')[1];
                    alert(`ERROR CODE = [${resCode}]`);
                }
                queryClient.invalidateQueries(`${EnvPath.REACT_APP_API_URL}/meetcha/car/manager/getCarGubunList`);
            },
            onError: (err) => {
                alert(err);
            },
        }
    );

    /**
     * 확인용 모달
     * isShow - 노출여부
     * desc - 설명
     * type - 'add' | 'update' 수정 추가 여부
     */
    const [dialog, setDialog] = React.useState<{
        isShow: boolean;
        desc: string;
        type: 'add' | 'update' | 'delete' | '';
    }>({
        isShow: false,
        desc: '',
        type: '',
    });

    // 저장폼 노출 여부
    const [isAddRow, setIsAddRow] = React.useState<Boolean>(false);
    const [modalType, setModalType] = React.useState<string>('');
    // 저장할 input 값
    const [carGubunInput, setCarGubunInput] = React.useState<{ code: string; name: string }>({
        code: '',
        name: '',
    });

    /* 삭제시 필요한 code 값 */
    const [removeNumber, setRemoveNumber] = React.useState<number | any>(0);

    /**
     * input에 입력한 값 set 함수
     * @param e
     */
    const handleInput = (e: any) => {
        setCarGubunInput({
            ...carGubunInput,
            [e.target.name]: e.target.value,
        });
    };

    /**
     * update && add 시 사용 함수
     * dialog.type 으로 add , update 구분
     * (type - add) : push -> sort -> 가공 -> api 호출
     * (type - update) : filter -> sort -> 가공 -> api 호출
     */
    const handleUpsertData = () => {
        let carGubunData = [...data!.data.result];

        if (dialog.type === 'add') {
            //배열에 데이터 삽입
            carGubunData.push({
                conf_code: carGubunInput.code,
                conf_name: carGubunInput.name,
            });
        } else if (dialog.type === 'update') {
            if (carGubunData.some((item: any) => item.conf_code === carGubunInput.code)) {
                carGubunData.forEach((item) => {
                    if (item.conf_code === carGubunInput.code) {
                        item.conf_name = carGubunInput.name;
                    }
                });
            }
        } else {
            carGubunData = carGubunData.filter((item) => item.conf_code !== removeNumber);
        }
        const formData = new FormData();

        //배열 정렬 및 재정의
        carGubunData
            //conf_code 오름차순 정렬
            .sort((a, b) => {
                let numA = Number(a.conf_code);
                let numB = Number(b.conf_code);
                return numA - numB;
            })
            .forEach((item) => {
                //return `${item.conf_code}:${item.conf_name}`
                formData.append(`carGubunList[]`, `${item.conf_code}:${item.conf_name}`);
            });

        setDialog({
            isShow: false,
            desc: '',
            type: '',
        });

        mutation.mutate(formData);
        handleCancelData();
    };

    /**
     * 추가폼 취소버튼 클릭 이벤트
     */
    const handleCancelData = () => {
        setIsAddRow(false);
        setCarGubunInput({
            code: '',
            name: '',
        });
    };

    /**
     * 데이터 추가/삭제 확인용 모달 노출 이벤트
     * @param type
     */
    const handleIsDialog = (type: 'add' | 'update' | 'delete') => {
        const typeText = () => {
            switch (type) {
                case 'add':
                    return '추가';
                case 'update':
                    return '수정';
                case 'delete':
                    return '삭제';
                default:
                    return '';
            }
        };
        setDialog({
            isShow: true,
            desc: `차종을 ${typeText()}하시겠습니까 ?`,
            type: type,
        });
    };

    /**
     * 데이터 추가/삭제 확인용 모달 초기화 이벤트
     */
    const handleClearDialog = () => {
        setDialog({
            isShow: false,
            desc: '',
            type: '',
        });
    };

    /**
     * 차종 title 클릭이벤트
     * @param item
     */
    const handleCarGubunTitle = (item: any) => {
        setCarGubunInput({
            code: item.conf_code,
            name: item.conf_name,
        });
        setModalType('update');
        setIsAddRow(true);
    };

    /**
     * 삭제 아이콘 클릭이벤트
     * @param code
     */
    const handleRemoveIcon = (code: any) => {
        setRemoveNumber(code);
        handleIsDialog('delete');
    };

    return (
        <div>
            <Box sx={{ maxWidth: 500 }}>
                <Typography variant="h4">차종별 구분설정</Typography>
            </Box>
            <Box sx={{ width: '100%', mt: 2 }}>
                <Box sx={{ maxWidth: 500 }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ width: 160, backgroundColor: '#f3f3f3' }}>
                                        번호
                                    </TableCell>
                                    <TableCell align="center" sx={{ width: 300 }}>
                                        차종
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data &&
                                    data?.data.result.map((item: any) => {
                                        return (
                                            <TableRow key={item.conf_code}>
                                                <TableCell
                                                    align="center"
                                                    component="th"
                                                    sx={{ backgroundColor: '#f3f3f3' }}
                                                >
                                                    {item.conf_code}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                    align="center"
                                                >
                                                    <Typography
                                                        sx={{
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                            fontWeight: 'bold',
                                                            ml: 4,
                                                        }}
                                                        onClick={() => handleCarGubunTitle(item)}
                                                    >
                                                        {item.conf_name}
                                                    </Typography>
                                                    <IconButton onClick={() => handleRemoveIcon(item.conf_code)}>
                                                        <RemoveCircleOutlineIcon color="error" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        <IconButton
                                            onClick={() => {
                                                setModalType('add');
                                                setIsAddRow(true);
                                            }}
                                        >
                                            <AddCircleOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {dialog.isShow && (
                    <McDialog
                        title="알림"
                        description={dialog.desc}
                        handleClose={handleClearDialog}
                        handleConfirm={handleUpsertData}
                    />
                )}
                {isAddRow && (
                    <CarGubunUpsertModal
                        carGubunInput={carGubunInput}
                        type={modalType}
                        handleInput={handleInput}
                        handleConfirm={handleIsDialog}
                        handleCanCel={handleCancelData}
                    />
                )}
                {Boolean(mutation.isLoading || isLoading || isFetching) && <McBackdrop />}
            </Box>
        </div>
    );
};

export default CarGubunForm;
