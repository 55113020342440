import React from 'react';
import { Grid, Typography } from '@mui/material';
import CarCategoryBrandForm from '../carCategory/CarBrandCategoryForm';
import CarCategoryForm from 'carManagement/carCategory/CarCategoryForm';
import CarGubunModal from 'carManagement/carCategory/CarGubunModal';
import { useQuery } from 'react-query';
import { EnvPath } from 'util/path/EnvPath';
import { customAxios } from 'axios/customAxios';

const CarCategory = () => {
    const [categoryDeps, setCategoryDeps] = React.useState({
        brandNum: '',
        brandName: '',
        modelNum: '',
        modelName: '',
        detailModelNum: '',
        detailModelName: '',
        gradeNum: '',
        gradeName: '',
        detailGradeNum: '',
        detailGradeName: '',
    });
    /* 
		카테고리 단계별 추가옵션
		2단계 - 차종(carGubunCode , carGubunValue)
		3단계 - 출시일(lanchDate)
		4단계 - 최저가(minPrice)
	*/
    const [depsByOption, setDepsByOption] = React.useState({
        carGubunCode: '',
        carGubunName: '',
        modelLaunchDate: '',
        detailModelLaunchDate: '',
        minPrice: '0',
    });

    /* 카테고리 2 ~ 5 중복 코드 생략을 위해 추가 */
    const categoryMap = [
        {
            info: { depsName: 'model', depsNumber: '2', parentNumber: categoryDeps.brandNum },
            category: { number: categoryDeps.modelNum, name: categoryDeps.modelName },
        },
        {
            info: { depsName: 'detailModel', depsNumber: '3', parentNumber: categoryDeps.modelNum },
            category: { number: categoryDeps.detailModelNum, name: categoryDeps.detailModelName },
        },
        {
            info: { depsName: 'grade', depsNumber: '4', parentNumber: categoryDeps.detailModelNum },
            category: { number: categoryDeps.gradeNum, name: categoryDeps.gradeName },
        },
        {
            info: { depsName: 'detailGrade', depsNumber: '5', parentNumber: categoryDeps.gradeNum },
            category: { number: categoryDeps.detailGradeNum, name: categoryDeps.detailGradeName },
        },
    ];

    const [isCarGubunModal, setIsCarGubunModal] = React.useState<Boolean>(false);

    //차종 데이터 api 호출
    const { data } = useQuery(
        [`${EnvPath.REACT_APP_API_URL}/meetcha/car/manager/getCarGubunList`],
        () => customAxios.get(`/meetcha/car/manager/getCarGubunList`),
        {
            refetchOnWindowFocus: false,
            staleTime: 10 * 60 * 1000,
            onError: (err) => {
                console.log(err);
            },
        }
    );

    /**
     * 카테고리 단계별 옵션 추가 함수
     * @param name
     * @returns
     */
    const handleDepsByOption = (name: string) => (e: any) => {
        setDepsByOption({
            ...depsByOption,
            [name]: e.target.value,
        });
    };

    /**
     * 카테고리 선택시 단계별로 필요한 옵션 추가 함수
     * @param depsNumber
     * @param item
     */
    const handleSelectDepsByOption = (depsNumber: string, item: any) => {
        let launchDate: any;

        if (item.launchDate) {
            const isLaunchDt = new Date(item?.launchDate).toString() !== 'Invalid Date';
            if (isLaunchDt) {
                let convertKor = new Date(item.launchDate).getTime() + 9 * 60 * 60000;
                launchDate = new Date(convertKor).toISOString().split('T')[0];
            }
        } else {
            launchDate = '';
        }

        switch (depsNumber) {
            case '2': {
                const result = [...data?.data.result];
                let carGubunName = '';
                if (item.carGubun) {
                    carGubunName = result.filter((cgItem) => cgItem.conf_code === item.carGubun)[0].conf_name;
                }

                setDepsByOption({
                    ...depsByOption,
                    carGubunCode: item.carGubun,
                    carGubunName: carGubunName,
                    modelLaunchDate: launchDate,
                });
                break;
            }
            case '3':
                setDepsByOption({
                    ...depsByOption,
                    detailModelLaunchDate: launchDate,
                });
                break;
            case '4':
                setDepsByOption({
                    ...depsByOption,
                    minPrice: item.minPrice,
                });
                break;
            default:
                break;
        }
    };

    /**
     * 차종선택모달에서 사용하는 차종버튼 클릭이벤트
     * @param code
     * @param name
     * @returns
     */
    const handleCarGubunValue = (code: string, name: string) => () => {
        setDepsByOption({
            ...depsByOption,
            carGubunCode: code,
            carGubunName: name,
        });
        setIsCarGubunModal(false);
    };

    /**
     * 단계별로 선택된 카테고리 (number ,name)
     * @param name
     * @param number
     * @param categoryName
     */
    const handleCategory = (name: string, number: string, categoryName: string) => {
        setCategoryDeps({
            ...categoryDeps,
            [`${name}Num`]: number,
            [`${name}Name`]: categoryName,
        });
    };

    /**
     * 단계별 Input 창 입력 함수
     * @param name
     * @returns
     */
    const handleCategoryInput = (name: string) => (e: any) => {
        setCategoryDeps({
            ...categoryDeps,
            [`${name}Name`]: e.target.value,
        });
    };

    const removeCategoryData = (deps: string) => {
        if (deps === '1') {
            setCategoryDeps({
                brandName: '',
                brandNum: '',
                modelNum: '',
                modelName: '',
                detailModelNum: '',
                detailModelName: '',
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                carGubunCode: '',
                carGubunName: '',
                modelLaunchDate: '',
                detailModelLaunchDate: '',
                minPrice: '0',
            });
        } else if (deps === '2') {
            setCategoryDeps({
                ...categoryDeps,
                modelNum: '',
                modelName: '',
                detailModelNum: '',
                detailModelName: '',
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                ...depsByOption,
                carGubunCode: '',
                carGubunName: '',
                modelLaunchDate: '',
                detailModelLaunchDate: '',
                minPrice: '0',
            });
        } else if (deps === '3') {
            setCategoryDeps({
                ...categoryDeps,
                detailModelNum: '',
                detailModelName: '',
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                ...depsByOption,
                detailModelLaunchDate: '',
                minPrice: '0',
            });
        } else if (deps === '4') {
            setCategoryDeps({
                ...categoryDeps,
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                ...depsByOption,
                minPrice: '0',
            });
        } else {
            setCategoryDeps({
                ...categoryDeps,
                detailGradeNum: '',
                detailGradeName: '',
            });
        }
    };

    const resetCategoryData = (deps: string) => {
        if (deps === '1') {
            setCategoryDeps({
                ...categoryDeps,
                modelNum: '',
                modelName: '',
                detailModelNum: '',
                detailModelName: '',
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                carGubunCode: '',
                carGubunName: '',
                modelLaunchDate: '',
                detailModelLaunchDate: '',
                minPrice: '0',
            });
        } else if (deps === '2') {
            setCategoryDeps({
                ...categoryDeps,
                detailModelNum: '',
                detailModelName: '',
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                ...depsByOption,
                detailModelLaunchDate: '',
                minPrice: '0',
            });
        } else if (deps === '3') {
            setCategoryDeps({
                ...categoryDeps,
                gradeNum: '',
                gradeName: '',
                detailGradeNum: '',
                detailGradeName: '',
            });
            setDepsByOption({
                ...depsByOption,
                minPrice: '0',
            });
        } else {
            setCategoryDeps({
                ...categoryDeps,
                detailGradeNum: '',
                detailGradeName: '',
            });
        }
    };

    // const removeCategoryData1 = (num: string) => {
    //     let copyCategoryDeps: any = { ...categoryDeps };
    //     let copyDepsByOption: any = { ...depsByOption };
    //     let categoryKey:Array<string> = ['brandName',
    // 	'brandNum',
    // 	'modelNum',
    // 	'modelName',
    // 	'detailModelNum',
    // 	'detailModelName',
    // 	'gradeNum',
    // 	'gradeName',
    // 	'detailGradeNum',
    // 	'detailGradeName',];
    //     let optionKey:Array<string> = ['carGubunCode', 'carGubunName', 'modelLaunchDate', 'detailModelLaunchDate', 'minPrice'];

    //     if (num === '2') {
    //         categoryKey = [
    //             'modelNum',
    //             'modelName',
    //             'detailModelNum',
    //             'detailModelName',
    //             'gradeNum',
    //             'gradeName',
    //             'detailGradeNum',
    //             'detailGradeName',
    //         ];
    //         optionKey = ['carGubunCode', 'carGubunName', 'modelLaunchDate', 'detailModelLaunchDate', 'minPrice'];
    //     } else if (num === '3') {
    //         categoryKey = ['detailModelNum', 'detailModelName', 'gradeNum', 'gradeName', 'detailGradeNum', 'detailGradeName'];
    //         optionKey = ['detailModelLaunchDate', 'minPrice'];
    //     } else if (num === '4') {
    //         categoryKey = ['gradeNum', 'gradeName', 'detailGradeNum', 'detailGradeName'];
    //         optionKey = ['minPrice'];
    //     } else {
    //         categoryKey = ['detailGradeNum', 'detailGradeName'];
    //     }

    // 	if(num !== '5'){
    // 		categoryKey.forEach((key) => (copyCategoryDeps[key] = ''));
    // 		optionKey.forEach((key) => (key === 'minPrice' ? (copyCategoryDeps[key] = '0') : (copyDepsByOption[key] = '')));
    // 	}else {
    // 		categoryKey.forEach((key) => (copyCategoryDeps[key] = ''));
    // 	}

    //     console.log(categoryKey);
    //     console.log(optionKey);
    // };

    React.useEffect(() => {
        if (categoryDeps.brandNum) {
            resetCategoryData('1');
        }
    }, [categoryDeps.brandNum]);

    React.useEffect(() => {
        if (categoryDeps.modelNum) {
            resetCategoryData('2');
        }
    }, [categoryDeps.modelNum]);

    React.useEffect(() => {
        if (categoryDeps.detailModelNum) {
            resetCategoryData('3');
        }
    }, [categoryDeps.detailModelNum]);

    React.useEffect(() => {
        if (categoryDeps.gradeNum) {
            resetCategoryData('4');
        }
    }, [categoryDeps.gradeNum]);

    return (
        <div>
            {/* <Box>
                    <Typography variant="h4">차량 설정</Typography>
                </Box> */}
            <Grid container columns={{ xs: 5, sm: 10, md: 15 }} spacing={{ xs: 2, sm: 1, md: 5 }}>
                <Grid item xs={5} sm={5} md={3}>
                    {/* Category 1 */}
                    <CarCategoryBrandForm
                        brand={{ number: categoryDeps.brandNum, name: categoryDeps.brandName }}
                        handleCategory={handleCategory}
                        handleCategoryInput={handleCategoryInput}
                        removeCategoryData={removeCategoryData}
                    />
                </Grid>
                {/* Category 2 ~ 5 */}
                {categoryMap.map((item) => (
                    <Grid item xs={5} sm={5} md={3} key={item.info.depsName}>
                        <CarCategoryForm
                            info={item.info}
                            category={item.category}
                            depsByOption={depsByOption}
                            handleSelectDepsByOption={handleSelectDepsByOption}
                            handleDepsByOption={handleDepsByOption}
                            handleCategory={handleCategory}
                            handleCategoryInput={handleCategoryInput}
                            handleCargubunModal={() => setIsCarGubunModal(true)}
                            removeCategoryData={removeCategoryData}
                        />
                    </Grid>
                ))}
            </Grid>
            {isCarGubunModal && data?.data.result && (
                <CarGubunModal
                    data={data?.data.result}
                    onClose={() => setIsCarGubunModal(false)}
                    handleCarGubunValue={handleCarGubunValue}
                />
            )}
        </div>
    );
};

export default CarCategory;
