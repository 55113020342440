
/* sessionStorage 저장 */
export const setStorageItem = (key:string , value:string) => {
	sessionStorage.setItem(key,value);
}

/* sessionStorage에 저장된 값 return */
export const getStorageItem = (key:string , str?:string) => {
	if(sessionStorage.getItem(key)){
		return sessionStorage.getItem(key);
	}else {
		return ''
	}
}

/* sessionStorage에 저장된 모든 값 return */
export const getAllStorageItem = () => {
	let key = ['id','token','group'];
	let resValue:any = {
		id : '',
		token : '',
		group : ''
	};

	key.forEach(key => 
		{
			if(sessionStorage.getItem(key)){
				resValue[key] = sessionStorage.getItem(key);
			}
		}
	);

	return resValue;
}

/* sessionStorage 키 값 삭제 */
export const removeStorageItem = (key:string) => {
	sessionStorage.removeItem(key);
}

/* sessionStorage에 저장된 모든 key ,value 삭제 */
export const clearStorage = () => {
	sessionStorage.clear();
}

export const getMultipleStorageItem = (keyArr : Array<string>) => {
	let storageData:Map<string, string> ;
	keyArr.forEach(key => {
		if(sessionStorage.getItem(key)){
			storageData.set(key , sessionStorage.getItem(key)!);
		}
	})
	
	if(storageData!.size){
		return storageData! ;
	}else {
		return null ;
	}
}

export const setMultipleStorageItem = (keyValueArr : Array<{key : string , value : string}>) => {
	keyValueArr.forEach(item => {
		sessionStorage.setItem(item.key , item.value);
	});
}

