import React, { useState } from 'react';
import Title from 'home/Title';
import {
    Box,
    Button,
    Paper,
    Stack,
    Pagination,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControl,
    TextField,
    Typography,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { ManagerInitWrap } from '../styled/ManagerStyled';
import { customAxios, testAxios } from 'axios/customAxios';
import AddSubManagerModal from './AddSubManagerModal';
import ModifySubManagerModal from './ModifySubManagerModal';

interface SubManagerListProps {}

interface SubManagerState {
    pageNo: number;
    pageSize: number;
    searchType: 'id' | 'name';
    searchKeyword: string;
}

interface SubManagerSelect {
    label: string;
    value: string;
}

export interface SubManagerInfo {
    number: number;
    id: string;
    pass: string;
    name: string;
    phone: string;
    date: number;
    lastlogin: number;
    loginCount: number;
    menu_sum: number;
    menu1: string;
    menu2: string;
    menu3: string;
    menu4: string;
    menu5: string;
    menu6: string;
    menu7: string;
    menu8: string;
    menu9: string;
    menu10: string;
    menu11: string;
    menu12: string;
    menu13: string;
    menu14: string;
    menu15: string;
}

const PAGESIZE: number = 10;

const subMangerSelect: SubManagerSelect[] = [
    { label: '아이디', value: 'id' },
    { label: '이름', value: 'name' },
];

const convertTimestampToDateTime = (timeString: any) => {
    let lTimestamp;
    if (typeof timeString === 'string') {
        lTimestamp = new Date(Number(timeString));
    } else {
        lTimestamp = new Date(timeString);
    }
    lTimestamp.setHours(lTimestamp.getHours() + lTimestamp.getTimezoneOffset() / 60);
    let lMonth = lTimestamp.getMonth() + 1;
    let lDate = lTimestamp.getDate();
    let lHour = lTimestamp.getHours();
    let lMin = lTimestamp.getMinutes();
    let returnVal = [
        lTimestamp.getFullYear(),
        lMonth.toString().padStart(2, '0'),
        lDate.toString().padStart(2, '0'),
    ].join('-');
    returnVal = returnVal + ' ' + lHour.toString().padStart(2, '0') + ':' + lMin.toString().padStart(2, '0');
    return returnVal;
};

const confirmAlert = (message: string, onAlertConfirm: any, onAlertCancel: any) => {
    if (!message || message === '') return;
    if (!onAlertConfirm || typeof onAlertConfirm !== 'function') return;
    if (!onAlertCancel || typeof onAlertCancel !== 'function') return;
    const confirmAction = () => {
        if (window.confirm(message)) {
            onAlertConfirm();
        } else {
            onAlertCancel();
        }
    };
    confirmAction();
};

const SubManagerList = ({}: SubManagerListProps) => {
    const [page, setPage] = useState<number>(1);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const [subManagerInfo, setSubManager] = useState<SubManagerInfo>();
    const [subManagerList, setSubManagerList] = useState<Array<SubManagerInfo>>();
    const [addSubManagerModalOpen, setAddSubManagerModalOpen] = useState<boolean>(false);
    const [modifySubManagerModalOpen, setModifySubManagerModalOpen] = useState<boolean>(false);
    const [subManagerListTotalCnt, setSubManagerListTotalCnt] = useState<number>(0);
    const [subManagerState, setSubManagerState] = useState<SubManagerState>({
        pageNo: 1,
        pageSize: PAGESIZE,
        searchType: 'id',
        searchKeyword: '',
    });
    const { pageNo, pageSize, searchType, searchKeyword } = subManagerState;

    React.useEffect(() => {
        fetchSubManagerList();
    }, [page]);

    React.useEffect(() => {
        if (isRefresh) {
            fetchSubManagerList();
            setIsRefresh(false);
        }
    }, [isRefresh]);

    const handleSubManagerStateOnChange = (e: any, type: string) => {
        setSubManagerState({
            ...subManagerState,
            [type]: e.target.value,
        });
    };

    const fetchSubManagerList = async () => {
        const config = { params: { ...subManagerState, pageNo: page } };
        try {
            const res = await customAxios.get('/meetcha/manager/user/admin/member/list', config);
            if (res.status === 200 && res.data) {
                setSubManagerList(res.data.data);
                setSubManagerListTotalCnt(res.data.apiinfo.totalcnt);
            }
        } catch (error) {
            alert('네트워크 에러가 발생하였습니다.');
        }
    };

    const pagingCount = () => {
        if (subManagerListTotalCnt % pageSize > 0) return Math.floor(subManagerListTotalCnt / pageSize) + 1;
        else return Math.floor(subManagerListTotalCnt / pageSize);
    };

    const handleSubManagerStateReset = () => {
        setSubManagerState({
            ...subManagerState,
            pageNo: 1,
            pageSize: PAGESIZE,
            searchType: 'id',
            searchKeyword: '',
        });
        setPage(1);
        setIsRefresh(true);
    };

    const handleSearch = () => {
        if (searchKeyword.trim() !== '' && searchKeyword.trim().length < 2) {
            alert('검색어는 2글자 이상 입력 하세요.');
            return;
        }
        if (page > 1) setPage(1);
        else fetchSubManagerList();
    };

    const maintainPageRefresh = () => setIsRefresh(true);

    const openModifySubManagerModal = (info: SubManagerInfo) => {
        setSubManager(info);
        setModifySubManagerModalOpen(true);
    };

    const deleteSubManager = (info: SubManagerInfo) => {
        const check = async () => {
            const config = { headers: { 'Content-Type': 'application/json' } };
            const jsonData = JSON.stringify({ ...info, process: 'DELETE' });
            try {
                const res = await customAxios.post('/meetcha/manager/user/modify/admin/member', jsonData, config);
                if (res.status === 200 && res.data === 'success') {
                    maintainPageRefresh();
                    alert('부관리자 삭제 성공');
                } else {
                    alert('부관리자 삭제 실패');
                }
            } catch (error) {
                alert('부관리자 삭제 실패');
            }
        };
        const cancel = () => {};
        confirmAlert(`${info.name} 부관리자를 삭제하시겠습니까?`, check, cancel);
    };

    return (
        <ManagerInitWrap>
            <Title>부관리자 설정</Title>
            <Box sx={{ mb: 2, mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <FormControl sx={{ m: 1, ml: 0, minWidth: 150 }} size="small">
                        <InputLabel id="demo-select-small">검색조건</InputLabel>
                        <Select
                            id="demo-select-small"
                            label="검색조건"
                            labelId="demo-select-small"
                            value={searchType}
                            onChange={(e: any) => handleSubManagerStateOnChange(e, 'searchType')}
                        >
                            {subMangerSelect.map((item: SubManagerSelect) => (
                                <MenuItem key={`${item.value}-${item.label}`} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }} size="small">
                        <TextField
                            id="outlined-size-small"
                            size="small"
                            label={searchType === 'id' ? '아이디로 검색' : '이름으로 검색'}
                            sx={{ width: 200 }}
                            value={searchKeyword}
                            onChange={(e: any) => handleSubManagerStateOnChange(e, 'searchKeyword')}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button variant="contained" style={{ height: 40 }} color="info" onClick={handleSearch}>
                            검색
                        </Button>
                    </FormControl>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button
                            variant="contained"
                            style={{ height: 40 }}
                            color="error"
                            onClick={handleSubManagerStateReset}
                        >
                            초기화
                        </Button>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl sx={{ m: 1, ml: 0 }}>
                        <Button
                            variant="contained"
                            style={{ height: 40, minWidth: 90 }}
                            color="success"
                            onClick={() => setAddSubManagerModalOpen(true)}
                        >
                            부관리자등록
                        </Button>
                    </FormControl>
                </Box>
            </Box>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{`등록부관리자 총 6 명`}</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>번호</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>이름</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>아이디</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>연락처</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>가입일</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>접속수</TableCell>
                            <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>관리</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subManagerList &&
                            subManagerList.map((info: SubManagerInfo, index: number) => (
                                <TableRow key={info.number}>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {subManagerListTotalCnt - (page - 1) * PAGESIZE - index}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', backgroundColor: '#F8FDF0' }}>
                                        {info.name}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{info.id}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{info.phone}</TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {convertTimestampToDateTime(info.date).slice(0, -6)}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center' }}>{info.loginCount}</TableCell>
                                    <TableCell style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            size="small"
                                            style={{ margin: '0 5px' }}
                                            onClick={() => openModifySubManagerModal(info)}
                                        >
                                            수정
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            style={{ margin: '0 5px' }}
                                            onClick={() => deleteSubManager(info)}
                                        >
                                            삭제
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={2} style={{ alignItems: 'center', padding: 30 }}>
                <Pagination
                    count={pagingCount()}
                    defaultPage={1}
                    page={page}
                    onChange={(e: React.ChangeEvent<unknown>, page: number) => {
                        setPage(page);
                    }}
                    color="secondary"
                    variant="outlined"
                    showFirstButton
                    showLastButton
                />
            </Stack>
            {addSubManagerModalOpen && (
                <AddSubManagerModal
                    openModal={addSubManagerModalOpen}
                    setOpenModal={setAddSubManagerModalOpen}
                    reset={handleSubManagerStateReset}
                />
            )}
            {modifySubManagerModalOpen && (
                <ModifySubManagerModal
                    info={subManagerInfo}
                    openModal={modifySubManagerModalOpen}
                    setOpenModal={setModifySubManagerModalOpen}
                    refresh={maintainPageRefresh}
                />
            )}
        </ManagerInitWrap>
    );
};

export default SubManagerList;
