import { Box ,FormControl ,InputLabel ,Select ,MenuItem, TextField, Button, SelectChangeEvent} from '@mui/material';
import * as React from 'react'

interface UserListMenuProps {
	searchParams : any,
	onChange : (name:string) => (e:any) => void ,
	handleButton : (name:string) => void ,
}
const UserListMenu = ({searchParams , onChange , handleButton}:UserListMenuProps) => {
  	/* 그룹 */
	const groupData = [
		{label : '전체' , value : 'a'},
		{label : '차량딜러' , value : '8'},
		{label : '차량매입딜러' , value : '21'},
		{label : '카스타일러' , value : '20'},
		{label : '일반회원' , value : '7'},
	];

	/* 로그인상태 */
	const loginStateData = [
		{label : '전체' , value : 'a'},
		{label : '승인 대기' , value : 'N'},
		{label : '승인' , value : 'Y'}
	];

	/* 검색조건 */
	const searchKeywordData = [
		{label : '이름' , value : 'userName'},
		{label : '아이디' , value : 'userId'},
		{label : '상사명' , value : 'comName'}
	];

	return (
		<Box sx={{mb : 2}}>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">회원구분</InputLabel>
				<Select
					labelId="demo-select-small"
					id="demo-select-small"
					value={ searchParams.group }
					label="회원구분"
					onChange={onChange('group')}
				>
					{
						groupData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">로그인상태</InputLabel>
				<Select
					labelId="demo-select-small"
					id="demo-select-small"
					value={ searchParams.loginState }
					label="로그인상태"
					onChange={onChange('loginState')}
				>
					{
						loginStateData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">검색옵션</InputLabel>
				<Select
					labelId="demo-select-small"
					id="demo-select-small"
					value={ searchParams.searchOption}
					label="검색옵션"
					onChange={onChange('searchOption')}
				>
					{
						searchKeywordData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}} size="small" >
				 <TextField
					label="검색어"
					id="outlined-size-small"
					onChange={onChange('searchText')}
					value={searchParams.searchText}
					size="small"
					onKeyDown={e => {
						if(e.key === 'Enter'){
							handleButton('search');
						}
					}}
				/>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}}  >
				<Button variant='contained' style={{height : 40}} color='info' onClick={() => handleButton('search')}>
					검색
				</Button>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}}  >
				<Button variant='contained' style={{height : 40}} color='error' onClick={() => handleButton('refresh')}>
					초기화
				</Button>
			</FormControl>
		</Box>
	)
}
export default UserListMenu