import * as React from 'react';

import axios from 'axios';
import CategorySelectItem from './CategorySelectItem';
import { customAxios } from 'axios/customAxios';
import { EnvPath } from 'util/path/EnvPath';

interface CarCategorySelectProps {
	brand : Array<any>,
	modelList : Array<any>,
	value: any,
	onChange : (name:string) => (e:any) => void,
	onClick : (name:string) => (e:any) => void ,

} 

/* 제조사 ~ 세부등급까지 SelectItem를 컴포넌트화 및 api 호출 */
const CarCategorySelect = ({brand ,modelList,value,onChange,onClick}:CarCategorySelectProps) => {

	const {REACT_APP_API_URL} = EnvPath;


	const [categoryModelList , setCategoryModelList] = React.useState({
		categoryList3 : [],
		categoryList4 : [],
		categoryList5 : [],
	})

	const brandModelData = () => {
		if(value.category1){
			return modelList.filter(i => i.category2_parent_number === value.category1);
		}else {
			return [];
		}
	}

	const modelDetailData = (name:string) => {

		let emptyArr:Array<any> = []
		
		if(name === 'category4'){
			if(value.category3){
				return categoryModelList.categoryList4.filter((i:any) => i.category_parent_number === value.category3);
			}else {
				return emptyArr;
			}
		}else if(name === 'category5'){
			if(value.category4){
				return categoryModelList.categoryList5.filter((i:any) => i.category_parent_number === value.category4);
			}else {
				return emptyArr;
			}
		}else {
			return emptyArr;
		}
	}

	const fetchBrandDetailModal = async () => {
		
		try {
			//const response = await axios.get(`${REACT_APP_API_URL}/meetcha/car/manager/carModelDetailMenu?pModel=${value.category2}`);
			const response = await customAxios.get(`/meetcha/car/v2/carModelDetailMenu?pModel=${value.category2}`);
			if(response.status === 200 && response.data){

				setCategoryModelList({
					...categoryModelList,
					categoryList3 : response.data.model_list.category3_list,
					categoryList4 : response.data.model_list.category4_list,
					categoryList5 : response.data.model_list.category5_list ? response.data.model_list.category5_list : []
				})
			}
		} catch (error) {
			
		}
	}

	React.useEffect(() => {
		if(value.category2){
			fetchBrandDetailModal();
		}
	}, [value.category2])

	return (
		<>
			{
				<CategorySelectItem
					title={'제조사'} 
					id={'category1'} 
					value={value.category1} 
					label={'제조사'} 
					onChange={onChange('category1')} 
					onClick={onClick('categoryName1')}
					data={brand}
				/>
			}
			{
				<CategorySelectItem
					title={'모델'} 
					id={'category2'} 
					value={value.category2} 
					label={'모델'} 
					onChange={onChange('category2')}
					onClick={onClick('categoryName2')}
					data={brandModelData()}
					disabled={value.category1 ? false : true}
				/>
			}
			{
				<CategorySelectItem
					title={'세부모델'} 
					id={'category3'} 
					value={value.category3} 
					label={'세부모델'} 
					onChange={onChange('category3')} 
					onClick={onClick('categoryName3')}
					data={categoryModelList.categoryList3}
					disabled={value.category2 ? false : true}
				/>
			}
			{
				<CategorySelectItem
					title={'등급'} 
					id={'category4'} 
					value={value.category4} 
					label={'등급'} 
					onChange={onChange('category4')} 
					onClick={onClick('categoryName4')}
					data={modelDetailData('category4')}
					disabled={value.category3 ? false : true}
				/>
			}
			{
				<CategorySelectItem
					title={'세부등급'} 
					id={'category5'} 
					value={value.category5} 
					label={'세부등급'} 
					onChange={onChange('category5')} 
					onClick={onClick('categoryName5')}
					data={modelDetailData('category5')}
					disabled={value.category4 ? false : true}
				/>
			}
		</>
	)
}

export default CarCategorySelect;