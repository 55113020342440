import { Box, Button, Paper, Stack, Pagination, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress, SelectChangeEvent, Link, FormControl, InputLabel, Select, MenuItem, TextField, Typography} from '@mui/material'
import {useNavigate , useLocation } from "react-router-dom";
import { customAxios } from 'axios/customAxios'
import McDialog from 'carManagement/component/McDialog';
import * as React from 'react'

import { SmsManagerInfo } from 'interface/SmsManagerInfo';

interface formParams {
    returnParam: any
}

const ManagerList = () => {

    const [managerList, setManagerList] = React.useState<Array<SmsManagerInfo>>([]);
	const navigate = useNavigate();
	const location = useLocation();

    // 초기화 버튼 관리용 변수
	const [isRefresh, setIsRefresh] = React.useState<boolean>(false);

    const [isLoading , setIsLoading] = React.useState<boolean>(false);

	const [totalCnt, setTotalCnt] = React.useState(0);

	// 페이징에 사용되는 state 변수
	const [page , setPage] = React.useState<number>(0);
	const pageSize = 10;

    const defaultImage = require('res/images/defaultImage.jpg');
	
	const onErrorImg = (e:any) => {
		e.target.src = defaultImage;
	}

    // 검색 메뉴 옵션 state
	const [searchParams , setSearchParams]  = React.useState<any>({
		searchTxt : '',
		searchSendGroup : 'a',
		searchGrade : 'a',
        searchUseYn : 'a',
        searchDelYn : '',
		searchPage : 1,
		isSearch : false
	});

	// 검색 메뉴 옵션 Data
	const sendGroupData = [
		{label : '전체' , value : 'a'},
		{label : '매입상담' , value : '01'},
		{label : '알림' , value : '99'}
	];

	const useYnData = [
		{label : '전체' , value : 'a'},
		{label : '수신' , value : 'Y'},
		{label : '미수신' , value : 'N'}
	];

	const gradeData = [
		{label : '전체' , value : 'a'},
		{label : '일반' , value : '01'},
		{label : '관리' , value : '99'}
	];

    // function
    const fetchDataList = async () => {
        const config:any = {
			params : {
                searchTxt : searchParams.searchTxt,
				searchSendGroup : searchParams.searchSendGroup === 'a' ? '' : searchParams.searchSendGroup,
                searchGrade : searchParams.searchGrade === 'a' ? '' : searchParams.searchGrade,
                searchUseYn : searchParams.searchUseYn === 'a' ? '' : searchParams.searchUseYn,
                searchDelYn : searchParams.searchDelYn,
				pageNo : page,
				pageSize : pageSize
			}
		}

		// console.log("fetchDataList search Params:" + JSON.stringify(config));

        try {
			const response = await customAxios.get(`/meetcha/sellcar/manager/list`,config);
			if(response.status === 200 && response.data.data){
				setTotalCnt(response.data.apiinfo.totalcnt);
				setManagerList(response.data.data);
			}
		} catch (error) {
			console.log(error);
		}
    }

    // carList 호출 관련 함수 , page 값에 따라 다르게 처리
	const pageCheck = () => {
		if(page === 1){
			fetchDataList();
		}else {
			setPage(1);
		}
	}

    // 페이징 계산 함수
	const pagingCount = () => {
		if((totalCnt % pageSize) > 0){
			return Math.floor(totalCnt / pageSize) + 1
		}else {
			return Math.floor(totalCnt/ pageSize) 
		}
	}

    // Pagination
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {		
		setPage(value);
	};
    
	// 로딩시 사용되는 함수
	const handleIsLoading = (state:boolean) =>{
		if(state){
			setIsLoading(true)
		}else {
			fetchDataList();
			
			setTimeout(() => {
				//alert('로그인상태 변경이 완료되었습니다.')
				//setIsDialog(false);
				//setIsLoading(false);
			}, 1500);
		}
	}

	const gradeByText = (grade:string) => {
		switch(grade){
			case '01' : return '일반';
			case '99' : return '관리';
			default : return '';
		}
	}

	const useynByText = (grade:string) => {
		switch(grade){
			case 'Y' : return '수신';
			case 'N' : return '미수신';
			default : return '';
		}
	}

	const sendGroupByText = (sendGroup:string) => {
		switch(sendGroup){
			case '01' : return '매입상담';
			default : return '';
		}
	}

	const convertTimestampToDate = (timeString:any) => {

		let lTimestamp;

		if(typeof timeString === 'string'){
			lTimestamp = new Date(Number(timeString));
		} else {
			lTimestamp = new Date(timeString);
		}

		let timeZoneOffset = lTimestamp.getTimezoneOffset() / 60;
		lTimestamp.setHours(lTimestamp.getHours() + timeZoneOffset);

		let lMonth = lTimestamp.getMonth()+1;
		let lDate = lTimestamp.getDate();
		
		let returnVal = [
							lTimestamp.getFullYear(),
							lMonth.toString().padStart(2,'0'), 
							lDate.toString().padStart(2,'0')
						].join("-");

		return returnVal;
	}

	const handleSearch = () => {
		if(searchParams.searchTxt.trim() !== '' && searchParams.searchTxt.trim().length < 2){
			alert('검색어는 2글자 이상 입력 하세요.');
			return;
		} else {

			if(page > 1){
				setPage(1);
			} else {			
				fetchDataList();
			}
		}
	}

	const handleSearchReset = () => {
		setSearchParams({
			...searchParams,
			searchTxt : '',
			searchSendGroup : 'a',
			searchGrade : 'a',
        	searchUseYn : 'a'
		});
		setPage(1);		
		setIsRefresh(true);
	}

    // event
    React.useEffect(() => {
		setSearchParams({
			...searchParams,
			searchPage: page
		})
		fetchDataList();
		// console.log("setPage test!!" + page);
	},[page])

	React.useEffect(() => {
		if(isRefresh){
			fetchDataList();
			setIsRefresh(false);
		}
	},[isRefresh])

	React.useEffect(() => {
		if(location){			
			if(location.state){				
				const { state }:any = location;
				if(state) {
					// console.log("test log:"+JSON.stringify(state));
					setSearchParams({
						...searchParams,
						searchTxt : state.returnParam.searchTxt === null ? '' : state.returnParam.searchTxt,
						searchSendGroup : state.returnParam.searchSendGroup,
						searchGrade : state.returnParam.searchGrade,
						searchUseYn : state.returnParam.searchUseYn,
						searchPage : state.returnParam.searchPage,
						searchDelYn : 'N',
						isSearch : false
					})
					setPage(state.returnParam.searchPage);
				} else {
					setPage(1);
				}
			} else {
				setPage(1);
			}
		} else {
			setPage(1);
		}
	}, [])

    // render
    return (
        <>
			<Box sx={{mb : 2}}>
				<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
					<InputLabel id="demo-select-small">수신그룹</InputLabel>
					<Select
						labelId="demo-select-small"
						id="demo-select-small"
						value={ searchParams.searchSendGroup }
						label="수신그룹"
						onChange={(e:any) => setSearchParams({...searchParams, searchSendGroup : e.target.value})}
					>
						{
							sendGroupData.map(item => (
								<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>

				<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
					<InputLabel id="demo-select-small">등급</InputLabel>
					<Select
						labelId="demo-select-small"
						id="demo-select-small"
						value={ searchParams.searchGrade }
						label="수신그룹"
						onChange={(e:any) => setSearchParams({...searchParams, searchGrade : e.target.value})}
					>
						{
							gradeData.map(item => (
								<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>

				<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
					<InputLabel id="demo-select-small">수신구분</InputLabel>
					<Select
						labelId="demo-select-small"
						id="demo-select-small"
						value={ searchParams.searchUseYn }
						label="수신그룹"
						onChange={(e:any) => setSearchParams({...searchParams, searchUseYn : e.target.value})}
					>
						{
							useYnData.map(item => (
								<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
							))
						}
					</Select>
				</FormControl>

				<FormControl sx={{ m: 1, ml:0}} size="small" >
					<TextField
						label="성명 or 휴대폰번호"
						id="outlined-size-small"
						onChange={(e:any) => setSearchParams({...searchParams, searchTxt : e.target.value})}						
						value={searchParams.searchTxt}
						size="small"
					/>
				</FormControl>

				<FormControl sx={{ m: 1, ml:0}}  >
					<Button variant='contained' style={{height : 40}} color='info' onClick={ handleSearch }>
						검색
					</Button>
				</FormControl>

				<FormControl sx={{ m: 1, ml:0}}  >
					<Button variant='contained' style={{height : 40}} color='error' onClick={ handleSearchReset }>
						초기화
					</Button>
				</FormControl>
			</Box>

			<Typography variant='subtitle1'>{`전체 : ${ totalCnt } 건`}</Typography>

            <TableContainer component={Paper}>
				<Table sx={{ minWidth: 800}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell >번호</TableCell>
							<TableCell >수신그룹</TableCell>
							<TableCell >성명</TableCell>
							<TableCell >휴대폰번호</TableCell>
							<TableCell >등급</TableCell>
							<TableCell >수신구분</TableCell>
							<TableCell >등록일</TableCell>
							<TableCell >등록자</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{managerList.map((item:any, index) => (
						<TableRow
							key={item.seq}
						>
                            <TableCell >{totalCnt - ((page-1) * pageSize) - index}</TableCell>
							<TableCell >
								<Link onClick={() => navigate('/sms/manageForm', { state: { paramSeq: item.seq, searchParams: searchParams } } )} sx={{cursor: 'pointer', textDecorationLine: 'none'}} >{sendGroupByText(`${item.sendGroup}`)}</Link>
							</TableCell>
							<TableCell >
								<Link onClick={() => navigate('/sms/manageForm', { state: { paramSeq: item.seq, searchParams: searchParams } } )} sx={{cursor: 'pointer', textDecorationLine: 'none'}} >{item.name}</Link>
							</TableCell>
							<TableCell >
								<Link onClick={() => navigate('/sms/manageForm', { state: { paramSeq: item.seq, searchParams: searchParams } } )} sx={{cursor: 'pointer', textDecorationLine: 'none'}} >{item.telno}</Link>
							</TableCell>
							<TableCell >{gradeByText(`${item.grade}`)}</TableCell>
							<TableCell >{useynByText(`${item.useYn}`)}</TableCell>							
							<TableCell >{convertTimestampToDate(`${item.regDate}`)}</TableCell>
							<TableCell >{item.regId}</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={{mt:2, display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
				<Button variant='contained' onClick={() => navigate('/sms/manageForm', { state: { paramSeq: 0, searchParams: searchParams } } )}>
					등록
				</Button>
			</Box>
			
			{/* 페이징 처리 */}
			<Stack spacing = {2} style= {{alignItems:'center', padding:30}} >
				<Pagination count = {pagingCount()} defaultPage={1} page={page} onChange = {handlePaginationChange} color="secondary" variant = "outlined" showFirstButton showLastButton />   
			</Stack>

			{
				isLoading &&
			 	/* 로딩바 */
				<Box sx={{ width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center' }}>
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={true}
						>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Box>
			}
        </>
    )

}
export default ManagerList;