import React from 'react';
import { customAxios } from 'axios/customAxios';
import { Box, Typography, TextField, Button, ButtonGroup, InputAdornment } from '@mui/material';
import { debounce, inRange, throttle } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EnvPath } from 'util/path/EnvPath';
import { dateToRegDateTime, formatDate } from 'util/Util';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import CategoryItem from './CategoryItem';
import {
    CarModelCategory,
    CarDetailModelCategory,
    CarGradeCategory,
    CarDetailGradeCategory,
} from 'interface/CarCategoryInterface';
import McDatePicker from 'component/McDatePicker';
import McBackdrop from 'component/McBackdrop';
import McDialog from 'carManagement/component/McDialog';
import arrayMove from 'array-move';

const containerStyle = {
    border: 2,
    borderColor: '#f3f3f3',
    //width: 300,
    height: 400,
    overflowY: 'auto',
};

interface CarCategoryFormProps {
    info: {
        depsName: string;
        depsNumber: string;
        parentNumber: string;
    };
    category: { number: string; name: string };
    depsByOption: any;
    handleSelectDepsByOption: (depsNumber: string, item: any) => void;
    handleDepsByOption: (name: string) => (e: any) => void;
    handleCategory: (name: string, number: string, categoryName: string) => void;
    handleCategoryInput: (name: string) => (e: any) => void;
    removeCategoryData: (deps: string) => void;
    handleCargubunModal?: () => void;
}

const CarCategoryForm = ({
    info,
    category,
    depsByOption,
    handleSelectDepsByOption,
    handleDepsByOption,
    handleCategory,
    handleCategoryInput,
    removeCategoryData,
    handleCargubunModal,
}: CarCategoryFormProps) => {
    const queryClient = useQueryClient();

    const { depsName, depsNumber, parentNumber } = info;
    const [data, setData] = React.useState<any>([]);
    const [removeDeps, setRemoveDeps] = React.useState<string>('');

    const [isDialog, setIsDialog] = React.useState(false);

    const [selectData, setSelectData] = React.useState<
        CarModelCategory | CarDetailModelCategory | CarGradeCategory | CarDetailGradeCategory | null
    >(null);

    //api 호출
    useQuery(
        [`/meetcha/car/manager/getListCarCategory${depsNumber}`, { parentNumber: parentNumber }],
        () => customAxios.get(`/meetcha/car/manager/getListCarCategory${depsNumber}?parentNumber=${parentNumber}`),
        {
            refetchOnWindowFocus: false,
            retry: false,
            //parentNumber가 있을 때만 쿼리 호출
            enabled: Boolean(parentNumber),
            //staleTime: 10 * 60 * 1000,
            onSuccess: ({ data }) => {
                setData(data);
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    const upsert = useMutation(
        (param) => customAxios.post(`/meetcha/car/manager/doSaveCarCategory${depsNumber}`, param),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([
                    `/meetcha/car/manager/getListCarCategory${depsNumber}`,
                    { parentNumber: parentNumber },
                ]);
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    const upsertList: any = useMutation(
        (param) => customAxios.post(`/meetcha/car/manager/doSaveCarCategoryList${depsNumber}`, param),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries([
                    `/meetcha/car/manager/getListCarCategory${depsNumber}`,
                    { parentNumber: parentNumber },
                ]);
            },
            onError: (err) => {
                console.log(err);
            },
        }
    );

    const remove = useMutation((param) => customAxios.post(`/meetcha/car/manager/doDeleteCarCategory`, param), {
        onSuccess: (data) => {
            setIsDialog(false);
            /* 
				삭제한 카테고리의 쿼리 ~ 하위 카테고리 초기화
				ex) 2단계 삭제시 -> 2 , 3 , 4 , 5 단계 쿼리 초기화 
			*/
            let arr = new Array(5);
            arr.fill('').forEach((i, index: number) => {
                let cId = index + 1;
                if (Number(removeDeps) <= cId) {
                    queryClient.invalidateQueries(`/meetcha/car/manager/getListCarCategory${cId}`);
                }
            });
            removeCategoryData(depsNumber);
        },
        onError: (err) => {
            alert('카테고리 삭제오류');
        },
    });

    /**
     * DraggingEvent
     * 아이템 이동시에 배열 순서만 변경
     *  @type {*}
     * */
    const handleDragEnd = (dragId: any, toIndex: any) => {
        const index: any = data.findIndex((item: any) => item.number === dragId);
        const copyData = arrayMove([...data], index, toIndex);

        copyData.forEach((i, index) => {
            i.sort = index + 1;
        });
        //미리 적용된 데이터를 보여줌
        setData(copyData);

        const formData: any = new FormData();
        //formData append 작업
        copyData.forEach((item, index) => {
            //날짜값이 string 으로 들어가기때문에 직접 변환해줘야함
            item.regDatetime = dateToRegDateTime(item.regDatetime, true);
            if (depsNumber === '2' || depsNumber === '3') {
                if (item.launchDate) {
                    item.launchDate = new Date(item.launchDate);
                }
            }
            /*
        		https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
        		Object.entries - [key , value] 형태의 배열로 변환해줌
        		장점 - 깔끔한 코드 , 단점 - map in map 이라 성능차이가 생김
        		위에 주석처리된 소스 참고

				ex ) carCategoryEntityList[0].number , value
        	*/
            Object.entries(item).forEach(([key, value]) => {
                if (key === 'launchDate') {
                    if (value) {
                        formData.append(`carCategoryEntityList[${index}].${key}`, item[key]);
                    }
                } else {
                    formData.append(`carCategoryEntityList[${index}].${key}`, item[key]);
                }
            });
        });
        upsertList.mutate(formData);
    };

    const depsBytitle = () => {
        switch (depsNumber) {
            case '2':
                return '모델';
            case '3':
                return '상세모델';
            case '4':
                return '등급';
            case '5':
                return '상세등급';
            default:
                return '';
        }
    };

    const addDepsByCategoryData = (type: 'add' | 'update') => {
        const formData: any = new FormData();
        const sort = data.length + 1;

        formData.append('categoryName', category.name);

        if (depsNumber === '2') {
            formData.append('carGubun', depsByOption.carGubunCode);

            if (depsByOption.modelLaunchDate) {
                formData.append('launchDate', formatDate(depsByOption.modelLaunchDate));
            }
        } else if (depsNumber === '3') {
            if (depsByOption.detailModelLaunchDate) {
                formData.append('launchDate', formatDate(depsByOption.detailModelLaunchDate));
            }
        } else if (depsNumber === '4') {
            formData.append('minPrice', depsByOption.minPrice);
        }

        if (type === 'add') {
            formData.append('regCount', '0');
            formData.append('regDatetime', dateToRegDateTime());
            formData.append('parentNumber', parentNumber);
            formData.append('sort', `${sort}`);
        } else {
            formData.append('number', selectData!.number);
            formData.append('regCount', selectData!.regCount);
            formData.append('regDatetime', dateToRegDateTime(selectData!.regDatetime, true));
            formData.append('parentNumber', parentNumber);
            formData.append('sort', `${selectData!.sort}`);
        }

        upsert.mutate(formData);

        if (type === 'add') {
            removeCategoryData(depsNumber);
            setSelectData(null);
        }
    };

    const removeDepsByCategoryData = () => {
        /* 
			삭제하는 카테고리의 단계를 state값으로 넣어줌 
			- 카테고리 삭제시 하위 카테고리도 같이 삭제되기때문에 해당 하위 카테고리 쿼리까지 초기화시켜줘야함
		*/
        setRemoveDeps(depsNumber);
        const formData: any = new FormData();
        formData.append('depth', depsNumber);
        formData.append('number', selectData!.number);

        remove.mutate(formData);
        //remove.mutateAsync()
    };

    const handleSelectData = (item: any) => {
        setSelectData(item);
        handleSelectDepsByOption(depsNumber, item);
    };

    /**
     * 단계별 추가정보 컴포넌트
     * 2단계 - 차종 + 출시일
     * 3단계 - 출시일
     * 4단계 - 최저가
     * @returns
     */
    const depsByAdditionalInfo = () => {
        let info: any = {
            id: `${depsName}_date`,
            label: '출시일',
            formSx: { m: 1, ml: 0 },
            datePickerSx: { width: 140 },
            size: 'small',
        };
        switch (depsNumber) {
            case '2':
                return (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 1 }}>
                            <Button variant="contained" onClick={handleCargubunModal} size={'small'}>
                                차종
                            </Button>
                            <Typography
                                ml={1}
                                fontSize={15}
                                alignSelf={'center'}
                                color={depsByOption.carGubunCode ? 'red' : 'black'}
                            >
                                {depsByOption.carGubunCode ? depsByOption.carGubunName : '차종선택'}
                            </Typography>
                        </Box>
                        <McDatePicker
                            info={info}
                            value={depsByOption.modelLaunchDate}
                            onChange={handleDepsByOption('modelLaunchDate')}
                        />
                    </>
                );
            case '3':
                return (
                    <McDatePicker
                        info={info}
                        value={depsByOption.detailModelLaunchDate}
                        onChange={handleDepsByOption('detailModelLaunchDate')}
                    />
                );
            case '4':
                return (
                    <Box sx={{ m: 1, ml: 0 }}>
                        <TextField
                            label="최저가"
                            value={depsByOption.minPrice}
                            sx={{ width: 180 }}
                            size="small"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{'만원'}</InputAdornment>,
                            }}
                            onChange={handleDepsByOption('minPrice')}
                        />
                    </Box>
                );
            default:
                return <Box sx={{ m: 1 }} />;
        }
    };

    //추가버튼 활성화 / 비활성화
    const disableAddButton = (deps: string) => {
        let result = true;
        if (category.name) {
            result = false;
        }
        return result;
    };

    //수정버튼 활성화 / 비활성화
    const disableUpdateButton = (deps: string) => {
        let result = true;
        if (selectData && category.name) {
            let launchDate = '';
            if (selectData.launchDate) {
                let convertKor = new Date(selectData.launchDate).getTime() + 9 * 60 * 60000;
                launchDate = new Date(convertKor).toISOString().split('T')[0];
            }

            if (deps === '2') {
                if (
                    launchDate !== depsByOption.modelLaunchDate ||
                    selectData.carGubun !== depsByOption.carGubunCode ||
                    selectData.categoryName !== category.name
                ) {
                    result = false;
                }
            } else if (deps === '3') {
                if (launchDate !== depsByOption.detailModelLaunchDate || selectData.categoryName !== category.name) {
                    result = false;
                }
            } else if (deps === '4') {
                if (selectData.minPrice !== depsByOption.minPrice || selectData.categoryName !== category.name) {
                    result = false;
                }
            } else {
                if (selectData.categoryName !== category.name) {
                    result = false;
                }
            }
            //result = false;
        }
        return result;
    };

    //단계별 값 초기화
    React.useEffect(() => {
        // if (depsNumber === '2') {
        //     if (!category.number) {
        //         setData([]);
        //         setSelectData(null);
        //     }
        // } else {
        if (!category.number && !parentNumber) {
            setData([]);
            setSelectData(null);
        }
        // }
    }, [parentNumber, category.number]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Box>
                <Box
                    sx={{
                        backgroundColor: '#f3f3f3',
                        p: 0.5,
                    }}
                >
                    <Typography fontSize={'21'} fontWeight={'bold'} align={'center'}>
                        {depsBytitle()}
                    </Typography>
                </Box>
                <Box sx={containerStyle}>
                    {data.length
                        ? data.map((data: any, index: number) => (
                              <CategoryItem
                                  key={data.number}
                                  data={data}
                                  info={{
                                      name: `category_${depsName}`,
                                      index: index,
                                      isSelect: Boolean(category.number === data.number),
                                  }}
                                  handleDragEnd={handleDragEnd}
                                  handleCategory={() => handleCategory(depsName, data.number, data.categoryName)}
                                  handleSelectData={handleSelectData}
                                  //post={post}
                              />
                          ))
                        : null}
                </Box>
                {parentNumber && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: parentNumber ? 0 : 1,
                        }}
                    >
                        <Box sx={{ alignSelf: 'flex-start', ml: 0 }}>{depsByAdditionalInfo()}</Box>
                        <TextField
                            // sx={{ ml: 0, alignSelf: 'flex-start' }}
                            fullWidth
                            value={category.name}
                            onChange={handleCategoryInput(depsName)}
                            size={'small'}
                        />
                        <ButtonGroup variant="contained" sx={{ mt: 2 }}>
                            <Button
                                disabled={disableAddButton(depsNumber)}
                                onClick={() => addDepsByCategoryData('add')}
                            >
                                추가
                            </Button>
                            <Button
                                disabled={disableUpdateButton(depsNumber)}
                                onClick={() => addDepsByCategoryData('update')}
                                sx={{ backgroundColor: '#5E95D2' }}
                            >
                                수정
                            </Button>
                            <Button
                                disabled={!Boolean(selectData)}
                                onClick={() => setIsDialog(true)}
                                sx={{ backgroundColor: '#094E9B' }}
                            >
                                삭제
                            </Button>
                        </ButtonGroup>
                        <Button
                            onClick={() => removeCategoryData(depsNumber)}
                            sx={{ mt: 1 }}
                            variant={'contained'}
                            color={'error'}
                        >
                            초기화
                        </Button>
                    </Box>
                )}
            </Box>
            {isDialog && (
                <McDialog
                    title={'경고'}
                    description={
                        <>
                            {`선택한 카테고리를 삭제시 하위 카테고리를 포함하여 삭제되며 차량정보의 카테고리 정보가 초기화되고 미노출상태로 전환 됩니다.\n`}
                            <span style={{ color: 'red' }}>삭제 하시겠습니까?</span>
                        </>
                    }
                    handleClose={() => setIsDialog(false)}
                    handleConfirm={() => {
                        setIsDialog(false);
                        removeDepsByCategoryData();
                    }}
                />
            )}
            {Boolean(remove.isLoading || upsert.isLoading || upsertList.isLoading) && <McBackdrop />}
        </DndProvider>
    );
};

export default CarCategoryForm;

/**
 * DragEnd Event
 * 카테고리의 순서 변경이 끝났을 때 sort 값 계산 -> api 호출
 * @param from 시작 index
 * @param to 도착 index
 */
// const post = (from: number, to: number, number: any) => {
//     const postData: Array<CarModelCategory | CarDetailModelCategory | CarGradeCategory | CarDetailGradeCategory> = [
//         ...data,
//     ];

// 		// sort 재정렬
// 		// drop 이벤트가 빠르게 일어나면 sort 값이 중복되거나 이상하게 계산되어 있는 경우가 있어서
// 		// dragEnd 이벤트에서 한번에 처리

//     postData.forEach((i, index) => {
//         i.sort = index + 1;
//     });
//     //미리 적용된 데이터를 보여줌
//     setData(postData);

//     const formData: any = new FormData();
//     //formData append 작업
//     postData.forEach((item, index) => {
//         //날짜값이 string 으로 들어가기때문에 직접 변환해줘야함
//         item.regDatetime = dateToRegDateTime(item.regDatetime, true);
//         if (depsNumber === '2' || depsNumber === '3') {
//             if (item.launchDate) {
//                 item.launchDate = new Date(item.launchDate);
//             }
//         }

//     		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
//     		// Object.entries - [key , value] 형태의 배열로 변환해줌
//     		// 장점 - 깔끔한 코드 , 단점 - map in map 이라 성능차이가 생김
//     		// 위에 주석처리된 소스 참고

// 			// ex ) carCategoryEntityList[0].number , value

//         Object.entries(item).forEach(([key, value]) => {
//             formData.append(`carCategoryEntityList[${index}].${key}`, item[key]);
//         });
//     });
//     upsertList.mutate(formData);
// };

/**
 * DragEnd Event
 * 카테고리의 순서 변경이 끝났을 때 sort 값 계산 -> api 호출
 * @param from 시작 index
 * @param to 도착 index
 */
//  const post = (from: number, to: number, number: any) => {
//     const postData: Array<CarModelCategory | CarDetailModelCategory | CarGradeCategory | CarDetailGradeCategory> = [
//         ...data,
//     ];

//     //정방향 , 역방향 구분처리
//     // let compareFromTo = from < to;
//     // let interval = to - from;
//     // postData.forEach((item, index) => {
//     //     if (compareFromTo) {
//     //         //정방향
//     //         if (inRange(index, from, to)) {
//     //             if (item.number !== number) {
//     //                 item.sort = Number(item.sort) - 1;
//     //             }
//     //         }
//     //     } else {
//     //         if (interval === -1) {
//     //             //역방향 , 한칸 이동
//     //             if (inRange(index, to, from + 1)) {
//     //                 if (item.number !== number) {
//     //                     item.sort = Number(item.sort) + 1;
//     //                 }
//     //             }
//     //         } else {
//     //             //역방향 , 여러칸 이동
//     //             if (inRange(index, to + 1, from + 1)) {
//     //                 if (item.number !== number) {
//     //                     item.sort = Number(item.sort) + 1;
//     //                 }
//     //             }
//     //         }
//     //     }
//     // });

//     setData(postData);
//     //2 , 3 , 4 , 5
//     const formData: any = new FormData();
//     // postData.map((item, index) => {
//     //     console.log(Object.entries(item), '<ts');

//     //     item.regDatetime = new Date(item.regDatetime);
//     //     formData.append(`carCategoryEntityList[${index}].number`, item['number']);
//     //     formData.append(`carCategoryEntityList[${index}].categoryName`, item['categoryName']);
//     //     formData.append(`carCategoryEntityList[${index}].regCount`, item['regCount']);
//     //     formData.append(
//     //         `carCategoryEntityList[${index}].regDatetime`,
//     //         item.regDatetime ? dateToRegDateTime(item.regDatetime) : '0000-00-00 00:00:00'
//     //     );
//     //     formData.append(`carCategoryEntityList[${index}].sort`, item['sort']);

//     //     switch (depsNumber) {
//     //         case '2':
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['carGubun']);
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['launchDate']);
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['parentNumber']);
//     //             break;
//     //         case '3':
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['launchDate']);
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['parentNumber']);
//     //             break;
//     //         case '4':
//     //             formData.append(`carCategoryEntityList[${index}].minPrice`, item['minPrice']);
//     //             formData.append(`carCategoryEntityList[${index}].parentNumber`, item['parentNumber']);
//     //             break;
//     //         default:
//     //             break;
//     //     }
//     // });

//     //formData append 작업
//     postData.forEach((item, index) => {
//         item.regDatetime = dateToRegDateTime(item.regDatetime, true);
//         if (depsNumber === '2' || depsNumber === '3') {
//             if (item.launchDate) {
//                 item.launchDate = new Date(item.launchDate);
//             }
//         }

//     		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
//     		// Object.entries - [key , value] 형태의 배열로 변환해줌
//     		// 장점 - 깔끔한 코드 , 단점 - map in map 이라 성능차이가 생김
//     		// 위에 주석처리된 소스 참고

//         Object.entries(item).forEach(([key, value]) => {
//             formData.append(`carCategoryEntityList[${index}].${key}`, item[key]);
//         });
//     });
//     upsertList.mutate(formData);
// };
