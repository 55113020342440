import * as React from 'react';
import axios, { AxiosInstance } from 'axios';
import { EnvPath } from 'util/path/EnvPath';

const { REACT_APP_API_URL } = EnvPath;

export const customAxios: AxiosInstance = axios.create({
    baseURL: `${REACT_APP_API_URL}`,
    /* 여기서 header 를 실어주게 되면 로그인 전에 create 되기 때문에 header에 null 값이 들어가게 됨 */
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
});

export const testAxios: AxiosInstance = axios.create({
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
});
