import * as React from 'react';
import { Button } from '@mui/material';

interface McButtonProps {
	variant : 'contained' | 'outlined' | 'text' | undefined,
	title : string,
	color : "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined ,
	disabled ?: boolean,
	onClick : any
}

const McButton = ({variant , title , color , disabled=false , onClick}:McButtonProps) => {
	return (
		<Button
			sx={{mr:1 , mt:1 , mb:1}}
			variant={variant}
			color={color}
			onClick={onClick}
			disabled={disabled}
		>
			{title}
		</Button>
	)
}

export default McButton ;