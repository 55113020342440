import * as React from 'react';
import { Modal , Box} from '@mui/material';
import DaumPostcode from 'react-daum-postcode'

interface DaumPostCodeModalProps {
	onClose : () => void ,
	onComplete : any
}
const DaumPostCodeModal = ({onClose , onComplete}:DaumPostCodeModalProps) => {

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width:  window.innerWidth < 500 ? window.innerWidth * 0.8 : window.innerWidth * 0.6,
		height :  window.innerHeight * 0.7,
		bgcolor: 'background.paper',
		overflowY :'scroll',
		//border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}

	return (
		<div>
			<Modal 
					open={true}
					onClose={onClose}
				>
					<Box sx={style}>
						<DaumPostcode 
							onComplete={onComplete}  // 값을 선택할 경우 실행되는 이벤트
							autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
						/>
					</Box>
				</Modal>
		</div>  
	)
}
export default DaumPostCodeModal