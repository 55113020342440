import * as React from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
//import { CookiesProvider } from "react-cookie";
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
//const root = createRoot(rootElement!);

ReactDOM.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {/* <CookiesProvider> */}
        <RecoilRoot>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>
        {/* </CookiesProvider> */}
    </ThemeProvider>,
    rootElement,
);
