import * as React from 'react';

import Box from '@mui/material/Box';

import CarCountryRadioGroup from 'carManagement/carRegistration/carBasicInfo/CarCountryRadioGroup'
import CarCategorySelect from 'carManagement/carRegistration/carBasicInfo/CarCategorySelect';
import CarSagoRadioGroup from 'carManagement/carRegistration/carBasicInfo/CarSagoRadioGroup';

import McCheckBox from 'carManagement/component/McCheckBox';
import McTypography from 'carManagement/component/McTypography';
import McSelectItem from 'carManagement/component/McSelectItem';
import McTextField from 'carManagement/component/McTextField';
import { CarFilterMenu } from 'interface/CarFilterMenuAPi';

interface CarBasicInfoProps {
	carFilterMenu : CarFilterMenu ,
	carBrandList : {
		kor : Array<any>
		for : Array<any>
		modelList : Array<any>
	},
	carInfoData : any,
	handleCarInfoData : (name:string) => (e:any) => void,
	//handleCarInfoTextData : (name:string) => (e:any) => void ,
	handleCategoryName : (name:string) => (e:any) => void ,
	handleInputText : (name:string) => (e:any) => void ,
}

const CarBasicInfo = ({carFilterMenu , carBrandList,carInfoData ,handleCarInfoData , handleCategoryName , handleInputText}:CarBasicInfoProps) => {

	//차량 용도 데이터
	const carGroup1 = ['출퇴근용' , '드라이브용' , '레저용' , '오프로드' , '영업용' , '렌트' , '리스'];
	
	//차량 테마 종류 데이터
	const carTheme = [
		{name : '렌트' , value : '3'}
	];
	//차량 노출 여부
	const carShowYn = [
		{name : '노출' , value : 'Y'},
		{name : '미노출' , value : 'N'}
	];

	const carRate = ['1','2','3','4','5'];

	//차량 등록 월
	const carMonth = ['01','02','03','04','05','06','07','08','09','10','11','12'];

	return (
			<>
				{
					carFilterMenu &&
					<>
						{/* 차량 기본정보 */}
						<Box sx={{ width: '100%', minWidth: 180 , borderBottom : 1 , mb : 2}}>
							<McTypography variant='h5' text='차량 기본정보'/>
						</Box>
						{/* 제조국 선택 */}
						<CarCountryRadioGroup  onChange={handleCarInfoData} value={carInfoData.typeNation} name={'typeNation'}/>
						{/* 제조사 및 모델 */}
						<Box sx={{ width: '100%', minWidth : 120}}>
							<CarCategorySelect 
								brand={carInfoData.typeNation === '0' ? carBrandList.kor : carBrandList.for}
								modelList={carBrandList.modelList}
								value={carInfoData}
								onChange={handleCarInfoData}
								onClick={handleCategoryName}
							/>
						</Box>
						{/* 광고 문구 */}
						<Box sx={{ width: '100%', minWidth : 180, '& > :not(style)': {width: '92%' }}}>
							<McTextField 
								id='title'
								value={carInfoData['title']}
								label='광고(홍보)문구'
								variant='outlined'
								onChange={handleInputText}
							/>
						</Box>
						{/* 용도 , 연식*/}
						<Box sx={{ width: '100%', minWidth : 120}}  >
							<McSelectItem data={carGroup1} title={'용도'} id={'group1'} value={carInfoData.group1} label={'용도'} onChange={handleCarInfoData}/>
							<McSelectItem data={carFilterMenu.carYm} title={'년'} id={'year'} value={carInfoData.year} label={'년'} onChange={handleCarInfoData}/>
							<McSelectItem data={carMonth} title={'월'} id={'month'} value={carInfoData.month} label={'월'} onChange={handleCarInfoData}/>
						</Box>
						{/* 색상 , 연료 */}
						<Box sx={{ width: '100%', minWidth : 120}} >
							<McSelectItem data={carFilterMenu.carColor} title={'색상'} id={'tone'} value={carInfoData.tone} label={'색상'} onChange={handleCarInfoData}/>
							<McSelectItem data={carFilterMenu.carFuel} title={'연료'} id={'fuel'} value={carInfoData.fuel} label={'연료'} onChange={handleCarInfoData}/>
						</Box>
						{/* 배기량 , 변속기 */}
						<Box sx={{ width: '100%', minWidth : 120}} >
							<McTextField 
								id='gas'
								value={carInfoData['gas']}
								label='배기량'
								variant='outlined'
								endAdornment='cc'
								onChange={handleInputText}
							/>
							<McSelectItem data={carFilterMenu.carAtmt} title={'변속기'} id={'atmt'} value={carInfoData.atmt} label={'변속기'} onChange={handleCarInfoData}/>
						</Box>
						{/* 주행거리 , 사고유무*/}
						<Box sx={{ width: '100%', minWidth : 120}} >
							<McTextField 
								id='mileage'
								value={carInfoData['mileage']}
								label='주행거리'
								variant='outlined'
								endAdornment='Km'
								onChange={handleInputText}
							/>
							<CarSagoRadioGroup onChange={handleCarInfoData} data={carFilterMenu.carSago} value={carInfoData.sago} name={'sago'}/>
						</Box>
						{/* 제시신고번호 , 차량번호*/}
						<Box sx={{ width: '100%', minWidth : 120}} >
							<McTextField 
								id='gouNumber'
								value={carInfoData['gouNumber']}
								label='제시신고번호'
								variant='outlined'
								onChange={handleInputText}
							/>
							<McTextField 
								id='carNumber'
								value={carInfoData['carNumber']}
								label='차량번호'
								variant='outlined'
								placeholder='예)12가 1234'
								onChange={handleInputText}
							/>
						</Box>
						{/* 압류 / 저당 , 공인연비 / 연비등급 */}
						<Box sx={{ width: '100%', minWidth : 120}} >
							<McTextField 
								id='apryo'
								value={carInfoData['apryo']}
								label='압류'
								variant='outlined'
								onChange={handleInputText}
							/>
							<McTextField 
								id='jeodang'
								value={carInfoData['jeodang']}
								label='저당'
								variant='outlined'
								onChange={handleInputText}
							/>
						</Box>
						{/* <Box sx={{ width: '100%', minWidth : 120}} >
							<McTextField 
								id='officialMileage'
								value={carInfoData['officialMileage']}
								label='공인연비'
								variant='outlined'
								onChange={handleInputText}
								endAdornment={'Km'}
							/>
							<McSelectItem data={carRate} title={'연비등급'} id={'fuelGrade'} value={carInfoData.fuelGrade} label={'연비등급'} onChange={handleCarInfoData}/>
						</Box> */}
						{/* 매물테마설정 , 노출여부 */}
						<Box sx={{ width: '100%', minWidth : 180}} >
							<McSelectItem data={carTheme} title={'테마종류선택'} id={'etc2'} value={carInfoData.etc2} label={'테마종류선택'} onChange={handleCarInfoData}/>
							<McSelectItem data={carShowYn} title={'노출여부'} id={'showYn'} value={carInfoData.showYn} label={'노출여부'} onChange={handleCarInfoData}/>
						</Box>
						{/* 소유구분 */}
						<Box sx={{ width: '100%', minWidth : 120}}>
							<McCheckBox checked={carInfoData.etc1 === 'Y'} onChange={handleCarInfoData} value={carInfoData.etc1 === 'Y' ? 'N' : 'Y'} name={'etc1'} label={'1인소유'} />
						</Box>
						<Box sx={{ width: '100%', minWidth : 120 , borderBottomWidth : '5' , borderBottomColor : 'red' , height : 5}} />
					</>
				}
			</>				
	)
	}

export default CarBasicInfo;