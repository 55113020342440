import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import BannerRoute from './BannerRoute';

interface SiteRouteProps {}

const SiteRoute = ({}: SiteRouteProps) => {
    return (
        <Routes>
            <Route path="/banner/*" element={<BannerRoute />} />
        </Routes>
    );
};
export default SiteRoute;
