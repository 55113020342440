import * as React from 'react';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';

interface AddressFormProps {
    addressData: any;
    onChangeDetailAddress: (e: any) => void;
    handleSearhAddress: () => void;
}

const AddressForm = ({ addressData, onChangeDetailAddress, handleSearhAddress }: AddressFormProps) => {
    return (
        <Grid
            sx={{ m: 1, mt: 2, mb: 2, borderTop: 1, borderColor: '#e7e7e7' }}
            container
            columns={{ xs: 3, sm: 3, md: 3 }}
        >
            <Box sx={{ mt: 1 }}>
                <Typography fontWeight={'bold'} fontSize={16} variant="body1">
                    주소
                </Typography>
            </Box>
            <Grid item xs={3} sm={3} md={3} sx={{ display: 'flex', mt: 1 }}>
                <TextField
                    label="우편번호"
                    disabled
                    variant="standard"
                    value={addressData.zonecode}
                    //sx={{width : 300}}
                    size="small"
                />
                <Button variant="contained" size="small" onClick={handleSearhAddress}>
                    우편번호찾기
                </Button>
            </Grid>
            <Grid item xs={3} sm={3} md={3} sx={{ mt: 1 }}>
                <TextField
                    label="주소"
                    disabled
                    variant="standard"
                    value={addressData.address}
                    sx={{ width: 300 }}
                    size="small"
                />
            </Grid>
            <Grid item xs={3} sm={3} md={3} sx={{ mt: 1 }}>
                <TextField
                    label="상세주소"
                    variant="standard"
                    value={addressData.detailAddress}
                    onChange={onChangeDetailAddress}
                    sx={{ width: 300 }}
                    size="small"
                />
            </Grid>
        </Grid>
    );
};
export default AddressForm;
