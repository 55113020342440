import * as React from 'react'
import {Button , Box} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';

interface TypeByButtonGroupProps {
	handleButton : (group : string) => void
} 

const TypeByButtonGroup = ({handleButton}:TypeByButtonGroupProps) => {
  
	return (
		<Box sx={{display : 'flex' , justifyContent : 'center' , mt : 2 }}>
			<Button 
				sx={{width : 200 , mr : 1}} 
				variant='contained' 
				color='primary'
				startIcon={<AssignmentIcon />}
				onClick={() => handleButton('21')}
			>
				매입상담사
			</Button>
			<Button 
				sx={{width : 200 , mr : 1}} 
				variant='contained' 
				color='primary'
				startIcon={<SupportAgentIcon />}
				onClick={() => handleButton('20')}
			>
				카스타일러
			</Button>
			<Button 
				sx={{width : 200 , mr : 1}} 
				variant='contained' 
				color='primary'
				startIcon={<DirectionsCarIcon />}
				onClick={() => handleButton('8')}
			>
				딜러회원
			</Button>
			<Button 
				sx={{width : 200 }} 
				variant='contained' 
				color='primary'
				startIcon={<DirectionsCarIcon />}
				onClick={() => handleButton('22')}
			>
				영업제휴회원
			</Button>
		</Box>
  	)
}
export default TypeByButtonGroup