import * as React from 'react'
import {CarInfoData , SelectOption} from 'interface/CarRegistrationInterface'

//의존성 체크
export const validationCheck = (handleValidationDialog : (text:string) => void , formData:CarInfoData , imageFileSize : number , call:string) => {
	// if(call === 'update'){
	// 	return true;
	// }
	const numberCheck = /^[0-9]+$/;

	const phoneNumberCheck = /^[0-9-]+$/;

	const carNumberCheck = /\d{2,3}[가-힣]{1}\d{4}/gm;

	const urlCheck = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ ;
	
	if(!formData.category1){
		handleValidationDialog('제조사를 선택해주세요.');
		return false;
	}

	if(!formData.category2){
		handleValidationDialog('모델을 선택해주세요.');
		return false;
	}

	if(!formData.year){
		handleValidationDialog('연식의 년도를 선택해주세요.');
		return false;
	}

	if(!formData.month){
		handleValidationDialog('연식의 월을 선택해주세요.');
		return false;
	}

	if(!formData.tone){
		handleValidationDialog('색상을 선택해주세요.');
		return false;
	}

	if(!formData.fuel){
		handleValidationDialog('연료을 선택해주세요.');
		return false;
	}

	if(!formData.atmt){
		handleValidationDialog('변속기를 선택해주세요.');
		return false;
	}

	if(!formData.gas){
		handleValidationDialog('배기량을 입력해주세요');
		return false;
	}else {
		if(!numberCheck.test(formData.gas)){
			handleValidationDialog('배기량은 숫자만 입력가능합니다');
			return false;
		}
	}

	if(!formData.gouNumber){
		handleValidationDialog('제시신고번호를 입력해주세요');
		return false;
	}else {
		if(!numberCheck.test(formData.price)){
			handleValidationDialog('제시신고번호는 숫자만 입력가능합니다');
			return false;
		}
	}

	if(!formData.carNumber){
		handleValidationDialog('차량번호를 입력해주세요');
		return false;
	}else {
		if(!carNumberCheck.test(formData.carNumber)){
			handleValidationDialog('차량번호 형식이 다릅니다. (예시 - 12가 1234 혹은 123가 4567) ');
			return false;
		}
	}
	
	if(!imageFileSize){
		handleValidationDialog('이미지를 등록해주세요');
		return false;
	}

	if(!formData.prfomncChckUrl && formData.carJagaImgUse === '0'){
		handleValidationDialog('성능점검표 URL 주소 혹은 성능점검표 이미지를 추가해주세요');
		return false;
	}else {
		if(formData.prfomncChckUrl){
			if(!urlCheck.test(formData.prfomncChckUrl)){
				handleValidationDialog('성능점검표 형식이 다릅니다. (예시 - URL 첨부) ');
				return false;
			}
		}else if(formData.carJagaImgUse === '1' && formData.carJagaImg){
			//image/jpg, image/png, image/jpeg
			if(!(formData.carJagaImg.type === 'image/png' || formData.carJagaImg.type === 'image/jpg' || formData.carJagaImg.type === 'image/jpeg')){
				handleValidationDialog('파일 형식은 .png , .jpg , .jpeg 만 가능합니다.');
				return false
			}
		}
	}
	if(formData.carJagaImgUse === 'Y'){
		if(!formData.carJagaImg){
			handleValidationDialog('성능점검기록부 이미지를 등록해주세요.');
			return false;
		}
	}

	if(!formData.phone1 && !formData.phone2){
		handleValidationDialog('전화번호 혹은 핸드폰 번호 둘 중에 하나는 입력해주세요.');
		return false;
	}else {
		if(!formData.phone2 && !phoneNumberCheck.test(formData.phone1)){
			handleValidationDialog('전화번호를 다시 입력해주세요.');
			return false;
		}else if(!formData.phone1 && !phoneNumberCheck.test(formData.phone2)){
			handleValidationDialog('핸드폰 번호를 다시 입력해주세요.');
		}
	}

	if(!formData.price){
		handleValidationDialog('가격을 입력해주세요');
		return false;
	}else {
		if(!numberCheck.test(formData.price)){
			handleValidationDialog('가격은 숫자만 입력가능합니다');
			return false;
		}
	}

	if(!formData.oldPrice){
		handleValidationDialog('구매시 가격을 입력해주세요');
		return false;
	}else {
		if(!numberCheck.test(formData.oldPrice)){
			handleValidationDialog('구매시 가격은 숫자만 입력가능합니다');
			return false;
		}
	}

	return true;
}

//키워드 및 타이틀 조합
const handleKeyword = (carInfoData:CarInfoData , carInfoSideData:any) => {
	let typeNation 
	if(carInfoData.typeNation === '1'){
		typeNation = '수입차';
	}else {
		typeNation = '국산차';
	}

	let selectTitle = `[${carInfoSideData.categoryName1}]${carInfoSideData.categoryName2} ${carInfoSideData.categoryName3} ${carInfoSideData.categoryName4} ${carInfoSideData.categoryName5}`
	
	let keyword = `${typeNation},${carInfoSideData.categoryName1},${carInfoSideData.categoryName2},${carInfoSideData.categoryName3},${carInfoSideData.categoryName4},${carInfoSideData.categoryName5},${carInfoData.title ? carInfoData.title : selectTitle},${carInfoData.group1},${carInfoData.tone},${carInfoData.fuel},${carInfoData.gas},${carInfoData.atmt},${carInfoData.mileage},${carInfoData.gouNumber},${carInfoData.carNumber},${carInfoData.id},${carInfoData.userNick},${carInfoData.phone1},${carInfoData.siName},${carInfoData.addr}`

	return [keyword , selectTitle];
	
}

//차량 등록용 데이터 formData 에 삽입 후 리턴
export const handleCarRegistration = (carInfoData:CarInfoData , carInfoSideData:any , selectOption:SelectOption , imageFile:Array<any> ,  wysiwhgRef:any) => {

	const formData = {...carInfoData};
	const keywordAndTitle = handleKeyword(carInfoData ,carInfoSideData );
	const compareWysiwyg = wysiwhgRef.current?.getInstance().getHTML() !== '<p><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak"></p>'
	const handleWysiwhgTag = () => {
		if(compareWysiwyg){
			const htmlCode = wysiwhgRef.current?.getInstance().getHTML();
			if(htmlCode.includes('<br')){
				return htmlCode.replace('<br class="ProseMirror-trailingBreak">' , '<br />');
			}else {
				return htmlCode;
			}
		}else {
			return '';
		}
	}

	const reqData = new FormData();
	if(formData.number){
		reqData.append('number' , `${formData.number}`);	
	}
	reqData.append('ym' , `${formData.year}-${formData.month}-01`);
	reqData.append('main' , handleWysiwhgTag());
	reqData.append('carOption2' , selectOption.carOption2.toString().replace( /,/gi, '/'));
	reqData.append('carOption3' , selectOption.carOption3.toString().replace( /,/gi, '/'));
	reqData.append('carOption4' , selectOption.carOption4.toString().replace( /,/gi, '/'));
	reqData.append('carOption5' , selectOption.carOption5.toString().replace( /,/gi, '/'));
	reqData.append('carOption8' , selectOption.carOption8.toString().replace( /,/gi, '/'));
	imageFile.map(item => {
		reqData.append('imageFiles' , item);
	});
	reqData.append('id', formData.id);
	if(formData.phone1){
		formData.phone1.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
	}
	if(formData.phone2){
		formData.phone2.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
	}
	reqData.append('phone1', formData.phone1);
	reqData.append('phone2', formData.phone2);
	reqData.append('email', formData.email);
	reqData.append('si', formData.si);
	reqData.append('gu', formData.gu);
	reqData.append('dong', formData.dong);
	reqData.append('fullAddr', formData.fullAddr);
	reqData.append('group1', formData.group1);
	reqData.append('tone', formData.tone);
	reqData.append('fuel', formData.fuel);
	reqData.append('gas', formData.gas);
	reqData.append('mileage', formData.mileage);
	reqData.append('atmt', formData.atmt);
	reqData.append('sago', formData.sago);
	reqData.append('quota', formData.quota);
	reqData.append('price', formData.price);
	reqData.append('saleEnd', formData.saleEnd);
	reqData.append('homeServiceYn', formData.homeServiceYn);
	reqData.append('showYn', formData.showYn);
	if(formData.title){
		reqData.append('title', formData.title);
	}else {
		reqData.append('title', keywordAndTitle[1]);	
	}
	reqData.append('gouNumber', formData.gouNumber);
	reqData.append('etc1', formData.etc1);
	reqData.append('etc2', formData.etc2);
	if(formData.carJagaImgUse === '1'){
		reqData.append('carJagaImg', formData.carJagaImg);
	}
	reqData.append('carJagaImgUse', formData.carJagaImgUse);
	reqData.append('oldPrice', formData.oldPrice);
	reqData.append('addr', formData.addr);
	reqData.append('sangho', formData.sangho);
	reqData.append('carNumber', formData.carNumber);
	reqData.append('carname', carInfoSideData.categoryName1);
	reqData.append('apryo', formData.apryo);
	reqData.append('jeodang', formData.jeodang);
	reqData.append('apJeoCheck', formData.apJeoCheck);
	reqData.append('category1', formData.category1 ? formData.category1 : '0');
	reqData.append('category2', formData.category2 ? formData.category2 : '0');
	reqData.append('category3', formData.category3 ? formData.category3 : '0');
	reqData.append('category4', formData.category4 ? formData.category4 : '0');
	reqData.append('category5', formData.category5 ? formData.category5 : '0');
	reqData.append('keyword', keywordAndTitle[0]);
	reqData.append('typeNation', formData.typeNation);
	reqData.append('carhistory', formData.carhistory);
	reqData.append('officialMileage', formData.officialMileage ? formData.officialMileage : '0');
	reqData.append('fuelGrade', formData.fuelGrade ? formData.fuelGrade : '0');
	reqData.append('prfomncChckUrl', formData.prfomncChckUrl ? formData.prfomncChckUrl : '0');
	reqData.append('addPrice', formData.addPrice);
	reqData.append('saleType', formData.saleType);
	
	return reqData;
}