import * as React from 'react'
import { Modal , Box, Typography, TextField, debounce, InputAdornment, Tooltip, Button, ClickAwayListener, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import McDialog from 'carManagement/component/McDialog';


interface DealerCarUpdatePriceModalProps {
	carInfo : any ,
	onClose : () => void,
	handleUpdatePrice : (price:string , addPrice:string , number:string) => void
}

//가격변경 모달
const DealerCarUpdatePriceModal = ({carInfo , onClose , handleUpdatePrice}:DealerCarUpdatePriceModalProps) => {

	//숫자 정규식
	const numberCheck = /^[0-9]+$/;
	const addPriceCheck = /^(-?)[0-9]+$/;

	const [isDialog , setIsDialog] = React.useState<boolean>(false);

	const [priceInfo , setPriceInfo] = React.useState({
		price : '',
		addPrice : ''
	})

	const [isTooltip , setIsTooltip] = React.useState(false);

	const modalWidth = () => {
		if(window.innerWidth < 450){
			return window.innerWidth * 0.8;
		}else if(window.innerWidth > 450 && window.innerWidth < 850){
			return window.innerWidth * 0.6;
		}else {
			return window.innerWidth * 0.4;
		}
	}

	const modalHeight = () => {
		if(window.innerHeight < 650){
			return window.innerHeight * 0.65;
		}else {
			return window.innerHeight * 0.5;
		}
	}
	
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth : 120 ,
		width: modalWidth(),
		height :  modalHeight(),
		bgcolor: 'background.paper',
		//overflow :'scroll',
		//border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	//수정된 가격 총합
	const updatePrice = () => {
		if(!priceInfo.price && !priceInfo.addPrice){
			return Number(carInfo.price) + Number(carInfo.add_price);
		}else if(priceInfo.price && priceInfo.addPrice){
			return Number(priceInfo.price) + Number(priceInfo.addPrice);
		}else {
			if(priceInfo.price){
				return Number(priceInfo.price) + Number(carInfo.add_price);
			}else {
				return Number(carInfo.price) + Number(priceInfo.addPrice);
			}
		}
	}

	//debounce 를 사용한 input onchange
	const handleInputText = (name:string) => (e:any) => {
		debouncedSearch(e.target.value , name);
	}

	const handleConfirm = () => {
		setIsDialog(true);
		handleUpdatePrice(priceInfo.price , priceInfo.addPrice , carInfo.number);
	}

	//input의 onChange 사용시 잦은 렌더링으로 성능이슈가 생길 수 있으므로 debounce 함수로 딜레이를 걸어줌
	const debouncedSearch = React.useMemo(() => debounce((text , name) => {
		// 모든 호출이 아닌 
		// 지정 간격 마다 리턴 값 받아서 state에 담고
		setPriceInfo({
			...priceInfo,
			[name] : text
		})
	
	}, 500), [ priceInfo ]);

	//모달 진입시 가격 , 증감액을 추가해줌
	React.useEffect(()=> {
		setPriceInfo({
			...priceInfo,
			price : carInfo.price,
			addPrice : carInfo.add_price
		})
	}, [])

	return (
		<div>
			<Modal 
				open={true}
				onClose={onClose}
			>
				<Box sx={style}>
					<Box sx={{mb : 2}}>
						<Typography variant='h6' sx={{textAlign : 'center'}}>가격 변경</Typography>
					</Box>
					<Box sx={{alignItems : 'center'}}>
						<TextField 
							sx={{m : 1}}
							id='price' 
							onChange={handleInputText('price')} 
							variant='outlined' label='판매가' 
							defaultValue={carInfo.price} 
							InputProps={{
								endAdornment: <InputAdornment position="end">{'만원'}</InputAdornment>,
							}}
							error={ numberCheck.test(priceInfo.price) ? false : true}
						/>
						<TextField 
							sx={{m : 1}}
							id='add_price' 
							onChange={handleInputText('addPrice')} 
							variant='outlined' label='증감액' 
							defaultValue={carInfo.add_price}
							error={ addPriceCheck.test(priceInfo.addPrice) ? false : true}
							InputProps={{
								endAdornment: 
								<InputAdornment position="end">
									{'만원'}
									{/* 툴팁 사용 */}
									<ClickAwayListener onClickAway={() => setIsTooltip(false)}>
										<Tooltip
											sx={{m : 1 , display:'flex' , justifyContent : 'center'}}
											PopperProps={{
											disablePortal: true,
											}}
											//placement="bottom-start"
											onClose={() => setIsTooltip(false)}
											open={isTooltip}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											title={<span style={{ whiteSpace: 'pre-line' }}>{`가격을 감소시키고 싶은 경우에는 \n숫자 앞에 - 를 추가해주세요.`}</span>}
										>
											<Button style={{maxWidth:30 , minWidth:30}} onClick={() => setIsTooltip(!isTooltip)}><HelpIcon sx={{width : 20 , height : 20}}/></Button>
										</Tooltip>
									</ClickAwayListener>
								</InputAdornment>,
							}}
						/>
					</Box>
					<Box sx={{mb : 1}}>
						<Typography variant='h6' sx={{textAlign : 'end'}}>변경 가격 : {updatePrice()}만원</Typography>
					</Box>
					<Box sx={{display : 'flex' , justifyContent : 'flex-end'}}>
						<Button 
							sx={{m:1}} 
							disabled={ isNaN(Number(priceInfo.price) + Number(priceInfo.addPrice)) ? true : false } 
							onClick={() => setIsDialog(true)} 
							variant='contained' 
							color='primary'
						>
							변경
						</Button>
						<Button sx={{m:1}} onClick={onClose} variant='contained' color='error'>취소</Button>
					</Box>
				</Box>
			</Modal>
			{
				
				/* 가격 변경을 제외한 다른 메뉴 버튼의 dialog */
				isDialog &&
				<McDialog 
					title={'안내'}
					description={'가격 변경을 하시겠습니까?'}
					handleClose={() => setIsDialog(!isDialog)}
					handleConfirm={handleConfirm}
				/>
				
			}
		</div>  
	)
}
export default DealerCarUpdatePriceModal;