import * as React from 'react';
import { Typography , List ,ListItem ,ListItemText ,Checkbox} from '@mui/material';


interface CarOptionListProps {
	data : Array<any>,
	name : 'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8',
	isChecked : (name:'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , value:string) => boolean,
	isCheckedAll : (name:'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , data:Array<any>) => boolean,
	onChange : (name:'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8') => (e:any) => void,
	onChangeAll : (name:'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , data:Array<any>) => () => void
} 

const CarOptionList = ({data , name ,isChecked , isCheckedAll , onChange , onChangeAll}:CarOptionListProps) => {

	const positionStyle = () => {
		return {borderLeft : 1 , borderRight : 1 , borderBottom : 1 , borderTop : 1}
	}

	const headerTitle = () => {
		switch (name) {
			case 'carOption2':
				return '편의사양'
			case 'carOption3':
				return '내장사양'
			case 'carOption4':
				return '외관사양'
			case 'carOption5':
				return '안전사양'
			case 'carOption8':
				return '기타'
		}
	}

	return (
		<>
			<List sx={[{ width: '100%', minWidth : 120} , positionStyle()]}>
				<ListItem
					sx={{borderBottom : 1}}
					secondaryAction={
					<Checkbox
						edge="start"
						onChange={onChangeAll(name , data)}
						checked={isCheckedAll(name , data)}
						//value={'test'}
						/>
					}
				>
					<ListItemText primary={<Typography variant='button'>{headerTitle()}</Typography>} />
				</ListItem>
			{
				data.map(item => (
					<ListItem
						key={item.number}
						secondaryAction={
						<Checkbox
							edge="start"
							onChange={onChange(name)}
							checked={isChecked(name , item.number)}
							value={item.number}
							inputProps={{ 'aria-labelledby': 'test' }}
							/>
						}
					>
						<ListItemText primary={
							<>
								<Typography variant='body2'>
									{item.title}
								</Typography>
							</>
						} />
					</ListItem>
				))
				}
			</List>
		</>
	);
}

export default CarOptionList;