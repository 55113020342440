import * as React from 'react';
import { Grid } from '@mui/material';
import McTypography from 'carManagement/component/McTypography';

interface CarTitleProps {
	title : string
}

const CarTitle = ({title}:CarTitleProps) => {

	return (
		<Grid container columns={{ xs: 2, sm : 4 ,md: 6 }} sx={{mb : 5}}>
		{/* 구매시 가격 , 판매 희망가격 , 판매 증감액 */}
			<Grid item xs={2} sm={1.5} md={1.5} sx={{mt : 0.5}}>
				<McTypography text={title} variant='h4'/>
			</Grid >
			{/* 매매종류 , 할부 가능여부 , 보험이력 공개여부 */}
			<Grid item xs={2} sm={2.5} md={4.5} >
				
					<li>매장 및 차량을 정확히 선택하세요 잘못된 정보일경우 허위차량매물로 삭제될 수 있습니다.</li>
					<li>차량정보는 최대한 상세히 입력하세요. 차량정보가 미비할 경우 판매가 잘 되지 않습니다.</li>
					<li>노출 아이콘을 선택하세요. 목록에서 나의 차량매물이 돋보이게 됩니다.</li>
					<li>아래 제조국가를 먼저 선택하세요. 선택에 따라 제조사 선택항목이 자동 변경됩니다.</li>
				
			</Grid >
		</Grid>
	)
}

export default CarTitle;