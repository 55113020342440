import * as React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import axios from 'axios';

interface TermsFormProps {
	title : string ,
	gubun : number ,
	checkLabel : string
	checked : boolean ,
	handleCheckBox : (title:string) => () => void
}

const TermsForm = ({title ,gubun ,checked , checkLabel , handleCheckBox}:TermsFormProps) => {

	const [contents , setContents] = React.useState('');

	const fetchTerms = async () => {
		try {
			const response = await axios.get(`https://api.meetcha.co.kr/mccarinfo/viewHtml?gubun=${gubun}`);
			if (response.status === 200) {
				setContents(response.data);
			}else {

			}
		} catch (error) {
			
		}
	}

	React.useEffect(() => {
		fetchTerms();
	},[])

	return (
		<Box sx={{mb : 2 , mt : 2 , borderBottom : 1 , borderColor : '#CCCCCC'}}>
			<Typography variant='h6'>
				{title}
			</Typography>
			<Box sx={{minWidth :  350, height : 500 , overflowY :'scroll' , border : 1 , borderColor : '#D7D7D7'}}>
				{
					contents &&
					<div dangerouslySetInnerHTML={{__html : contents}}/>
				}
			</Box>
			<FormControlLabel
				sx={{mt : 1 , minWidth: 120 , display : 'flex' , justifyContent : 'end' }}
				control={
					<Checkbox 
						checked={checked}
						value={title}
						onChange={handleCheckBox(title)}
					/>
				} 
				label={checkLabel} 
			/>
		</Box>  
	)
}
export default TermsForm