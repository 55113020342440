import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import CarRegistration from 'carManagement/main/CarRegistration';
import DealerCarList from 'carManagement/main/DealerCarList';
import { getStorageItem } from 'util/sessionStorage';
import MainListItems from 'route/menuItem/MainListItems';
import DefaultLayout from 'home/DefaultLayout';
import CarRegistRoute from './CarRegistRoute';
import SellMyCarRoute from './SellMyCarRoute';
import UserRoute from './UserRoute';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';
import SmsRoute from './SmsRoute';
import SiteRoute from './SiteRoute';

const drawerWidth: number = 250;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
            //600px 보다 작을 때 아이콘도 노출안되게 처리
            [theme.breakpoints.down('sm')]: {
                width: theme.spacing(0),
            },
        }),
    },
}));

const mdTheme = createTheme();

const MainRoute = () => {
    const { REACT_APP_MODE } = EnvPath;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    // id 값
    const [userId, setUserId] = React.useState<string>(getStorageItem('id') ? getStorageItem('id')! : '');

    const mainRef = React.useRef<any>(null);
    const navigate = useNavigate();

    const handleResize = () => {
        const { innerWidth } = window;
        if (innerWidth < 850) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };
    React.useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // console.log(cookie.getCookie("token"));
    // token 만료 되면 다시 로그인 창으로
    React.useEffect(() => {
        if (!getStorageItem('token')) {
            alert('로그인 시간이 만료되었습니다.');
            navigate(Path.ROOT_PATH);
        }
    }, [sessionStorage.getItem('token')]);

    //   const NaviPath = (path:string) => {
    // 	switch (path) {
    // 		case 'Total':
    // 			return  <TotalList />
    // 		case 'Consult':
    // 			return <Orders />
    // 		case 'CarRegistration':
    // 			return <CarRegistration />
    // 		default:
    // 			break;
    // 	}
    //   }
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            // background: 'linear-gradient(to right bottom, #430089, #82ffa1)'
                            background: 'linear-gradient(to right, #FF436C , #A42BF5)',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <IconButton color="inherit">
                                <div style={{ width: 270, color: '#ffffff' }}>{userId} 님</div>
                            </IconButton>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems isMainDrawerMenuVisible={open} />
                        {/* <Divider sx={{ my: 1 }} />
                        {secondaryListItems} */}
                    </List>
                </Drawer>
                <Box
                    ref={mainRef}
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={26}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Routes>
                                        <Route path="/home" element={<DefaultLayout />} />
                                        <Route path="/car/*" element={<CarRegistRoute mainRef={mainRef} />} />
                                        <Route path="/sellMyCar/*" element={<SellMyCarRoute />} />
                                        <Route path="/user/*" element={<UserRoute />} />
                                        <Route path="/sms/*" element={<SmsRoute />} />
                                        <Route path="/site/*" element={<SiteRoute />} />
                                    </Routes>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default MainRoute;
