import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface SelectItemProps {
	data : Array<any>,
	title : string,
	id : string,
	value : string,
	label : string,
	onChange : (e:any) => void ,
	onClick : (e:any) => void ,
	disabled ?: boolean,
} 

/* Select Item (제조사 , 모델 , 세부모델 , 등급 , 세부등급 ) */
const CategorySelectItem = ({data , title, id, value, label , onChange , onClick , disabled = false}:SelectItemProps) => {

	const menuItemText = (item:any) => {
		switch (id) {
			case 'category1':
				return <MenuItem onClick={onClick} value={item.number} key={item.number} >{item.category_name}</MenuItem>
			case 'category2':
				return <MenuItem onClick={onClick} value={item.category2_number} key={item.category2_number} >{item.category2_name}</MenuItem>
			case 'category3':
				return <MenuItem onClick={onClick} value={item.category_number} key={item.category_number} >{item.category_name}</MenuItem>
			case 'category4':
				return <MenuItem onClick={onClick} value={item.category_number} key={item.category_number} >{item.category_name}</MenuItem>
			case 'category5':
				return <MenuItem onClick={onClick} value={item.category_number} key={item.category_number} >{item.category_name}</MenuItem>
			default:
				break;
		}
	}

	return (
		<FormControl sx={{ m : 1 ,  minWidth: 120 }} disabled={disabled}>
			<InputLabel id="demo-simple-select-label">{title}</InputLabel>
			<Select
				id={id}
				value={value}
				label={label}
				onChange={onChange}
				//required={true}
			>
				{
					data.length ?
					data.map(item => (
						menuItemText(item)
					))
					:
					null
				}
			</Select>
		</FormControl>
	)
}

export default CategorySelectItem;