import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

interface McTextFieldtProps {
	id : string,
	label : string,
	//input창 ui
	variant : "standard" | "filled" | "outlined" | undefined ,
	//onBlur로 처리한 이유는 onChange를 사용하면 한글자 입력할 때마다 렌더링 됨 
	onChange : (name:string) => (e:any) => void,
	value ?: string
	//input창 안에 text 추가할 때 사용
	endAdornment ?: string ,
	// placeholder
	placeholder ?: string,
	size ?: "small" | "medium" | undefined ,
	disabled ? : boolean
} 

/* TextField (광고 문구 , 배기량 , 주행거리 , 제시신고번호 , 차량번호 , 압류 , 저당 , 공인연비) */
const McTextField = ({id , label , variant , onChange , value='', endAdornment='' , placeholder ='' , size , disabled = false}:McTextFieldtProps) => {
	
	return (
		<FormControl sx={{ m : 1 ,  minWidth: 120 }}>
			<TextField 
				defaultValue={value}
				id={id} 
				label={label} 
				variant={variant}
				placeholder={placeholder}
				onChange={onChange(id)}
				InputProps={{
					endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
				}}
				size={size}
				disabled={disabled}
			/>
		</FormControl>
	)
}

export default McTextField;