import React from 'react';

export const Path = {
    ROOT_PATH: '/',
    HOME_PATH: '/home',
    CAR_REGISTRATION_PATH: '/car/carRegistration',
    CAR_DEALER_LIST_PATH: '/car/dealerCarList',
    CAR_GUBUN_PATH: '/car/carGubun',
    CAR_CATEGORY_PATH: '/car/carCategory',
    CAR_CONFIG_PATH: '/car/carConfig',
    CAR_OPTION_PATH: '/car/carOption',
    SELL_MY_CAR_TOTAL_LIST_PATH: '/sellMyCar/totalList',
    SELL_MY_CAR_ORDER_LIST_PATH: '/sellMyCar/orderList',
    USER_LIST_PATH: '/user/userList',
    JOIN_SIGN_UP_PATH: '/join/signUp',
    JOIN_MEMBERSHIP_TERMS_PATH: '/join/terms',
    SITE_BANNER_INTEGRATEDLIST_PATH: '/site/banner/integratedlist',
    USER_TERMS_PATH: '/user/terms',
    SUB_MANAGER_LIST_PATH: '/user/submanager/list',
};
