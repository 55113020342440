import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import IntegratedBannerList from 'siteManagement/banner/IntegratedBannerList';

interface BannerRouteProps {}

const BannerRoute = ({}: BannerRouteProps) => {
    return (
        <Routes>
            <Route path="/integratedlist" element={<IntegratedBannerList />} />
        </Routes>
    );
};
export default BannerRoute;
