import * as React from 'react';
import {useDropzone} from 'react-dropzone';
import { Box, FormControlLabel, ImageList , ImageListItem , Checkbox, Grid} from '@mui/material';
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from 'array-move';
import './carImageInfo/test.css'

import McTypography from 'carManagement/component/McTypography';
import McButton from 'carManagement/component/McButton';
import CarImageListItem from 'carManagement/carRegistration/carImageInfo/CarImageListItem';



interface CarImageInfoProps {
	imageFile : Array<any>,
	setImageFile : any,
	isUpdate : boolean
}

const CarImageInfo = ({imageFile , setImageFile , isUpdate}:CarImageInfoProps) => {

	const [isClick , setIsClick] = React.useState<boolean>(false);

	const [isClickImage , setIsClickImage] = React.useState<boolean>(false);

	//react-dropzone 설정
	const { getRootProps, getInputProps , open } = useDropzone({
		/* 선택 가능한 파일 종류 (jpg , png , gif) */
		accept: {
			'image/jpg': ['.jpg'],
			'image/png': ['.png'],
			'image/gif': ['.gif'],
		},
		//이미지 선택 (Drop) 시 미리보기를 위한 이미지 url 생성 + ImageListCheck 초기화 
		onDrop: (acceptedFiles:any) => {
			setImageListCheck([]);
			console.log(acceptedFiles.length + acceptedFiles.length);
			
			if((imageFile.length + acceptedFiles.length) > 20 ){
				alert('이미지는 최대 20장만 등록할 수 있습니다.');
				return ;
			}else {
				if(imageFile.length){
					const newFile = acceptedFiles.map((file:any) => Object.assign(file, {
						preview: URL.createObjectURL(file)
					}));
					setImageFile(imageFile.concat(newFile));
				}else {
					setImageFile(acceptedFiles.map((file:any) => Object.assign(file, {
						preview: URL.createObjectURL(file)
					})));
				}
			}
			
		},
		noDrag : imageFile.length ? true : false,
		// 최대 20까지 가능
		maxFiles : 20,
		noClick: isClick,
		disabled: isClickImage
	});

	//체크된 이미지 배열
	const [imageListCheck , setImageListCheck] = React.useState<Array<any>>([]);	

	const [updateImage , setUpdateImage] = React.useState<Array<any>>([]);

	//화면비율에 맞는 이미지 크기 및 갯수
	// const handleResize = () => {
	// 	const {innerWidth} = window;

	// 	let col = 5;
	// 	let rowH = 125;

	// 	if(innerWidth > 1050){
	// 		col = 5;
	// 		rowH = 125;
	// 	}else if(innerWidth > 925 && innerWidth < 1050){
	// 		col = 4;
	// 		rowH = 200;
	// 	}else if(innerWidth > 800 && innerWidth < 925){
	// 		col = 3;
	// 		rowH = 300;
	// 	}else if(innerWidth > 675 && innerWidth < 800){
	// 		col = 2;
	// 		rowH = 300;
	// 	}else if (innerWidth > 450 && innerWidth < 675){
	// 		col = 1;
	// 		rowH = 300;
	// 	}else {
	// 		col = 1;
	// 		rowH = 200;
	// 	}

	// 	setCols(col);
	// 	setRowHeight(rowH);
	// }

	//체크박스 선택 이벤트
	const handleCheckBox = (name:string) => () => {
		let dataArr:Array<any> = [...imageListCheck];
		if(imageListCheck.some(item => item === name)){
			setImageListCheck(dataArr.filter(item => item !== name));
		}else {
			dataArr.push(name);
			setImageListCheck(dataArr)
		}
	}

	//이미지 선택삭제 이벤트
	const handleSelectCheckBox =  () => {
		let arr = [...imageFile];
		setImageFile(arr.filter(file => !imageListCheck.includes(file.name)))	
	}

	//이미지 체크박스 boolean
	const isImageListChecked = (name:string) => {
		return imageListCheck.some(item => item === name);
	}
	/**
	 * 이미지 위치 변경 함수
	 * @param oldIndex 
	 * @param newIndex 
	 */
	const handleSortEnd = (oldIndex:number , newIndex:number) => {
		console.log(oldIndex , newIndex);
		
		setImageFile((image:any) => arrayMove(image , oldIndex , newIndex));
	}
	
	//이미지 전체선택 이벤트
	const imageAllCheck = () => {

		let arr:Array<any> = [];

		if(imageListCheck.length !== imageFile.length){
			imageFile.map((item:any) => arr.push(item.name));
		}
		setImageListCheck(arr);
	}
	//이미지가 없을 때 이미지 박스 영역 클릭시 파일 선택창 , 있을 때는 클릭 이벤트 제거
	React.useEffect(() => {
		if(imageFile.length){
			setIsClick(true);
		}else {
			setIsClick(false);
		}
	}, [imageFile]);

	//페이지를 벗어날 때 이미지 미리보기 Url 삭제
	React.useEffect(() => {
	// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => imageFile.forEach((file:any) => URL.revokeObjectURL(file.preview));
	}, []);

	// //화면비율에 따라 이미지리스트의 cols 를 줄여줌  
	// React.useEffect(() => {
	// 	window.addEventListener('resize' , handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize' , handleResize)
	// 	}
	// }, [])

	return (
		<>
			<Box sx={{ width: '100%', minWidth: 180 ,mb : 2}}>
				<McTypography variant='h5' text='차량 사진'/>
			</Box>
			<Box sx={{width: '100%', minWidth : 180 ,  backgroundColor:'#FAFAFA' , border:1}} 
				{ ...getRootProps({className: 'dropzone'})}
				>
				<input {...getInputProps()} />
				{
					imageFile.length ?
						<SortableList
							onSortEnd={handleSortEnd}
							className={'sortList'}
							draggedItemClassName={'imageItem'}
							style={{display : 'flex' , flexDirection : 'row' , flexWrap : 'wrap' , position:'relative'}}
						>
							{
								imageFile.map((file:any , index:number) => (
									<SortableItem key={`${file.name}-${index}`} >
										<div style={{marginLeft : 5 , zIndex : isUpdate ? 1310 : 0 , userSelect : 'none' , cursor : 'grab'}}>
											<div 
												style={{position : 'absolute', margin  :10 , width : 40 , height : 40}}
												onClick={handleCheckBox(file.name)}
											>
												<input 
													type={'checkbox'}
													checked={isImageListChecked(file.name)}
													name={file.name}
													onChange={handleCheckBox(file.name)}
													tabIndex={2}
													style={{width : 15 , height : 15}}
												/>
											</div>
											<img
												style={{width : 200 , height : 150 , margin : 5}}
												src={file.preview}
												alt={file.name}
											/>
										</div>
									</SortableItem>
								))
							}
						</SortableList>
						:
						<Box sx={{height : 200 }}  display="flex" justifyContent="center" alignItems="center" flexDirection="column">
							<McTypography variant='body1' text='본 영역에 파일을 드래그하거나 클릭하여 파일을 선택하세요.'/>
							
							<McTypography variant='body2' text='(최대 20장의 사진업로드가 가능합니다.)'/>
						</Box>
				}
			</Box>
			<Box sx={{ width: '100%', minWidth : 120 }}>
				<McButton variant='contained' title='파일추가' color='primary'disabled={imageFile.length > 19} onClick={open}/>
				<McButton variant='outlined' title='선택삭제' color='primary' disabled={Boolean(!imageListCheck.length)} onClick={handleSelectCheckBox}/>
				<FormControlLabel
					sx={{mt : 1 , mb : 1 , minWidth: 120}}
					control={
						<Checkbox 
							checked={imageListCheck.length === imageFile.length}
							onChange={imageAllCheck}
							disabled={Boolean(!imageFile.length)}
						/>
					} 
					label={'전체선택'}
				/>
			</Box>
		</>
	)
}
export default CarImageInfo;

