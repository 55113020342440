import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { yellow , green , grey } from '@mui/material/colors';
import Title from '../home/Title';
import axios from 'axios';
//import * as cookie from "../util/cookie";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import AlertDialog  from '../home/AlertDialog';
import {useNavigate} from "react-router-dom";
import { getStorageItem } from 'util/sessionStorage';
import { customAxios } from 'axios/customAxios';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';

interface SellCar {
  seq : number,
  carModelName : string,
  sellerPhone : string,
  sellerArea : string,
  sellerName : string,
  carYear : string,
  carNumber : string,
  orderSttus : string,
  orderSttusTxt : string,
  orderEtc : string,
  attachNum : number,
  delYn : string,
  writeId : string,
  writeDate : number,
  modifyId : string,
  modifyDate : number
}

// Generate Order Data
function createData(
  id: number,
  date: string,
  name: string,
  shipTo: string,
  paymentMethod: string,
  amount: number,
) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Orders() {

  const [sellCarList , setSellCarList] = React.useState<Array<SellCar>>([]);
  const [page , setPage] = React.useState<number>(1);
  const [pageSize , setPageSize] = React.useState<number>(12);
  const [totCount , setTotCount] = React.useState<number>(0);

  const [open , setOpen] = React.useState<boolean>(false);
  const [title , setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');

  const [seq , setSeq] = React.useState<number>();
  const navigate = useNavigate();

//   const config = {
//     headers: {
//         'Authorization':`Bearer ${getStorageItem("token")}`,
//     },
// } 

const handleClose = () => {
  setOpen(false);
};

const handleAccept = () => {
  
  if(title === '상담취소') {
    updateOwnSellCar("99");
    setOpen(false);
  } else {
    updateOwnSellCar("05");
    setOpen(false);
  } 
}

const consultCancel = (seq : number) => {
  setSeq(seq);
  setTitle('상담취소');
  setDescription('상담을 취소하시겠습니까?');
  setOpen(true);
  
}

const consultSuccess = (seq : number) => {
  setSeq(seq);
  setTitle('상담완료');
  setDescription('상담을 완료하시겠습니까?');
  setOpen(true);
}

const timeStampToDate = (time : number) => {
  let date = new Date(time);
    //`${date.getFullYear()}년 ${date.getMonth()+1}월 ${date.getDate()}일`;
  return date.toISOString().substring(0,19).replace(`T`, ` `);
}

const getSttusComponent = (sttus : string , sttusTxt : string) => {

  switch(sttus) {
      case "01" : 
         return (
          <Avatar sx={{ width : 45 , height : 45, bgcolor: yellow[600] , fontSize : 9 , textAlign: 'center' }} aria-label="recipe">
              {sttusTxt}
          </Avatar>
         )
      case "03" :
         return (
          <Avatar sx={{ width : 45 , height : 45, bgcolor: green[600] , fontSize : 10 , textAlign: 'center'}} aria-label="recipe">
              {sttusTxt}
          </Avatar>
          )
      case "05" :
         return(
          <Avatar sx={{ width : 45 , height : 45, bgcolor: green[600] , fontSize : 10 , textAlign: 'center'}} aria-label="recipe">
              {sttusTxt}
          </Avatar>   
         ) 
      default :
          return (
           <Avatar sx={{width : 45 , height : 45, bgcolor: grey[600] , fontSize : 9 , textAlign: 'center'}} aria-label="recipe">
              {sttusTxt}
           </Avatar>
          )
  }
}

const getConsultIcon = (sttus : string , seq : number) => {
  if(sttus === "03") {
      return (
        <>
          <IconButton aria-label="settings" onClick={() => {consultSuccess(seq)}}>
              <CallEndIcon/>
          </IconButton>
          <IconButton aria-label="settings" onClick={() => {consultCancel(seq)}}>
              <CloseIcon/>
          </IconButton>
        </>
      )
  }
}

const returnTitle = (title : string) => {
  return "모델명 : " + title;
}  

const settingSeq = (seq : string) => {
  let newSeq = ""  

  for (let i = 0; i < 10-seq.length; i++) {
      newSeq += "0"
  }
  
  return "접수번호 : " + newSeq+seq;
}

const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  setPage(value);
};

const getOwnSellCarList = async () => {
    try {
      // 리스트를 불러오는 건 따로 token 체크 할 필요가 없다.
      // Drawer layout 에서 클릭 시 Home 에서 체크 하기 때문이다.
      if(getStorageItem("token") !== undefined) {

      	const response = await customAxios.get(`/meetcha/sell/consult/own?pageNo=${page}&pageSize=${pageSize}`);

      if(response.status === 200) {
        if(response.data.apiinfo.status === "00") {
          setSellCarList(response.data.data);       
          setTotCount(response.data.apiinfo.totalcnt);
          
        } else {
          alert(response.data.apiinfo.message);
        }
      } else {
        alert(response.data.apiinfo.message);
      }
     
     } 
    } catch(error) {
      alert(error);
    }
  }

  const updateOwnSellCar = async(state : string) => {
    try {

      if(getStorageItem("token") !== undefined) {

      	const response = await customAxios.post(`/meetcha/sell/consult/update?seq=${seq}&state=${state}`);

      if(response.status === 200) {
        if(response.data.apiinfo.status === "00") {
           getOwnSellCarList();
          } else {
            alert(`에러 : ${response.data.apiinfo.message}`);
          }
        } else {
            alert(`에러 : ${response.data.apiinfo.message}`);
          }  

      } else {
        alert("로그인 시간이 만료되었습니다.");
        navigate(Path.ROOT_PATH);
     }

    } catch(error) {
      alert(error);
    }
  }
  
  React.useEffect(() => {
    getOwnSellCarList();
  } , [page]);

  return (
    <React.Fragment>
      <Title>상담중</Title>
            <Grid container spacing={3}>
                { sellCarList.length > 0 ?
                    sellCarList.map((myCar) => (
                     <Grid item xs ={8} sm = {6} md ={4} key ={myCar.seq}> 
                        <Card sx = {{minWidth : 250 , maxWidth : 320 , minHeight : 200}}>
                            <CardHeader
                                    avatar={
                                        getSttusComponent(myCar.orderSttus,myCar.orderSttusTxt)
                                    }
                                    action={
                                        getConsultIcon(myCar.orderSttus,myCar.seq)
                                    }
                                    title={returnTitle(myCar.carModelName)}
                                    subheader={settingSeq(myCar.seq+"")}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary" gutterBottom={true} align ="justify" >
                                        연락처 : {myCar.sellerPhone}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" align ="justify">
                                        신청일 : {timeStampToDate(myCar.writeDate)}
                                    </Typography>  
                                </CardContent>  
                        </Card>
                     </Grid>   
                    ))
                    :
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{minHeight : '30vh'}}>                
                      <Typography variant="body2" color="text.secondary" gutterBottom={true} alignItems={'center'} justifyContent={'center'} display={"flex"}  flexDirection= {"column"}>
                                    목록이 없습니다.
                      </Typography>       
                    </Grid>
                    
                }
            </Grid>
      <Stack spacing = {2} style= {{alignItems : 'center' , padding : 30}} >
         <Pagination count = {Math.floor(totCount / pageSize) + 1} defaultPage={1} onChange = {handleChange} color="secondary" variant = "outlined" showFirstButton showLastButton />   
      </Stack>

      {
      open ? 
      <AlertDialog handleClose={handleClose} handleAccept = {handleAccept} title = {title} description={description}/>
      :
      null
    }      

    </React.Fragment>
  );
}