import * as React from 'react'
import { Grid,Box,FormControlLabel,Checkbox} from '@mui/material';

import CarOptionList from 'carManagement/carRegistration/carOptionInfo/CarOptionList';
import McTypography from 'carManagement/component/McTypography';


interface SelectOption {
	carOption2 :Array<any>,
	carOption3 :Array<any>,
	carOption4 :Array<any>,
	carOption5 :Array<any>,
	carOption8 :Array<any>
}
interface CarOptionProps {
	carOption : any,
	selectOption :SelectOption,
	setSelectOption :any
}

/* 차량 옵션정보 */
const CarOptionInfo = ({carOption , selectOption, setSelectOption}:CarOptionProps) => {

	//api data
	const {car_option2 } = carOption[0];
	const {car_option3 } = carOption[1];
	const {car_option4 } = carOption[2];
	const {car_option5 } = carOption[3];
	const {car_option8 } = carOption[4];

	const compareCheckList = 
		(selectOption.carOption2.length === car_option2.length) && 
		(selectOption.carOption3.length === car_option3.length) && 
		(selectOption.carOption4.length === car_option4.length) && 
		(selectOption.carOption5.length === car_option5.length) && 
		(selectOption.carOption8.length === car_option8.length)
	
	/* check 여부 */
	const isChecked = (name :'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , value:string) => {
		return selectOption[name].some((item:any) => item === value);
	}
	/* subMenu 전체 check 여부 */
	const isCheckedSubMenu = (name :'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , data:Array<any>) => {
		return data.length === selectOption[name].length;
	}
	/* check event */
	const handleSelectOptionCheck = (name :'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8') => (e:any) => {
		let dataArr = [...selectOption[name]];
		
		if(dataArr.some(item => item === e.target.value)){
			setSelectOption({
				...selectOption ,
				[name] : dataArr.filter(item => item !== e.target.value)
			})
		}else {
			dataArr.push(e.target.value);
			setSelectOption({
				...selectOption ,
				[name] : dataArr
			})
		}
	}

	/* submenu 전체 check event */
	const handleSubMenuCheck = (name :'carOption2' | 'carOption3' | 'carOption4' | 'carOption5' | 'carOption8' , data:Array<any>) => () => {
		let dataArr:Array<any> = [];

		if(data.length !== selectOption[name].length){
			data.map(item => {
				dataArr.push(item.number)
			})
		}

		setSelectOption({
			...selectOption ,
			[name] : dataArr
		})	
	}

	/* 모든 옵션 체크 */
	const handleAllCheck = () => {
		let category = ['carOption2' , 'carOption3' , 'carOption4' , 'carOption5' , 'carOption8']
		let dataArr:any = {
			carOption2 : [],
			carOption3 : [],
			carOption4 : [],
			carOption5 : [],
			carOption8 : [],
		};
		
		if(!compareCheckList){
			category.map(category => {
				switch (category) {
					case 'carOption2':
						car_option2.map((item:any) => dataArr[category].push(item.number));
						break;
					case 'carOption3':
						car_option3.map((item:any) => dataArr[category].push(item.number));
						break;
					case 'carOption4':
						car_option4.map((item:any) => dataArr[category].push(item.number));
						break;
					case 'carOption5':
						car_option5.map((item:any) => dataArr[category].push(item.number));
						break;
					case 'carOption8':
						car_option8.map((item:any) => dataArr[category].push(item.number));
						break;
				}
			});
		}		
		setSelectOption(dataArr);
	}

	return (
		<>
			<Box sx={{ width: '100%', maxWidth: 180 ,mb : 2}}>
				<McTypography variant='h5' text='차량 옵션정보'/>
				<FormControlLabel
					sx={{mt : 1 , mb : 1 , minWidth: 120}}
					control={
						<Checkbox 
							checked={compareCheckList}
							onChange={handleAllCheck}
						/>
					} 
					label={'전체선택'}
				/>
			</Box>
			{/* <Box sx={{width: '100%', minWidth : 120 , display: 'grid' , gridTemplateColumns: 'repeat(5, 1fr)'}} > */}
			<Grid container spacing={{ xs: 2, sm: 1, md: 1 }} columns={{ xs: 5, sm : 10 ,md: 15 }} >
				<Grid item xs={5} sm={5} md={3}>
					<CarOptionList data={car_option4} name={'carOption4'} isChecked={isChecked} isCheckedAll={isCheckedSubMenu} onChange={handleSelectOptionCheck} onChangeAll={handleSubMenuCheck}/>
				</Grid >
				<Grid item xs={5} sm={5} md={3}>
					<CarOptionList data={car_option3} name={'carOption3'} isChecked={isChecked} isCheckedAll={isCheckedSubMenu} onChange={handleSelectOptionCheck} onChangeAll={handleSubMenuCheck}/>
				</Grid >
				<Grid item xs={5} sm={5} md={3}>
					<CarOptionList data={car_option5} name={'carOption5'} isChecked={isChecked} isCheckedAll={isCheckedSubMenu} onChange={handleSelectOptionCheck} onChangeAll={handleSubMenuCheck}/>
				</Grid >
				<Grid item xs={5} sm={5} md={3}>
					<CarOptionList data={car_option2} name={'carOption2'} isChecked={isChecked} isCheckedAll={isCheckedSubMenu} onChange={handleSelectOptionCheck} onChangeAll={handleSubMenuCheck}/>
				</Grid >
				<Grid item xs={5} sm={5} md={3}>
					<CarOptionList data={car_option8} name={'carOption8'} isChecked={isChecked} isCheckedAll={isCheckedSubMenu} onChange={handleSelectOptionCheck} onChangeAll={handleSubMenuCheck}/>
				</Grid >
			</Grid>
		</>
	)
}

export default CarOptionInfo;