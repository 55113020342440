import * as React from 'react';

import { Box , FormControlLabel, Radio, Table ,TableBody ,TableCell ,TableHead ,TableRow , Typography} from '@mui/material';
import McTypography from 'carManagement/component/McTypography';

interface CarPaymentOptionPriceProps {
	data : any,
	paymentData : any
	onChange : (name:string) => (e:any) => void
}

const CarPaymentOptionPrice = ({data , paymentData ,onChange}:CarPaymentOptionPriceProps) => {
	
	const name = data.optTitle === '매물별딜러노출' ? 'exposurePrice' : 'popularityPrice';
	
	const checked = (name:string , number:string) => {
		if(name === 'exposurePrice'){
			return  paymentData.exposurePrice === number;
		}else {
			return  paymentData.popularityPrice === number;
		}
	}

	const randomColor = () => {
		return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
	}

	// const effectOptionText = (name:string) => {
		
	// 	if(name === 'exposurePrice'){
	// 		return <Typography variant='caption' style={{color:randomColor()}}>{data.effectOption}</Typography> 
	// 	}else {
	// 		return <Typography variant='caption' style={{color:'#6F6F6F'}}>{data.effectOption}<img src='http://dev.meetcha.co.kr/upload/pay/1380698567-8.gif' alt='icon' /></Typography> 
	// 	}
	// }


	return (
		<Box sx={{ minWidth : 100}}>
			{/* 타이틀 */}
			<Box sx={{mb : 2}}>
				<McTypography text={data.optTitle} variant='body1' />
			</Box>
			{/* 테이블 */}
			<Box>
			<Table sx={{ minWidth: 180 }} aria-label="simple table">
				<TableHead>
					<TableRow style={{backgroundColor : '#F9F9F9'}}>
						<TableCell>기간</TableCell>
						<TableCell align="center">금액설정</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{data.happyPayDetailList.map((row:any) => (
					<TableRow
						key={`${row.number}`}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
					>
						<TableCell align="left" >
							{`${row.opt1}일`}
						</TableCell>
						<TableCell align="center">
							<FormControlLabel 
								key={row.number} 
								value={row.number} 
								onClick={onChange(name)} 
								checked={checked(name , row.number.toString())}
								control={<Radio size='small' />} 
								label={''} 
							/>
							{row.optPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
						</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
			</Box>
			{/* 효과 */}
			{/* <Box sx={{backgroundColor : '#F9F9F9' , border : 0.5 , borderColor : '#FFFFFF'  , p : 2}}>
				<Box sx={{mb : 1 }}>
					<Typography variant='caption'>{`이펙트 제목 `}</Typography>
					<Typography variant='caption' style={{color:'#6F6F6F'}}>{data.effectName}</Typography>
				</Box>
				<Box sx={{mt : 1}}>
					<Typography variant='caption'>{`이펙트 효과 `}</Typography>
					{
						effectOptionText(name)
					}
				</Box>
			</Box> */}
		</Box>
	)

}

export default CarPaymentOptionPrice;