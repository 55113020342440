import * as React from 'react';
import Title from 'home/Title';
import styled from 'styled-components';
import { SubManagerInfo } from './SubManagerList';
import { customAxios, testAxios } from 'axios/customAxios';
import { InitWrap } from 'siteManagement/styled/SiteManagementStyled';
import { Modal, Box, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Button } from '@mui/material';

interface ModifySubManagerModalProps {
    info: SubManagerInfo | undefined;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: () => void;
}

interface ModifySubManagerState {
    number: number;
    id: string;
    pass: string;
    name: string;
    phone: string;
}

interface ToolTipProps {
    tip: string;
    required?: boolean;
}

const ToolTip = ({ tip, required }: ToolTipProps) => {
    return (
        <div
            style={{
                fontSize: 11,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgb(247, 244, 244)',
                padding: '10px 10px',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    fontSize: 1,
                    color: '#FFFFFF',
                    padding: '4px 7px 2px 7px',
                    marginRight: 10,
                    backgroundColor: '#9ab341e6',
                    borderRadius: 4,
                }}
            >
                짧은설명
            </div>
            <div style={{ color: '#989898', marginRight: 10 }}>{tip}</div>
            {required && <div style={{ color: '#ff0000', marginRight: 10, fontWeight: 'bold' }}>*필수</div>}
        </div>
    );
};

const ModifySubManagerModal = ({ info, openModal, setOpenModal, refresh }: ModifySubManagerModalProps) => {
    const passInputRef = React.useRef<HTMLInputElement>(null);
    const nameInputRef = React.useRef<HTMLInputElement>(null);
    const [modifySubManagerState, setModifySubManagerState] = React.useState<ModifySubManagerState>({
        number: 0,
        id: '',
        pass: '',
        name: '',
        phone: '',
    });
    const { id, pass, name, phone } = modifySubManagerState;

    React.useEffect(() => {
        if (info) {
            setModifySubManagerState({
                ...modifySubManagerState,
                number: info.number,
                id: info.id,
                pass: info.pass,
                name: info.name,
                phone: info.phone,
            });
        }
        return () => {};
    }, []);

    const handleModifySubManagerOnChange = (e: any, type: string) => {
        setModifySubManagerState({ ...modifySubManagerState, [type]: e.target.value });
    };

    const onModalClose = () => setOpenModal(false);

    const modifySubManagerValidationCheck = () => {
        if (pass.trim() === '') {
            alert('패스워드를 입력해주세요.');
            passInputRef.current?.focus();
            return false;
        } else if (name.trim() === '') {
            alert('이름을 입력해주세요.');
            nameInputRef.current?.focus();
            return false;
        }
        return true;
    };

    const modifySubManager = async () => {
        if (!modifySubManagerValidationCheck()) return;

        const config = { headers: { 'Content-Type': 'application/json' } };
        const jsonData = JSON.stringify({ ...modifySubManagerState, process: 'MODIFY' });

        try {
            const response = await customAxios.post('/meetcha/manager/user/modify/admin/member', jsonData, config);
            if (response.status === 200 && response.data === 'success') {
                refresh();
                alert('부관리자 수정 성공');
                onModalClose();
            } else {
                alert('부관리자 수정 실패');
            }
        } catch (error) {
            alert('부관리자 수정 실패');
        }
    };

    return (
        <Modal open={openModal} onClose={onModalClose} sx={{ zIndex: 1300 }}>
            <InitWrap>
                <Box sx={style}>
                    <Title>부관리자 수정</Title>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            아이디
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'아이디를 입력하세요(4자 이상 170자 이하)'} required />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="text"
                                                    readOnly
                                                    isVisible
                                                    maxLength={170}
                                                    widthSize="350px"
                                                    value={id}
                                                    onChange={(e: any) => handleModifySubManagerOnChange(e, 'id')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            패스워드
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'비밀번호를입력하세요(13자 이하)'} required />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    ref={passInputRef}
                                                    type="text"
                                                    maxLength={13}
                                                    widthSize="350px"
                                                    value={pass}
                                                    onChange={(e: any) => handleModifySubManagerOnChange(e, 'pass')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            이름
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'부관리자의 이름을 입력하세요(13자 이하)'} required />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    ref={nameInputRef}
                                                    type="text"
                                                    maxLength={13}
                                                    widthSize="350px"
                                                    value={name}
                                                    onChange={(e: any) => handleModifySubManagerOnChange(e, 'name')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                width: 200,
                                                fontWeight: 'bold',
                                                backgroundColor: 'rgb(238, 238, 238)',
                                                textAlign: 'center',
                                            }}
                                        >
                                            연락처
                                        </TableCell>
                                        <TableCell>
                                            <ToolTip tip={'부관리자의 연락처를 입력하세요(13자 이하)'} />
                                            <div style={{ marginTop: 20, display: 'flex' }}>
                                                <CustomInput
                                                    type="text"
                                                    maxLength={13}
                                                    widthSize="350px"
                                                    value={phone}
                                                    onChange={(e: any) => handleModifySubManagerOnChange(e, 'phone')}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px 0',
                        }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="warning"
                            style={{ marginRight: 50 }}
                            onClick={modifySubManager}
                        >
                            수정
                        </Button>
                        <Button variant="contained" size="large" color="error" onClick={onModalClose}>
                            취소
                        </Button>
                    </div>
                </Box>
            </InitWrap>
        </Modal>
    );
};

export default ModifySubManagerModal;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 1300,
    width: '80%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    overflow: 'scroll',
    boxShadow: 24,
    p: 4,
};

const CustomRadio = styled.input`
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 15px;
    height: 15px;
    margin-right: 5px;
`;

const CustomSpan = styled.span`
    color: #000000;
    text-align: center;
    font-size: 13.5px;
    line-height: 0;
`;

const CustomInput = styled.input.attrs(
    (props: { widthSize: string; minWidthSize: string; isVisible: boolean }) => props
)`
    height: 26.5px;
    width: ${(props) => props.widthSize};
    min-width: ${(props) => props.minWidthSize};
    margin-right: 10px;
    border: 1px solid #bdbdc0;
    background: #f3f3f3;
    :focus {
        outline: ${(props) => (props.isVisible ? 'none' : undefined)};
    }
`;

const CustomSelectBox = styled.select`
    height: 26.5px;
`;
const CustomOption = styled.option``;
