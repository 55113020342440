import * as React from 'react'
import { Box , Checkbox, FormControlLabel , Grid} from '@mui/material';
import McTypography from 'carManagement/component/McTypography';
import CarPriceGroup from 'carManagement/carRegistration/carPriceInfo/CarPriceGroup';
import CarPriceOptionGroup from 'carManagement/carRegistration/carPriceInfo/CarPriceOptionGroup';

interface CarPriceInfoProps {
	carInfoData : any,
	handleCarInfoData : (name:string) => (e:any) => void,
	handleInputText : (name:string) => (e:any) => void
}

/* 판매가격 정보 */
const CarPriceInfo = ({carInfoData ,handleCarInfoData ,handleInputText }:CarPriceInfoProps) => {

	// const [isChecked , setIsChecked] = React.useState<boolean>(false);

	return (
		<>
			<Box sx={{ width: '100%', minWidth: 180 ,mb : 2 , borderBottom : 1}}>
					<McTypography variant='h5' text='판매가격 정보'/>
					{/* <FormControlLabel
						sx={{mt : 1 , mb : 1 , minWidth: 120}}
						control={
							<Checkbox 
								checked={isChecked}
								onChange={() => setIsChecked(!isChecked)}
							/>
						} 
					label={'가격상담시 체크 (판매가격을 입력하지 않고 상담만으로 진행하실 경우 선택하세요.)'}
					/> */}
			</Box>
			<Grid container spacing={{ xs: 2, sm: 2, md: 2 }} columns={{ xs: 2, sm : 4 ,md: 6 }}>
				{/* 구매시 가격 , 판매 희망가격 , 판매 증감액 */}
				<Grid item xs={2} sm={2} md={3}>
					<CarPriceGroup carInfoData={carInfoData} handleInputText={handleInputText}/>
				</Grid >
				{/* 매매종류 , 할부 가능여부 , 보험이력 공개여부 */}
				<Grid item xs={2} sm={2} md={3} sx={{backgroundColor:'#F9F9F9'}}>
					<CarPriceOptionGroup carInfoData ={carInfoData} handleCarInfoData={handleCarInfoData}/>
				</Grid >
			</Grid>
		</>
	)
}

export default CarPriceInfo;