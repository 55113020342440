import * as React from 'react'
import { Route, Routes } from 'react-router-dom'
import ManageList from 'sms/main/ManageList'
import ManageForm from 'sms/main/ManageForm'
import SmsSendList from 'sms/main/SmsSendList'

interface SmsRouteProps {

}
const SmsRoute = () => {
  return (
	<Routes>                  
		<Route path="/manageList" element ={<ManageList />}/>
		<Route path="/manageForm" element ={<ManageForm />}/>
		<Route path="/smsSendList" element ={<SmsSendList />}/>
	</Routes>    
  )
}
export default SmsRoute