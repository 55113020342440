import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

interface RadioButtonProps {
	onChange : (name:string) => (e:any) => void,
	value : string,
	name : string
} 

/* 국산차 , 수입차 RadioGroup 처리 */
const CarCountryRadioGroup = ({onChange ,value ,name}:RadioButtonProps) => {
	return (
		<FormControl sx={{m:1}}>
			<RadioGroup
				row
				name={name}
				value={value}
				onChange={onChange(name)}
			>
				<FormControlLabel value={0} control={<Radio />} label="국산차" />
				<FormControlLabel value={1} control={<Radio />} label="수입차" />
			</RadioGroup>
		</FormControl>
	)
}

export default CarCountryRadioGroup;