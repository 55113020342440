import React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useDrag, useDrop } from 'react-dnd';
import { DragDiv, DropDiv, IconBtn, Input } from 'carManagement/style/DragStyle';

interface CarConfigItemProps {
    info: {
        typeName: string; //typeName을 지정해줘야 서로 drag&drop이 가능함
        name: string; //value
        index: number; // index - 순서를 위해 사용
    };
    isUpdate: boolean;
    handleConfigDataInValue: (index: number, value: string) => void;
    handleDrop: (startName: string, toIndex: number) => void;
    handleShowDialog: (value: string) => void;
    handleRemoveItem: (value: string) => void;
}

const CarConfigItem = React.memo(
    ({
        info,
        isUpdate,
        handleConfigDataInValue,
        handleDrop,
        handleShowDialog,
        handleRemoveItem,
    }: CarConfigItemProps) => {
        const { typeName, name, index } = info;
        const isReverse = React.useRef(false);
        const [{ isDragging }, dragRef, previewRef] = useDrag(
            () => ({
                type: typeName,
                item: { name, index },
                collect: (monitor) => ({
                    isDragging: monitor.isDragging(), //isDragging 변수가 현재 드래깅중인지 아닌지를 리턴해주는 부분.
                }),
                end: (item: any, monitor) => {
                    const didDrop = monitor.didDrop();
                    if (!didDrop && item.index !== index) {
                        handleDrop(item.name, item.index);
                        //api 호출
                        //handleDragEnd();
                    }
                },
            }),
            [name, index, isUpdate]
        );
        const [{ isOver }, dropRef] = useDrop(
            () => ({
                accept: typeName,
                canDrop: () => false,
                collect: (monitor) => ({ isOver: monitor.isOver() }),
                hover(item: any, monitor) {
                    /* 
						마우스를 빠르게 드래그 할 때 , 배열값이 바뀌는 걸 ui 가 못따라가거나 hover event에서 캐치를 못해서 배열 순서가 꼬이는 이슈가 있음
						-> hover event에서 index 값만 변경해주고 dragEnd 이벤트에서 배열값 변경시켜주는 방식으로 수정 
					*/
                    if (item.index !== index) {
                        isReverse.current = Boolean(item.index < index);
                        item.index = index;
                    }
                },
            }),
            [handleDrop, index]
        );

        // const carColorEndAdornment = () => {
        //     return (
        //         <Box
        //             sx={{
        //                 width: 10,
        //                 height: 10,
        //                 backgroundColor: name.indexOf(':') > -1 ? name.split(':')[1] : '#FFFFFF',
        //             }}
        //         />
        //     );
        // };
        //id={`configDrag_${name}`}

        const borderStyle: any = {
            //borderTopColor: isOver && !isReverse.current ? 'red' : '#f3f3f3',
            //borderBottomColor: isOver && isReverse.current ? 'red' : '#f3f3f3',
            opacity: isDragging ? 0 : 1,
        };

        return (
            <div ref={previewRef}>
                <DropDiv ref={dropRef} style={borderStyle}>
                    <DragDiv ref={dragRef} isUpdate={!isUpdate}>
                        {isUpdate ? (
                            <Input
                                defaultValue={name}
                                onBlur={(e) => {
                                    handleConfigDataInValue(index, e.target.value);
                                }}
                            />
                        ) : (
                            <span>{name}</span>
                        )}
                        <IconBtn onClick={() => handleShowDialog(name)}>
                            <RemoveCircleOutlineIcon color="error" />
                        </IconBtn>
                    </DragDiv>
                </DropDiv>
            </div>
        );
    }
);

export default CarConfigItem;
