import * as React from 'react'

import {decode} from 'html-entities'
import { Modal , Box, styled, Backdrop, CircularProgress } from '@mui/material'

import {CarInfoData, SelectOption} from 'interface/CarRegistrationInterface'
import {carData} from 'interface/CarListInterface'
import CarRegistration from 'carManagement/main/CarRegistration'
import { EnvPath } from 'util/path/EnvPath';

interface DealerCarDetailModalProps {
	data : carData,
	onClose : () => void
}

const DealerCarDetailModal = ({data , onClose}:DealerCarDetailModalProps) => {

	const {REACT_APP_CAR_IMG_DOMAIN_URL} = EnvPath ;

	const [carInfo , setCarInfo] = React.useState<any>();
	const [carOption , setCarOption] = React.useState<SelectOption>({
		carOption2 : [],
		carOption3 : [],
		carOption4 : [], 
		carOption5 : [],
		carOption8 : [],
	});
	const [imageFile , setImageFile] = React.useState<Array<any>>([]);

	const [isUpdate , setIsUpdate] = React.useState<Boolean>(false);
	
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: window.innerWidth * 0.8,
		height :  window.innerHeight * 0.8,
		bgcolor: 'background.paper',
		overflow :'scroll',
		//border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}

	const handleCarInfoData = () => {		
		const info:CarInfoData = {
			number : data.number,
			addPrice: data.add_price ? data.add_price : '0',
			phone1: data.phone1,
			phone2: data.phone2,
			email: data.email,
			si: data.si,
			gu: data.gu,
			dong: data.dong,
			group1: data.group1,
			tone: data.tone,
			fuel: data.fuel,
			gas: data.gas,
			mileage: data.mileage ? data.mileage.replace(',' , '') : '' ,
			atmt: data.atmt,
			sago: data.sago,
			quota: data.quota,
			price: data.price.replace(',' , ''),
			saleEnd: data.sale_end,
			title: data.title,
			gouNumber: data.gou_number,
			etc1: data.etc1,
			etc2: data.etc2,
			oldPrice: data.old_price,
			addr: data.addr,
			sangho: data.sangho,
			carNumber: data.car_number,
			carname: data.carname,
			apryo: data.apryo,
			jeodang: data.jeodang,
			apJeoCheck: data.ap_jeo_check,
			category1: data.category1,
			category2: data.category2,
			category3: data.category3,
			category4: data.category4,
			category5: data.category5,
			typeNation: data.type_nation,
			carhistory: data.carhistory,
			officialMileage: data.official_mileage,
			fuelGrade: data.fuel_grade ? data.fuel_grade : '',
			prfomncChckUrl: data.prfomnc_chck_url,
			saleType: data.sale_type,
			id: data.id,
			userNick: '',
			phone3: '',
			siName: data.si,
			fullAddr: '',
			year: data.ym.split('년')[0],
			month: data.ym.split('년')[1].replace('월' , ''),
			ym: '',
			main: decodeHtmlEntity(data.main),
			//main : `<![CDATA[&lt;p&gt;보증금 : 500,000원 * 보증금은 계약만료시 차량에 이상이 없는 경우 돌려받는 금액 입니다. &lt;/p&gt;&lt;p&gt;1. 계약기간 - 1년 &lt;/p&gt;&lt;p&gt;2. 약정운행거리 - 30,000km/년 (확대가능 최대 50,000km까지: 10,000km당 30만원) &lt;/p&gt;&lt;p&gt;3. 출고비용 - 보증금+1개월선불(월기본료+월보험료) &lt;/p&gt;&lt;p&gt;4. 보험 - (계약자: 회사 / 피보험자: 고객)으로 회사가 산출 - 피보험자 보험경력인정 - 조건: 대물 10억 / 사망 2억 / 부상 5천만원 &lt;/p&gt;&lt;p&gt;5. 중도해지위약금 - (월기본료+월보험료)x잔여회차x0.3 &lt;/p&gt;&lt;p&gt;6. 서비스 - 계약기간 내 엔진, 미션 보증수리&lt;/p&gt;&lt;p&gt;7. 서비스제외 - 배터리, 라이트류, 엔진오일 및 엔진, 미션 제외 기타수리 고객부담&lt;/p&gt;&lt;p&gt;&lt;br class=\"ProseMirror-trailingBreak\"&gt;&lt;/p&gt;]]>`,
			homeServiceYn: '',
			showYn: '',
			imageFiles: undefined,
			carOption1: [],
			carOption2: [],
			carOption3: [],
			carOption4: [],
			carOption5: [],
			carOption6: [],
			carOption7: [],
			carOption8: [],
			carJagaImg: undefined,
			carJagaImgUse: "0",
			mainFlashImg: '',
			keyword: ''
		}

		return info ;
	}
	

	const decodeHtmlEntity = (main : string) => {
		const htmlCode = decode(main,{level : 'html5'}).replace('<![CDATA[' , '');
		return htmlCode.slice(0 , htmlCode.length-3);
	}

	const convertURLtoFile = async (url: string , name:string , index:number) => {
		const response = await fetch(url);
		const data = await response.blob();
		
		let imageType 
		if(url.includes('png')){
			imageType = 'png';
		}else if(url.includes('jpeg')){
			imageType = 'jpeg';
		}else {
			imageType = 'jpg';
		}

		const filename = name.includes('oname=') ? name.split('oname=')[1] : name; // url 구조에 맞게 수정할 것
		const metadata = { type: `image/${imageType}`};

		return Object.assign(new File([data], filename!, metadata) , {preview : url , index : index});
	};

	const handleImageFile = () => {
		const fileArr:Array<any> = [];
		const carImageArr = [
			data.img1 , data.img2 ,data.img3 ,data.img4 ,data.img5 ,
			data.img6 ,data.img7 ,data.img8 ,data.img9 ,data.img10 ,
			data.img11 ,data.img12 ,data.img13 ,data.img14 ,data.img15 ,
			data.img16 ,data.img17 ,data.img18 ,data.img19 ,data.img20
		].filter(item => item !== '');

		if(!carImageArr.length){
			setIsUpdate(true);
		}else {
			carImageArr.map((item , index) => {
				const url = item.includes(REACT_APP_CAR_IMG_DOMAIN_URL!) ? `${item}&w=270` : item;
				/* 미리보기를 위한 preview와 file 형태로 가공 */
				convertURLtoFile(url , item , index).then(file => {
					if(file){
						fileArr.push(file);
					}
					if(carImageArr.length === fileArr.length){
						/* 순서정렬 (index 값으로 비교) */
						// fileArr.forEach(i => console.log(i.name, '<before'));
						// fileArr.sort((a , b) => (a.index > b.index) ? 1 : -1);
						// fileArr.forEach(i => console.log(i.name, '<after'));
						
						setImageFile(fileArr.sort((a , b) => (a.index > b.index) ? 1 : -1));
						setIsUpdate(true);
					}
				});
			})
		}
	}
	//	^\\d{4}/\\d{2}/\\d{2}/\\\S+$
	React.useEffect(() => {		
		setCarInfo(handleCarInfoData());
		setCarOption({
			...carOption ,
			carOption2 : data.car_option2 ? data.car_option2.split('/') : [],
			carOption3 : data.car_option3 ? data.car_option3.split('/') : [],
			carOption4 : data.car_option4 ? data.car_option4.split('/') : [],
			carOption5 : data.car_option5 ? data.car_option5.split('/') : [],
			carOption8 : data.car_option8 ? data.car_option8.split('/') : []
		});
		handleImageFile();
	}, [])

	return (
		<div >
			<Modal 
				open={true}
				onClose={onClose}
				//react material ui - modal 의 default zindex
				sx={{zIndex : 1300}}
			>
				<Box sx={style}>
					{
						isUpdate ?
						<CarRegistration 
							uptData={carInfo}
							uptCarOption={carOption}
							uptImageFiles={imageFile}
							handleIsModal={onClose}
						/>
						:
						<Box sx={{ width : '100%' , height : '100%' , display : 'flex' , alignItems : 'center' , justifyContent : 'center' }}>
							<Backdrop
								sx={{ color: '#fff', zIndex: 1315 }}
								open={true}
								//onClick={handleClose}
								>
								<CircularProgress color="inherit" />
							</Backdrop>
						</Box>

					}
				</Box>
			</Modal>
		</div>  
	)
}
export default DealerCarDetailModal