import styled from 'styled-components';

export const DropDiv = styled.div<{ isDrop?: boolean }>`
    padding: 10px;
    min-height: 55px;
    border: 1px solid #f3f3f3;
    opacity: ${(props) => (props?.isDrop ? 0 : 1)};
`;

export const DragDiv = styled.div<{ isUpdate?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: ${(props) => (props?.isUpdate ? 'grab' : 'auto')};
`;
//align-items: center;

export const IconBtn = styled.button`
    background-color: transparent;
    border: 0px;
    cursor: pointer;
`;

export const Input = styled.input`
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgb(133, 133, 133);
    border-radius: 4px;
    width: 90%;
    &:focus {
        border-color: black;
    }
`;
