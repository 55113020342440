import {
    Box,
    Button,
    Paper,
    Stack,
    Pagination,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Backdrop,
    CircularProgress,
    SelectChangeEvent,
} from '@mui/material';
import { customAxios } from 'axios/customAxios';
import McDialog from 'carManagement/component/McDialog';
import * as React from 'react';
import UserDetailModal from 'user/component/UserDetailModal';
import UserListMenu from 'user/userList/UserListMenu';
interface UserListProps {}

const UserList = () => {
    const [dealerList, setDealerList] = React.useState([]);
    const [dealerInfo, setDealerInfo] = React.useState<any>({
        userId: '',
        group: '',
        loginState: '',
    });

    /* 검색 메뉴 옵션 state */
    const [searchParams, setSearchParams] = React.useState<any>({
        group: 'a',
        loginState: 'a',
        searchOption: 'userName',
        searchText: '',
        isSearch: false,
    });

    /* 초기화 버튼 관리용 변수 */
    const [isRefresh, setIsRefresh] = React.useState<boolean>(false);

    const [totalCnt, setTotalCnt] = React.useState(0);

    /* 페이징에 사용되는 state 변수 */
    const [page, setPage] = React.useState<number>(1);
    const pageSize = 10;
    /* 가격 변경을 제외한 다른 메뉴 버튼들 클릭시 안내 dialog true / false */
    const [isDialog, setIsDialog] = React.useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const defaultImage = require('res/images/defaultImage.jpg');

    const onErrorImg = (e: any) => {
        e.target.src = defaultImage;
    };

    const [isUserDetailModal, setIsUserDetailModal] = React.useState<boolean>(false);
    const [userInfo, setUserInfo] = React.useState<any>(null);

    // const imgCheck = (photo:string) => {
    // 	if(photo){
    // 		if(photo.includes(REACT_APP_CAR_IMG_DOMAIN_URL!)){
    // 			return `${photo}&w=270`;
    // 		}else {
    // 			return `${photo}`
    // 		}
    // 	}else {
    // 		return '';
    // 	}
    // }

    /**
     * 검색옵션 변경 함수 (회원구분 , 로그인상태 , 검색옵션)
     * @param name
     * @returns
     */
    const handleSearchParams = (name: string) => (e: SelectChangeEvent<any>) => {
        setSearchParams({
            ...searchParams,
            [name]: e.target.value,
        });
    };
    /**
     * 검색 버튼 클릭 이벤트 함수
     * @param name
     * @returns
     */
    const handleButton = (name: string) => {
        if (name === 'refresh') {
            let reset = {
                group: 'a',
                loginState: 'a',
                searchOption: 'userName',
                searchText: '',
                isSearch: false,
            };
            setSearchParams(reset);
            setIsRefresh(true);
        } else {
            pageCheck();
        }
    };

    /**
     * 로그인 상태 - 승인 버튼 클릭 이벤트 함수
     * @param dealerInfo
     */
    const handleUserLoginState = (dealerInfo: any, loginState: string) => {
        setDealerInfo({
            ...dealerInfo,
            userId: dealerInfo.userId,
            group: dealerInfo.group,
            loginState: loginState,
        });
        setIsDialog(true);
    };

    /**
     * 로딩시 사용되는 함수
     */
    const handleIsLoading = (state: boolean) => {
        if (state) {
            setIsLoading(true);
        } else {
            fetchDealerList();

            setTimeout(() => {
                alert('로그인상태 변경이 완료되었습니다.');
                setIsDialog(false);
                setIsLoading(false);
            }, 1500);
        }
    };

    /** 페이징 계산 함수 */
    const pagingCount = () => {
        if (totalCnt % pageSize > 0) {
            return Math.floor(totalCnt / pageSize) + 1;
        } else {
            return Math.floor(totalCnt / pageSize);
        }
    };

    /*carList 호출 관련 함수 , page 값에 따라 다르게 처리 */
    const pageCheck = () => {
        if (page === 1) {
            fetchDealerList();
        } else {
            setPage(1);
        }
    };

    /**
     *
     * 그룹별 권한 함수
     * @param {string} group
     * @return {string} "차량 딜러" | "매입 딜러" | "카스타일러" | "모바일 유저"
     */
    const userGroupByText = (group: number) => {
        switch (group) {
            case 8:
                return '딜러회원';
            case 21:
                return '매입상담사';
            case 22:
                return '영업제휴회원';
            case 20:
                return '카스타일러';
            case 7:
                return '일반회원';
            default:
                return '';
        }
    };

    /* Pagination Event */
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    /**
     * 로그인상태 변경 api 호출 함수
     */
    const doLoginStateSet = async () => {
        handleIsLoading(true);
        const config = {
            params: {
                userId: dealerInfo.userId,
                loginState: dealerInfo.loginState,
            },
        };

        try {
            const response = await customAxios.post(
                `/meetcha/manager/user/doLoginStateSet?userId=${dealerInfo.userId}&loginState=${dealerInfo.loginState}`,
                config
            );
            if (response.status === 200 && response.data === 'success') {
                handleIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     *회원리스트 api 호출 함수
     *
     */
    const fetchDealerList = async () => {
        const config: any = {
            params: {
                group: searchParams.group === 'a' ? '' : searchParams.group,
                loginState: searchParams.loginState === 'a' ? '' : searchParams.loginState,
                [searchParams.searchOption]: searchParams.searchText,
                pageNo: page,
            },
        };
        try {
            const response = await customAxios.get(`/meetcha/manager/user/dealerList`, config);
            if (response.status === 200 && response.data.data) {
                setTotalCnt(response.data.apiinfo.totalcnt);
                setDealerList(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * 회원상세정보 모달 close 이벤트 함수
     * @param isClear
     */
    const handleCloseModalByApiCall = (isClear?: boolean) => {
        setIsUserDetailModal(false);
        if (isClear) {
            fetchDealerList();
        }
    };

    /**
     * 회원상세정보 모달 open 이벤트 함수
     * @param data
     * @returns
     */
    const handleOpenModal = (data: any) => () => {
        setIsUserDetailModal(true);
        setUserInfo(data);
    };

    /* 진입시 호출 */
    React.useEffect(() => {
        fetchDealerList();
    }, [page]);

    React.useEffect(() => {
        if (isRefresh) {
            pageCheck();
            setIsRefresh(false);
        }
    }, [isRefresh]);

    return (
        <>
            <UserListMenu searchParams={searchParams} onChange={handleSearchParams} handleButton={handleButton} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>아이디</TableCell>
                            <TableCell>성명/닉네임</TableCell>
                            <TableCell>회원구분</TableCell>
                            <TableCell>휴대폰번호</TableCell>
                            <TableCell>연락처</TableCell>
                            <TableCell>상사명</TableCell>
                            <TableCell>회원가입일</TableCell>
                            <TableCell>로그인상태</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dealerList.map((item: any) => (
                            <TableRow key={item.number}>
                                <TableCell sx={{ cursor: 'pointer' }} onClick={handleOpenModal(item)}>
                                    {item.userId}
                                </TableCell>
                                <TableCell
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleOpenModal(item)}
                                >{`${item.userName}/${item.userNick}`}</TableCell>
                                <TableCell>{userGroupByText(item.group)}</TableCell>
                                <TableCell>{item.userHphone}</TableCell>
                                <TableCell>{item.userPhone}</TableCell>
                                <TableCell>{item.comName}</TableCell>
                                <TableCell>{item.regDate.split(' ')[0]}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        color={item.loginState === 'Y' ? 'error' : 'primary'}
                                        onClick={() => handleUserLoginState(item, item.loginState === 'Y' ? 'N' : 'Y')}
                                    >
                                        {item.loginState === 'Y' ? '차단' : '승인'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* 페이징 처리 */}
            <Stack spacing={2} style={{ alignItems: 'center', padding: 30 }}>
                <Pagination
                    count={pagingCount()}
                    defaultPage={1}
                    page={page}
                    onChange={handlePaginationChange}
                    color="secondary"
                    variant="outlined"
                    showFirstButton
                    showLastButton
                />
            </Stack>

            {isLoading && (
                /* 로딩바 */
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                        //onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            )}
            {isUserDetailModal && <UserDetailModal data={userInfo} onClose={handleCloseModalByApiCall} />}
            {
                /* 가격 변경을 제외한 다른 메뉴 버튼의 dialog */
                isDialog && (
                    <McDialog
                        title={'안내'}
                        description={` 아이디 : ${dealerInfo.userId} \n 권한 : ${userGroupByText(
                            dealerInfo.group
                        )} \n 회원가입을 ${dealerInfo.loginState === 'Y' ? '승인' : '차단'}하시겠습니까?`}
                        handleClose={() => setIsDialog(false)}
                        handleConfirm={() => doLoginStateSet()}
                    />
                )
            }
        </>
    );
};
export default UserList;

/* 
<Grid
	container
	columns={{ xs: 4, sm: 8, md: 8 , lg : 9}}
	sx={{justifyContent : 'space-around'}}
>
{
	dealerList.map((item:any) => (
		<Grid 
			key={item.number}
			item
			xs={4} sm={8} md={8} lg={4} 
			sx={{
				display: 'flex',
				flexDirection: { xs: 'column', md: 'row' },
				alignItems: 'center',
				bgcolor: 'background.paper',
				overflow: 'hidden',
				borderRadius: '12px',
				boxShadow: 1,
				fontWeight: 'bold',
				marginBottom: 2,
			}}
		>
			<img alt='profile' src={imgCheck(item.photo1)} style={{width : 150 , height : 150}} onError={onErrorImg}/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: { xs: 'center', md: 'flex-start' },
					m: 2,
					//minWidth: { md: 350 }
				}}
			>
				<Typography> 이름 : {item.userName}</Typography>
				<Typography> 아이디 : {item.userId}</Typography>
				<Typography> 닉네임 : {item.userNick}</Typography>
				<Typography> 해시태그 : {item.extra10}</Typography>
				<Typography> 상사 : {item.comName}</Typography>
				<Typography> 상사번호 : {item.userPhone}</Typography> 
				<Typography> 핸드폰번호 : {item.userHphone}</Typography>
				<Typography> 생성일자 : {item.regDate.split(' ')[0]}</Typography>
			</Box>
			<ButtonGroup variant="contained" orientation='vertical'>
				<Button>승인</Button>
				<Button>정보 수정</Button>
				<Button>삭제</Button>
			</ButtonGroup>
		</Grid>
	))
}
</Grid>
*/
