import { Button , Modal ,Grid ,Box, TextField, Paper, List, ListItem, ListItemText, ListItemIcon, ListItemButton ,FormControl ,InputLabel ,Select ,MenuItem, CircularProgress, ListSubheader} from '@mui/material';
import axios from 'axios';
import { customAxios } from 'axios/customAxios';
import McDialog from 'carManagement/component/McDialog';
import { throttle } from 'lodash';
import * as React from 'react'
import { getStorageItem } from 'util/sessionStorage';
import { EnvPath } from 'util/path/EnvPath';
interface CarSellerSearchModalProps {
	onClose : () => void,
	handleCarSellerInfo : (sellerInfo:any) => void
}

interface UserInfo {
	comName: string,
	extra8: string,
	group: number,
	number: number,
	photo1: string,
	point: number,
	regDate: string,
	userAddr1: string,
	userAddr2: string,
	userEmail: string,
	userHphone: string,
	userId: string,
	userName: string,
	userNick: string,
	userPhone: string,
	userPhone2: string
}

const CarSellerSearchModal = ({onClose , handleCarSellerInfo}:CarSellerSearchModalProps) => {

	/* 토큰 및 dealerId */
	const token = getStorageItem("token");

 	/* env 설정 가져오기 */
	const {REACT_APP_CAR_IMG_DOMAIN_URL} = EnvPath ;

	const modalHeight = () => {
		if(window.innerWidth < 500){
			return  window.innerHeight * 0.8 ;
		}else {
			return  window.innerHeight * 0.8 ;
		}
	}

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: window.innerWidth * 0.8,
		height : modalHeight() ,
		bgcolor: 'background.paper',
		//border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}

	const menuOption = [
		{name : '딜러 이름' , value : 'dealer'},
		{name : '상사 이름' , value : 'com'}
	]

	const [dealerList , setDealerList] = React.useState<Array<UserInfo>>([]);
	const [dealerInfo , setDealerInfo] = React.useState<UserInfo>();
	const [searchText , setSearchText] = React.useState<string>('');
	const [menu , setMenu] = React.useState('dealer');
	const [pageNo , setPageNo] = React.useState<number>(1);
	const [totalCnt , setTotalCnt] = React.useState<number>(0);
	const [isLoading , setIsLoading] = React.useState<boolean>(false);

	const [isDialog , setIsDialog] = React.useState<boolean>(false);
	const [isStart , setIsStart] = React.useState<boolean>(false);

	const imgCheck = (photo:string) => {
		if(photo){
			if(photo.includes(REACT_APP_CAR_IMG_DOMAIN_URL!)){
				return `${photo}&w=270`;
			}else {
				return `${photo}`
			}
		}else {
			return '';
		}
	}

	const selectUserInfo = () => {
		handleCarSellerInfo(dealerInfo);
		setIsDialog(false);
		onClose();
	}

	const handleSearch = () => {
		setDealerList([]);

		if(pageNo < 2){
			fetchDealerList();
		}else {
			setPageNo(1);
		}
	}

	const handleIsDialog = (info:any) => () => {
		setIsDialog(true);
		setDealerInfo(info);
	}


	const fetchDealerList = async () => {
		const config:any = {
			params : {
				group : '8',
				pageNo : pageNo
			}
		}
		if(menu === 'dealer'){
			config.params.userName = searchText;
		}else {
			config.params.comName = searchText;
		}

		try {
			const response = await customAxios.get(`/meetcha/manager/user/dealerList`,config);
			if(response.status === 200 && response.data.data){
				if(dealerList.length){
					setDealerList(list => list.concat(response.data.data));
					setIsLoading(false);
				}else {
					setTotalCnt(response.data.apiinfo.totalcnt);
					setDealerList(response.data.data);
					setIsLoading(false);
				}
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	// 스크롤 이벤트 핸들러
	const handleScroll = (e:any) => {
		const currentH = e.target.scrollTop + e.target.clientHeight;
		let maxPage = Math.floor(totalCnt/10);

		if(totalCnt%10 > 0){
			maxPage += 1;
		}
		
		if(currentH >= e.target.scrollHeight-50 && !isLoading && dealerList.length){
			if(pageNo < maxPage){
				setIsLoading(true);
				setPageNo(pageNo + 1);
			}
		}
    };
	//onScroll event 가 너무 많이 일어나기 때문에 throttle 함수를 사용하여 시간적 제한을 줌 
	const onScrollEvent = throttle(handleScroll , 1000);

	React.useEffect(() => {
		if(isStart){
			fetchDealerList();
		}else {
			setIsStart(true);
		}
	}, [pageNo])

	return (
		<div>
			<Modal 
				open={true}
				onClose={onClose}
			>
				<Box sx={style}>
					<Box >
						<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
							<InputLabel id="demo-select-small">판매여부</InputLabel>
							<Select
								value={ menu }
								label="판매여부"
								onChange={(e) => setMenu(e.target.value)}
							>
								{
									menuOption.map(item => (
										<MenuItem key={`${item.value}-${item.name}`} value={ item.value }>{item.name}</MenuItem>
									))
								}
							</Select>
						</FormControl>
						{/* <Box sx={{m : 1}}> */}
							<TextField 
								sx={{m : 1 , ml:0}} 
								size='small' 
								placeholder={'딜러 정보 입력'} 
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={e => {
									if(e.key === 'Enter'){
										handleSearch();
									}
								}}
							/>
							<Button sx={{m : 1 , ml : 0}} style={{height : 40}} variant='contained' onClick={handleSearch}>검색</Button>
						{/* </Box> */}
					</Box>
					<div 
						onScroll={onScrollEvent} 
						style={{
							overflowY :'scroll', 
							width : window.innerWidth * 0.7 , 
							height : window.innerHeight * 0.55 , 
						}}
					>
						<List
							subheader={
								<div style={{display : 'flex' , flexDirection : 'row' }}>
									<ListSubheader component='div' sx={{flex : 1}}>
										이미지
									</ListSubheader>
									<ListSubheader component='div' sx={{flex : 1}}>
										이름
									</ListSubheader>
									{
										window.innerWidth > 450 &&
										<>
											<ListSubheader component='div' sx={{flex : 1}}>
												전화번호
											</ListSubheader>
											<ListSubheader component='div' sx={{flex : 1}}>
												상사
											</ListSubheader>
										</>
									}
								</div>
							}
						>
								{
									dealerList.length ?
									dealerList.map(item => (
										<ListItemButton key={item.number} onClick={handleIsDialog(item)}>
											<ListItem disablePadding sx={{display : 'flex' }}>
												<ListItemIcon sx={{flex : 1}}>
													<img 
														src={imgCheck(item.photo1)}
														alt='프로필'
														style={{
															width : 60 ,
															height : 60 ,
														}}
													/>
												</ListItemIcon>
												<ListItemText primary={item.userName} sx={{flex : 1}}/>
												{
													window.innerWidth > 450 &&
													<>
														<ListItemText primary={item.userPhone ? item.userPhone : item.userHphone} sx={{flex : 1}}/>
														<ListItemText primary={item.comName} sx={{flex : 1}} />
													</>
												}
											</ListItem>
										</ListItemButton>
									))
									:
									null
								}
						</List>
					</div>
					{/* <Box ref={scrollRef} sx={{m:1,overflow :'scroll', width : window.innerWidth * 0.7 , height : window.innerHeight * 0.55 , bgcolor: 'background.paper'}}> */}
					{/* </Box> */}
				</Box>
			</Modal>
			{
				isDialog &&
				<McDialog 
					title='알림'
					description='딜러 정보를 선택하시겠습니까 ?'
					handleClose={() => setIsDialog(false)}
					handleConfirm={selectUserInfo}
				/>
			}
		</div>  
	)
}
export default CarSellerSearchModal;