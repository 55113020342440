import * as React from 'react';
import axios, { AxiosResponse } from 'axios';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';
import {useNavigate} from "react-router-dom";

import CarOptionInfo from 'carManagement/carRegistration/CarOptionInfo';
import CarBasicInfo from 'carManagement/carRegistration/CarBasicInfo';
import CarImageInfo from 'carManagement/carRegistration/CarImageInfo';
import CarSellerInfo from 'carManagement/carRegistration/CarSellerInfo';
import CarPrfomncChckUrl from 'carManagement/carRegistration/CarPrfomncChckUrl';
import CarPriceInfo from 'carManagement/carRegistration/CarPriceInfo';
import MarkdownEditor from 'carManagement/carRegistration/MarkdownEditor';
import CarPrfomncChckImage from 'carManagement/carRegistration/CarPrfomncChckImage';
import McButton from 'carManagement/component/McButton';
import ValidationDialog from 'carManagement/component/ValidationDialog';
import {CarInfoData , SelectOption} from 'interface/CarRegistrationInterface'
import {handleCarRegistration, validationCheck} from 'carManagement/carRegistration/CarRegistrationUtil'
import CarTitle from 'carManagement/carRegistration/CarTitle';
import CarOptionPaymentModal from 'carManagement/carRegistration/CarOptionPaymentModal';
import { debounce } from 'lodash';
import { getStorageItem } from 'util/sessionStorage';
import CarSellerSearchModal from 'carManagement/carRegistration/CarSellerSearchModal';
import { customAxios } from 'axios/customAxios';
import { Path } from 'util/path/RoutePath';
import { EnvPath } from 'util/path/EnvPath';
interface CarRegistrationProps {
	uptData ?: CarInfoData,
	uptCarOption ?:SelectOption,
	uptImageFiles ?:Array<any> ,
	handleIsModal ?:any //() => void
}

const CarRegistration = ({uptData , uptCarOption , uptImageFiles = [] , handleIsModal}:CarRegistrationProps) => {

	/* env 설정 가져오기 */
	const {REACT_APP_API_URL , REACT_APP_MODE} = EnvPath;
	const group = getStorageItem('group');
	const navigate = useNavigate();

	/* api 호출시 데이터를 저장할 state */
	const [carFilterMenu , setCarFilterMenu] = React.useState<any>(null);

	const [carBrandList , setCarBrandList] = React.useState<any>({
		kor : [],
		for : [],
		modelList : []
	});

	const [isDialog , setIsDialog] = React.useState<boolean>(false);
	const [dialogInfo , setDialogInfo] = React.useState<any>({
		title : '',
		desc : ''
	});
	
	const carRef:any = React.useRef(null);

	/* (임시) 차량등록시 넘길 데이터 */
	const [carInfoData , setCarInfoData] = React.useState<CarInfoData>({
		id : '', // 유저아이디
		userNick : '',
		phone1 : '',// 전화번호
		phone2 : '',// 핸드폰 번호
		phone3 : '', // --선택
		email : '', // 이메일
		si : 0, // 시
		siName: '',
		gu : 0, // 구
		dong : '', // 동
		fullAddr : '', // 주소
		group1 : '출퇴근용', // 용도
		year : '', // 년도
		month : '', // 월
		ym: '', // year-month-01
		tone : '',//색상
		fuel : '', //연료
		gas : 0, // 배기량
		mileage : 0, // 주행거리
		atmt : '', // 변속기
		sago : '무', // 사고유무 , default : 무
		quota : '불가능', // 할부 가능 여부
		price : 0, // 차량가격
		main : '', // 차량 설명 , wysiwhg
		saleEnd : '0', // 판매여부 , default : 0
		homeServiceYn : 'N', // 홈서비스 , default : 'N'
		showYn : 'Y', // 노출 여부 , default : 'Y'
		title : '', // 제목
		gouNumber : '', // 제시번호
		etc1 : 'N', // 1인소유 , default : 'N'
		etc2 : '', // 매물테마유형
		imageFiles : null,
		carOption1 : [],
		carOption2 : [],
		carOption3 : [],
		carOption4 : [],
		carOption5 : [],
		carOption6 : [],
		carOption7 : [],
		carOption8 : [],
		carJagaImg : '' , // 이미지타입 성능점검기록부 path+filename
		carJagaImgUse : '0', // 이미지타입 성능점검기록부 사용여부
		oldPrice : 0, //구매시 가격
		addr : '', // 상세주소
		sangho : '', // 상사 이름
		carNumber : '', // 차량 번호
		mainFlashImg : '', // --선택
		carname : '', // 제조사 
		apryo : '없음', // 압류
		jeodang : '없음', // 저당
		apJeoCheck : 'n', // default 'n' ?
		category1 : '', // 단계별 카테고리 number
		category2 : '', // 단계별 카테고리 number
		category3 : '', // 단계별 카테고리 number
		category4 : '', // 단계별 카테고리 number
		category5 : '',
		keyword : '', // 검색 키워드 
		typeNation : '0',// 국산/수입 0 , 1
		carhistory : 0, // 보험이력공개
		officialMileage : '', // 공인연비 --선택
		fuelGrade : '', // 연비등급 --선택
		prfomncChckUrl : '', // 성능점검표 url
		addPrice : 0, // 판매 증감액
		saleType : '1' // 매매 종류
	});

	const [carInfoSideData , setCarInfoSideData] = React.useState({
		categoryName1 : '', // 단계별 카테고리 name
		categoryName2 : '', // 단계별 카테고리 name
		categoryName3 : '', // 단계별 카테고리 name
		categoryName4 : '', // 단계별 카테고리 name
		categoryName5 : '', // 단계별 카테고리 name
	})
	
	//check list
	const [selectOption , setSelectOption] = React.useState<SelectOption>({
		carOption2 : [],
		carOption3 : [],
		carOption4 : [],
		carOption5 : [],
		carOption8 : [],
	});

	const [userInfo , setUserInfo] = React.useState({
		dealerId : '',
		point : ''
	})

	const userId = getStorageItem('id');
	const token = getStorageItem('token');

	const [imageFile , setImageFile] = React.useState<Array<any>>([]);
	
	const wysiwhgRef = React.useRef<any>();

	const [siguData , setSiguData] = React.useState<any>([]);

	const [isCarSellerInfo , setIsCarSellerInfo] = React.useState(false);

	const [isLoading , setIsLoading] = React.useState<boolean>(false);

	const [isCarSellerSearchModal , setIsCarSellerSearchModal] = React.useState<boolean>(false);

	const [isCarPaymentModal , setIsCarPaymentModal] = React.useState<boolean>(false);
	
	const handleSellerInfo = (sellerInfo:any) => {
		
		const sigudongData = sellerInfo.extra8.split('/');
		setSiguData([sigudongData[0] , sigudongData[1]])
		setCarInfoData({
			...carInfoData,
			id : sellerInfo.userId,
			userNick : sellerInfo.userNick,
			phone1 : sellerInfo.userPhone,
			phone2 : sellerInfo.userHphone,
			email : sellerInfo.userEmail,
			dong : sigudongData[2],
			addr : sigudongData[3],
			sangho : sellerInfo.comName
		})
	}
	//carUserData api 호출
	const fetchDealerInfo = async () => {
		try {
			const response = await customAxios.get(`/meetcha/car/manager/getDealerInfo?dealerId=${userId}`);

			if(response.status === 200 && response.data){
				const {userId , comName , userPhone , userPhone2 , userEmail , userNick , extraData , point} = response.data
				
				const addArr:Array<any> = extraData.split('/');

				setSiguData([addArr[0] , addArr[1]]);

				setCarInfoData({
					...carInfoData ,
					id : userId,
					userNick : userNick,
					sangho :comName ,
					phone1 : userPhone, 
					phone2 : userPhone2,
					email : userEmail,
					siName : addArr[0],
					// si : addArr[0] ? addArr[0] : '',
					// gu : addArr[1] ? addArr[1] : '',
					dong : addArr[2] ? addArr[2] : '',
					addr : addArr[3]
				});

				setUserInfo({
					...userInfo,
					dealerId : userId,
					point : point
				})
				setIsCarSellerInfo(true);
			}
			
		} catch (error) {
			console.log(error);
		}
	}

	//carFilterData api 호출
	const fetchCarFilterMenu = async () => {
		try {
			const response = await customAxios.get(`/meetcha/car/v2/carFilterMenu`);

			if(response.status === 200 && response.data){
				setCarFilterMenu(response.data);
			}
			
		} catch (error) {
			console.log(error);
		}
	}	

	//carBrandSearch api 호출
	const fetchCarBrandList = async () => {
		try {
			const response = await customAxios.get(`/meetcha/car/v2/carBrandSearch`);

			if(response.status === 200 && response.data){

				setCarBrandList({
					...carBrandList,
					for : response.data.brand_list_for,
					kor : response.data.brand_list_kor,
					modelList : response.data.model_list
				});
			}
			
		} catch (error) {
			console.log(error);
		}
	}

	/* 모든 Item에서 선택한 값을 state값에 넣어주는 이벤트 함수 */
	const handleCarInfoData = (name:string) => (e:any) => {
		if(name === 'si' ){
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value,
				gu : 0,
				dong : ''
			})
		}else if(name === 'gu'){
			setCarInfoData({
				...carInfoData ,
				[name] : e.target.value,
				dong : ''
			})
		}else if(name === 'typeNation'){
			setCarInfoData({
				...carInfoData ,
				[name] : e.target.value,
				category1 : '',
				category2 : '',
				category3 : '',
				category4 : '',
				category5 : '',
			})
			//카테고리별 name 초기화
			setCarInfoSideData({
				...carInfoSideData,
				categoryName1 : '',
				categoryName2 : '',
				categoryName3 : '',
				categoryName4 : '',
				categoryName5 : '',

			})
		}else if(name === 'category1'){
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value,
				category2 : '',
				category3 : '',
				category4 : '',
				category5 : '',
			})
			//카테고리별 name 초기화
			setCarInfoSideData({
				...carInfoSideData,
				categoryName2 : '',
				categoryName3 : '',
				categoryName4 : '',
				categoryName5 : '',

			})
		}else if(name === 'category2'){
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value,
				category3 : '',
				category4 : '',
				category5 : '',
			})
			//카테고리별 name 초기화
			setCarInfoSideData({
				...carInfoSideData,
				categoryName3 : '',
				categoryName4 : '',
				categoryName5 : '',

			})
		}else if(name === 'category3'){
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value,
				category4 : '',
				category5 : '',
				
			})
			//카테고리별 name 초기화
			setCarInfoSideData({
				...carInfoSideData,
				categoryName4 : '',
				categoryName5 : '',

			})
		}else if(name === 'category4'){
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value,
				category5 : '',
			})
			//카테고리별 name 초기화
			setCarInfoSideData({
				...carInfoSideData,
				categoryName5 : '',

			})
		}else if(name === 'carJagaImg'){
			setCarInfoData({
				...carInfoData ,
				[name] : e.target.files[0] ? e.target.files[0] : '' ,
			})
		}else {
			setCarInfoData({
				...carInfoData , 
				[name] : e.target.value
			})
		}
	}

	const handleInputText = (name:string) => (e:any) => {
		debouncedSearch(name , e.target.value);
	}

	const handleInputTextNotUsedDebounce = (name:string) => (e:any) => {
		setCarInfoData({
			...carInfoData,
			[name] : e.target.value
		})
	}

	//input의 onChange 사용시 잦은 렌더링으로 성능이슈가 생길 수 있으므로 debounce 함수로 딜레이를 걸어줌
	const debouncedSearch = React.useMemo(() => debounce((name , text) => {
		// 모든 호출이 아닌 
		// 지정 간격 마다 리턴 값 받아서 state에 담고
		setCarInfoData({
			...carInfoData,
			[name] : text
		})
	
	}, 500), [ carInfoData ]);

	const handleCategoryName = (name:string) => (e:any) => {	
		setTimeout(() => {
			setCarInfoSideData({
				...carInfoSideData , 
				[name] : e.target.innerText
			})
		}, 100);
	}
	/* textField에서 입력한 값을 state에 넣어주는 이벤트 함수  */
	// const handleCarInfoTextData = (name:string) => (e:any) => {
	// 	setCarInfoData({
	// 		...carInfoData , 
	// 		[name] : e.target.value.trim()
	// 	})
	// }
	const handleCarInfoSiGu = (si:any , gu:any) => {
		setCarInfoData({
			...carInfoData ,
			si : si,
			gu : gu,
		});
	}
	const handleValidationDialog = (desc:string) => {
		setIsDialog(true);
		setDialogInfo({
			...dialogInfo,
			title : '경고',
			desc : desc
		});
	}

	const postCarRegistration = async () => {
		setIsLoading(true);
		let gubun = uptData ? 'update' : 'insert'
		// 의존성 검사
		if(!validationCheck(handleValidationDialog , {...carInfoData} , imageFile.length , gubun)){	
			setIsLoading(false);		
			return ;
		}
		// formData 형식으로 변환 작업
		const formData:FormData = handleCarRegistration({...carInfoData} , {...carInfoSideData} , {...selectOption} , imageFile , wysiwhgRef);

		const formConfig = {
			headers: {
				'Authorization':`Bearer ${token}`,
				'content-type': 'multipart/form-data'
			},
		} 
		try {
			const path = gubun === 'insert' ? 'carRegistration' : 'doCarLinksUpdate'
			const response = await axios.post(`${REACT_APP_API_URL}/meetcha/car/manager/${path}` , formData , formConfig);
			
			if(response.data === 'success'){
				if(gubun === 'update'){
					setIsLoading(false);
					alert('차량수정이 완료되었습니다.')
					handleIsModal();
				}else {
					setIsLoading(false);
					navigate(Path.CAR_DEALER_LIST_PATH)
				}
			}else {
				setIsLoading(false);
				if(gubun === 'update'){
					alert('차량수정 중에 오류가 발생했습니다.');
				}else {
					alert('차량등록 중에 오류가 발생했습니다.');
				}
			}
		} catch (error) {
			setIsLoading(false);
			alert('오류가 발생했습니다.');
			
		}
	} 

	/* 진입시 호출 */
	React.useEffect(() => {

		if(uptData && uptCarOption){
			setIsLoading(true);

			fetchCarFilterMenu();
			fetchCarBrandList();
			
			setCarInfoData(uptData);

			setTimeout(() => {
				setSelectOption(uptCarOption);
				setImageFile(uptImageFiles);
				setIsCarSellerInfo(true);
				setIsLoading(false);
				carRef.current.scrollIntoView({top:0});
			}, 1500);
		}else {
			if(group === '8'){
				fetchDealerInfo();
			} 
			fetchCarFilterMenu();
			fetchCarBrandList();
		}
	},[])

	return (
		<div>
			<Card sx={{ minWidth: 200 }} ref={carRef}>
				{
					(carFilterMenu && carBrandList.modelList && (group === '8' ? isCarSellerInfo : true)) &&
						<>
							<CardContent>
								<CarTitle title={uptData && uptCarOption ? '차량 수정하기' : '차량 등록하기'}/>
								{/* 차량 기본정보 */}
								<CarBasicInfo 
									carFilterMenu={carFilterMenu}
									carBrandList={carBrandList}
									carInfoData={carInfoData}
									handleCarInfoData={handleCarInfoData}
									//handleCarInfoTextData={handleCarInfoTextData}
									handleCategoryName={handleCategoryName}
									handleInputText={handleInputText}
								/>	
							</CardContent>
							{/* 차량 옵션정보 */}
							<CardContent>
								<CarOptionInfo 
									carOption={carFilterMenu.carOption} 
									//carOptionSpecial={carFilterMenu.carOptionSpecial}
									selectOption={selectOption}
									setSelectOption={setSelectOption}	
								/>
							</CardContent>
							{/* 차량 사진정보 */}
							<CardContent>
								<CarImageInfo
									imageFile={imageFile}
									setImageFile={setImageFile}	
									isUpdate={uptData ? true : false}
								/>
							</CardContent>
							{/* 판매자 정보 */}
							<CardContent>
								{
									<CarSellerInfo 
										carInfoData={carInfoData}
										siguData={siguData}
										handleCarInfoData={handleCarInfoData}
										handleInputText={handleInputTextNotUsedDebounce}
										handleCarInfoSiGu={handleCarInfoSiGu}
										handleCarSellerSearchModal={() => setIsCarSellerSearchModal(true)}
									/>
								}
							</CardContent>

							{/* 차량설명 */}
							<CardContent>
								<MarkdownEditor 
									innerRef={wysiwhgRef}
									updateMain={uptData ? uptData.main : undefined}
								/>
							</CardContent>

							{/* 성능점검표 등록 */}
							<CardContent>
								<CarPrfomncChckUrl
									prfomncChckUrl={carInfoData.prfomncChckUrl}
									handleInputText={handleInputText}
								/>
							</CardContent>

							{/* 성능점검표 이미지 등록 */}
							<CardContent>
								<CarPrfomncChckImage 
									carJagaImgUse={carInfoData.carJagaImgUse}
									handleCarInfoData={handleCarInfoData}
								/>
							</CardContent>
							{/* 판매가격 정보 */}
							<CardContent>
								<CarPriceInfo 
									carInfoData={carInfoData}
									handleCarInfoData={handleCarInfoData}
									handleInputText={handleInputText}
								/>
							</CardContent>
							<CardActions sx={{minWidth : 180 , width : '100%' , display : 'flex' , justifyContent : 'center'}} >
								{
									(uptData && uptCarOption) ?
										<>
											<McButton title='수정하기' color='primary' variant='contained' onClick={postCarRegistration}/>
											<McButton title='수정취소' color='error' variant='contained' onClick={handleIsModal}/>
										</>
										:
										<>
											<McButton title='등록완료' color='primary' variant='contained' onClick={postCarRegistration}/>
											<McButton title='등록취소' color='error' variant='contained' onClick={() => navigate(Path.HOME_PATH)}/>
										</>
								}
								
								{/* <McButton title='유료결제' color='info' variant='contained' onClick={() => setIsCarPaymentModal(true)}/> */}
							</CardActions>
						</>
				}
			</Card>
			{
				isDialog &&
				<ValidationDialog 
					title={dialogInfo.title}
					description={dialogInfo.desc}
					handleClose={() => setIsDialog(!isDialog)}
				/>
			}
			{
				isLoading &&
				<Box sx={{ width : '100%' , height : '100%' , display : 'flex' , alignItems : 'center' , justifyContent : 'center' }}>
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={true}
						//onClick={handleClose}
						>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Box>
			}
			{
				isCarSellerSearchModal &&
				<CarSellerSearchModal 
					onClose={() => setIsCarSellerSearchModal(false)}
					handleCarSellerInfo={handleSellerInfo}
				/>
			}
			{
				// isCarPaymentModal &&
				// <CarOptionPaymentModal
				// 	userInfo={userInfo}
				// 	carInfo={null}
				// 	isRegistCar={false}
				// 	onClose={() => setIsCarPaymentModal(false)}
				// />
			}
		</div>
	)
}

export default CarRegistration;