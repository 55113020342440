import React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DragDiv, DropDiv, IconBtn, Input } from 'carManagement/style/DragStyle';
import { useDrag, useDrop } from 'react-dnd';

interface CarOptionItemProps {
    info: {
        optType: string;
        number: number;
        title: string;
        index: number;
    };
    isUpdate: boolean;
    handleDrop: (item: any, index: number, optType: string) => void;
    handleUpdateText: (index: number, value: string, optType: string) => void;
    handleRemoveItem: (number: number, optType: string) => void;
}

const CarOptionItem = React.memo(
    ({ info, isUpdate, handleDrop, handleUpdateText, handleRemoveItem }: CarOptionItemProps) => {
        const { optType, title, number, index } = info;

        // const [isRemoveIcon, setIsRemoveIcon] = React.useState(false);
        // const [{ isDragging }, dragRef, previewRef] = useDrag(
        //     () => ({
        //         type: 'car_option',
        //         item: { title, number, index, optType },
        //         collect: (monitor) => ({
        //             isDragging: monitor.isDragging(), //isDragging 변수가 현재 드래깅중인지 아닌지를 리턴해주는 부분.
        //         }),
        //         end: (item: any, monitor) => {
        //             const didDrop = monitor.didDrop();
        //             if (!didDrop && item.optType !== optType) {
        //                 console.log(optType, title, number, index, '<from');
        //                 console.log(item, '<to');
        //                 //api 호출
        //                 //handleDragEnd();
        //             }
        //         },
        //     }),
        //     [number, index]
        // );

        // const [, dropRef] = useDrop(
        //     () => ({
        //         accept: 'car_option',
        //         canDrop: () => false,
        //         hover(item: any, monitor) {
        //             if (!isUpdate) {
        //                 if (item.number !== number) {
        //                     if (item.optType !== optType) {
        //                         handleDrop(item, index, optType);
        //                         item.optType = optType;
        //                     }
        //                 }
        //             }
        //         },
        //     }),
        //     [handleDrop, index]
        // );

        return (
            <div>
                <DropDiv>
                    <DragDiv>
                        {isUpdate ? (
                            <Input
                                defaultValue={title}
                                onBlur={(e) => {
                                    handleUpdateText(index, e.target.value, optType);
                                }}
                            />
                        ) : (
                            <span>{title}</span>
                        )}
                        {!isUpdate && (
                            <IconBtn onClick={() => handleRemoveItem(number, optType)}>
                                <RemoveCircleOutlineIcon color="error" />
                            </IconBtn>
                        )}
                    </DragDiv>
                </DropDiv>
            </div>
        );
    }
);

export default CarOptionItem;
