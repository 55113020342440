import * as React from 'react';

import { FormControl , InputLabel ,Select ,MenuItem ,TextField, Box , Button} from '@mui/material';
interface DealerCarMenuProps {
	value :any,
	onChange : (name:string) => (e:any) => void,
	handleSearch : () => void,
	handleInputText : (e:any) => void,
	handleRefresh : () => void
} 

//상단바 메뉴
const DealerCarMenu = ({ value , onChange , handleSearch , handleInputText , handleRefresh}:DealerCarMenuProps) => {

	/* 판매여부 */
	const saleEndData = [
		{label : '전체' , value : 'a'},
		{label : '판매중' , value : '0'},
		{label : '판매완료' , value : '1'}
	];

	/* 광고여부 */
	const broadcastGubunData = [
		{label : '전체' , value : 'a'},
		{label : '광고 차량' , value : '1'},
		{label : '미광고 차량' , value : '0'}
	];
	/* 노출여부 */
	const showYnData = [
		{label : '전체' , value : 'a'},
		{label : '노출' , value : 'Y'},
		{label : '미노출' , value : 'N'}
	];

	const saleTypeData = [
		{label : '전체' , value : 'a'},
		{label : '판매' , value : '1'},
		{label : '렌트' , value : '3'},
		{label : '구독' , value : '5'},
		{label : '판매&구독' , value : '7'}
	];

	return (
		<Box sx={{mb : 2}}>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">판매여부</InputLabel>
				<Select
					labelId="demo-select-small"
					id="demo-select-small"
					value={ value.saleEnd }
					label="판매여부"
					onChange={onChange('saleEnd')}
				>
					{
						saleEndData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">광고여부</InputLabel>
				<Select
				labelId="demo-select-small"
				id="demo-select-small"
				value={ value.isBroadcast }
				label="촬영여부"
				onChange={onChange('isBroadcast')}
				>
					{
						broadcastGubunData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">노출여부</InputLabel>
				<Select
				labelId="demo-select-small"
				id="demo-select-small"
				value={ value.showYn }
				label="노출여부"
				onChange={onChange('showYn')}
				>
					{
						showYnData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0 , minWidth: 120 }} size="small">
				<InputLabel id="demo-select-small">매매종류</InputLabel>
				<Select
				labelId="demo-select-small"
				id="demo-select-small"
				value={ value.saleType }
				label="매매종류"
				onChange={onChange('saleType')}
				>
					{
						saleTypeData.map(item => (
							<MenuItem key={`${item.value}-${item.label}`} value={ item.value }>{item.label}</MenuItem>
						))
					}
				</Select>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}} size="small" >
				 <TextField
					label="검색 차량번호"
					id="outlined-size-small"
					onChange={handleInputText}
					
					value={value.searchText}
					size="small"
				/>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}}  >
				<Button variant='contained' style={{height : 40}} color='info' onClick={handleSearch}>
					검색
				</Button>
			</FormControl>
			<FormControl sx={{ m: 1, ml:0}}  >
				<Button variant='contained' style={{height : 40}} color='error' onClick={handleRefresh}>
					초기화
				</Button>
			</FormControl>
		</Box>
	)
}

export default DealerCarMenu ;