import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { customAxios } from 'axios/customAxios';
import McBackdrop from 'component/McBackdrop';
import { useQueries } from 'react-query';
import CarOptionCheck from 'carManagement/carOption/CarOptionCheck';
import CarOptionDetail from 'carManagement/carOption/CarOptionDetail';

const CarOption = () => {
    const optList = ['car_option4', 'car_option3', 'car_option5', 'car_option2', 'car_option8'];
    const carOptListUrl = `/meetcha/car/manager/getListCarOpt`;
    const tabList = [
        { name: 'car_option', label: '차량 상세옵션' },
        { name: 'car_option_search_six_checked', label: '상세검색 옵션 설정' },
    ];

    //탭 키값 state
    const [value, setValue] = React.useState('car_option');

    /**
     * 탭 변경 이벤트
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const carOptList = useQueries(
        optList.map((opt) => {
            return {
                queryKey: opt,
                queryFn: async () => {
                    const res: any = await customAxios.get(`${carOptListUrl}?optType=${opt}`);
                    //configList.data 객체 안에 queryKey를 넣어줌
                    res.queryKey = opt;
                    return res;
                },
                //활성화 시간 60분
                staleTime: 60 * 60 * 1000,
                retry: false,
                onError: (err: any) => {
                    console.log(err);
                },
            };
        })
    );

    if (carOptList.some((item) => item.isLoading === true) && carOptList.some((item) => item.isSuccess === false)) {
        return (
            <div>
                <McBackdrop />
            </div>
        );
    }
    if (carOptList.some((item) => item.isError === true)) {
        return <div></div>;
    }

    return (
        <div>
            <Tabs value={value} onChange={handleChange}>
                {tabList.map((item) => (
                    <Tab
                        key={item.name}
                        sx={{ fontWeight: 'bold', fontSize: 17 }}
                        value={item.name}
                        label={item.label}
                    />
                ))}
            </Tabs>
            <div>
                {value === 'car_option' ? (
                    <CarOptionDetail data={[...carOptList]} optList={optList} />
                ) : (
                    <CarOptionCheck data={[...carOptList]} optList={optList} />
                )}
            </div>
        </div>
    );
};

export default CarOption;
