import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import OrdersLayout from 'sellMyCar/OrdersLayout';
import TotalListLayout from 'sellMyCar/TotalListLayout';

interface SellMyCarRouteProps {}

const SellMyCarRoute = () => {
    return (
        <Routes>
            <Route path="/totalList" element={<TotalListLayout />} />
            <Route path="/orderList" element={<OrdersLayout />} />
        </Routes>
    );
};
export default SellMyCarRoute;
