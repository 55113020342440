import * as React from 'react';
import axios from 'axios';
import {useNavigate , useLocation } from "react-router-dom";
import { Box, CardContent, CardActions, FormControl, Grid, TextField } from '@mui/material';

import { customAxios } from 'axios/customAxios';

import McTypography from 'component/McTypography';
import McTextField from 'component/McTextField';
import McSelectItem from 'component/McSelectItem';
import McButton from 'component/McButton';
import McAlertDialog from 'component/McAlertDialog';
import McConfirmDialog from 'component/McConfirmDialog';
import { isConstructorDeclaration } from 'typescript';

interface formParams {
    paramSeq: any,
    searchParams: any
}

const ManageForm = () => {

    const [isDialog , setIsDialog] = React.useState<boolean>(false);    
	const [dialogInfo , setDialogInfo] = React.useState<any>({
		title : '',
		desc : ''
	});

    const [isConfirmDialog , setIsConfirmDialog] = React.useState<boolean>(false);
    const [confirmDialogInfo , setConfirmDialogInfo] = React.useState<any>({
		title : '',
		desc : '',
        handleConfirm : null,
	});


    const navigate = useNavigate();
    const { state } = useLocation();
    const { paramSeq, searchParams } = state as formParams;

    const [seq, setSeq] = React.useState<any>(paramSeq);
    const [name, setName] = React.useState<string>('');
    const [telno, setTelno] = React.useState<string>('');
    const [smsGroup, setSmsGroup] = React.useState<string>('');
    const [useYn, setUseYn] = React.useState<string>('');
    const [grade, setGrage] = React.useState<string>('');    

    const smsGroupData = [
        {name:'매입상담', value:'01'},
        {name:'알림', value:'99'},
    ];

    const useYnData = [
        {name:'수신', value:'Y'},
        {name:'미수신', value:'N'}        
    ];

    const gradeData = [
        {name:'일반', value:'01'},
        {name:'관리', value:'99'},
    ];

    const handleInputText = (name: string) => (e:any) => {
        if(name === 'name'){
            setName(e.target.value);
        } else if (name === 'telno') {
            setTelno(e.target.value);
        }
    }

    const handleChange = (name: string) => (e:any) => {        
        if(name === 'useYn'){
            setUseYn(e.target.value);
        } else if(name === 'grade') {
            setGrage(e.target.value);
        } else if(name === 'smsGroup') {
            setSmsGroup(e.target.value);
        }
    }

    // function
    const fetchData = async () => {

        const config:any = {
			params : {
                seq : seq
			}
		}

        try {
			const response = await customAxios.get(`/meetcha/sellcar/manager/view`,config);
			if(response.status === 200 && response.data.data){
                setName(response.data.data.name);
                setTelno(response.data.data.telno);
                setSmsGroup(response.data.data.sendGroup);
                setUseYn(response.data.data.useYn);
                setGrage(response.data.data.grade);                
			}
		} catch (error) {
			console.log(error);
		}
    }

    const handleAlertDialog = (desc:string) => {
		setIsDialog(true);
		setDialogInfo({
			...dialogInfo,
			title : '경고',
			desc : desc
		});
	}

    // 전송~
    const postSubmit = async () => {        
        // console.log('paramSeq:'+paramSeq);
        // console.log('seq:'+seq);
        // console.log('name:'+name);
        // console.log('telno:'+telno);
        // console.log('useYn:'+useYn);
        // console.log('grade:'+grade);

        var pSeq;

        if(seq == 0){
            pSeq = null;
        } else {
            pSeq = seq;
        }

        if(name === ''){
            handleAlertDialog('성명을 입력해 주세요.');
            return;
        }

        if(telno === ''){
            handleAlertDialog('휴대폰번호를 입력해 주세요.');            
            return;
        }

        if(smsGroup === ''){
            handleAlertDialog('수신그룹 선택해 주세요.');
            return;
        }

        if(useYn === ''){
            handleAlertDialog('수신구분을 선택해 주세요.');
            return;
        }

        if(grade === ''){
            handleAlertDialog('등급을 선택해 주세요.');
            return;
        }

        // 알림창 비활성화
        setIsDialog(false);

        const config:any = {
			headers: {
				'content-type': 'multipart/form-data'
			},
		}

        const data:any = {			            
            sendGroup: smsGroup,
            name: name,
            telno: telno,
            grade: grade,
            useYn: useYn,
            delYn: 'N',
            seq : pSeq
		}

        try {

            const response = await customAxios.post(`/meetcha/sellcar/manager/doSave`, data, config);

            if(response.status === 200 && response.data.apiinfo.status === '00'){
                handleAlertDialog('저장 되었습니다.');
                navigate('/sms/manageList');
            } else if(response.status === 200 && response.data.apiinfo.status !== '00') {
                handleAlertDialog(response.data.apiinfo.message);
            }

        } catch(error) {
            handleAlertDialog('관리자에게 문의 바랍니다.');
            console.log(error);
        }
    }

    const handleDelDialog = () => {

        setIsConfirmDialog(!isConfirmDialog);

        setConfirmDialogInfo({
			...confirmDialogInfo,
			title : '경고',
			desc : '삭제 하시겠습니까?',
            handleConfirm : postDel
		});

    }

    const postDel = async () => {

        var pSeq;

        if(seq == 0){
            pSeq = null;
        } else {
            pSeq = seq;
        }

        const config:any = {
			headers: {
				'content-type': 'multipart/form-data'
			},
		}

        const data:any = {			            
            sendGroup: smsGroup,
            name: name,
            telno: telno,
            grade: grade,
            useYn: useYn,
            delYn: 'Y',
            seq : pSeq
		}

        try {

            const response = await customAxios.post(`/meetcha/sellcar/manager/doSave`, data, config);

            if(response.status === 200 && response.data.apiinfo.status === '00'){
                handleAlertDialog('삭제 되었습니다.');
                navigate('/sms/manageList');
            } else if(response.status === 200 && response.data.apiinfo.status !== '00') {
                handleAlertDialog(response.data.apiinfo.message);
            }

        } catch(error) {
            handleAlertDialog('관리자에게 문의 바랍니다.');
            console.log(error);
        }

    }

    React.useEffect(() => {
        // console.log("searchParams.searchPage:"+searchParams.searchPage);
        if(seq !== 0){
		    fetchData();
        }
	},[seq])

    // React.useEffect(() => {
    //     window.onpopstate = (e) => {
    //         e.preventDefault()
    //         if(e){
    //             navigate('/sms/manageList', { state : { returnParam : searchParams } });
    //         }
    //     }
    //     return () => {
    //         document.removeEventListener("onpopstate", (e) => {
    //             e.preventDefault()
    //             if(e){
    //                 navigate('/sms/manageList', { state : { returnParam : searchParams } });
    //             }
    //         });
    //     }
	// },[])

    return (
        <>
            <CardContent>
                <Box sx={{ width: '100%', minWidth: 180 ,mb : 2 , borderBottom : 1}}>
                    <McTypography variant='h5' text='수신자 정보'/>
                </Box>

                <Grid container spacing={{ xs: 2, sm: 2, md: 2 }} columns={{ xs: 2, sm : 4 ,md: 6 }}>
                    <Grid item xs={2} sm={2} md={3}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Box sx={{m:2}}>
                                <McTextField 
                                    id={'name'}
                                    label={'성명'} 
                                    variant={'outlined'}
                                    onChange={handleInputText}
                                    value={name}
                                    endAdornment={''}
                                    placeholder={'성명'}
                                />
                            </Box>
                            <Box sx={{m:2}}>
                                <McTextField 
                                    id={'telno'}
                                    label={'휴대폰번호'} 
                                    variant={'outlined'}
                                    onChange={handleInputText}
                                    value={telno}
                                    endAdornment={''}
                                    placeholder={'휴대폰번호'}
                                />
                            </Box>                   
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} sm={2} md={3} sx={{backgroundColor:'#F9F9F9'}}>
                        <Box sx={{m:2}}>
                            <McSelectItem data={smsGroupData} title={'수신그룹'} id={'smsGroup'} value={smsGroup} label={'수신그룹'} onChange={handleChange} />
                        </Box>
                        <Box sx={{m:2}}>
                            <McSelectItem data={useYnData} title={'수신구분'} id={'useYn'} value={useYn} label={'수신구분'} onChange={handleChange} />
                        </Box>
                        <Box sx={{m:2}}>
                            <McSelectItem data={gradeData} title={'등급'} id={'grade'} value={grade} label={'등급'} onChange={handleChange}/>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions sx={{minWidth : 180 , width : '100%' , display : 'flex' , justifyContent : 'center'}} >
                <McButton title='저장' color='primary' variant='contained' onClick={ postSubmit }/>
                {
                    seq !== 0 ?                        
                    <McButton title='삭제' color='error' variant='contained' onClick={ handleDelDialog }/>
                    :
                    null                    
                }
                <McButton title='취소' color='warning' variant='contained' onClick={ () => navigate('/sms/manageList', { state : { returnParam : searchParams } }) }/>
            </CardActions>

            {
				isDialog &&
				<McAlertDialog 
					title={dialogInfo.title}
					description={dialogInfo.desc}
					handleClose={() => setIsDialog(!isDialog)}
				/>
			}

            {
				isConfirmDialog &&
				<McConfirmDialog 
					title={confirmDialogInfo.title}
					description={confirmDialogInfo.desc}
					handleClose={() => setIsConfirmDialog(!isConfirmDialog)}
                    handleConfirm={confirmDialogInfo.handleConfirm}
				/>
			}

        </>
    )

}
export default ManageForm;