import * as React from 'react';
import { FormHelperText } from '@mui/material';
import { SignUpUserFormInfo, SignUpUserRequireInfo } from 'interface/SignUpUserInfo';
import { FieldErrorsImpl, UseFormRegister, DeepRequired, UseFormWatch } from 'react-hook-form';
import { EmailTextField, SignUpTextField } from './SignUpTextField';

interface UserInfoFormProps {
    register: UseFormRegister<SignUpUserFormInfo>;
    errors: FieldErrorsImpl<DeepRequired<SignUpUserRequireInfo>>;
    watch: UseFormWatch<SignUpUserFormInfo>;
}
const UserInfoForm = ({ register, errors, watch }: UserInfoFormProps) => {
    const { userId, userPass, userPassCom, userName, userNick, userEmail, userPhone, userHphone, comName }: any =
        errors;
    console.log('UserInfoForm');

    return (
        <div>
            <SignUpTextField
                label={'아이디*'}
                name={'userId'}
                register={register}
                type={'text'}
                helperText={'4자 이상 입력해주세요.'}
                pattern={{
                    value: /^[A-Za-z0-9]{4,12}$/,
                    message: '아이디는 4 ~ 12자로 영어 및 숫자만 사용할 수 있습니다.',
                }}
                required={'아이디를 입력해주세요.'}
                error={Boolean(userId)}
            />
            {userId && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userId)}>
                    {userId.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'비밀번호*'}
                name={'userPass'}
                type={'password'}
                register={register}
                helperText={'영문,숫자 조합 8자 이상 입력해 주세요.'}
                required={'비밀번호를 입력해주세요.'}
                pattern={{
                    value: /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?=[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/,
                    message: '비밀번호는 8자 이상으로 영문 대 소문자 , 숫자 , 특수기호를 조합해서 사용하세요.',
                }}
                error={Boolean(userPass)}
            />
            {userPass && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userPass)}>
                    {userPass.message}
                </FormHelperText>
            )}

            <SignUpTextField
                label={'비밀번호 확인*'}
                type={'password'}
                name={'userPassCom'}
                register={register}
                validate={(value) => value === watch('userPass') || '입력한 비밀번호와 일치하지 않습니다.'}
                required={'입력한 비밀번호와 일치하지 않습니다.'}
                error={Boolean(userPassCom)}
            />
            {userPassCom && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userPassCom)}>
                    {userPassCom.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'이름*'}
                name={'userName'}
                type={'text'}
                register={register}
                pattern={{ value: /^[가-힣]{2,4}$/, message: '이름은 2 ~ 4 자로 한글로 입력해주세요.' }}
                helperText={'실명으로 입력해 주세요. (실명인증 이용시 자동입력)'}
                required={'이름을 입력해 주세요.'}
                error={Boolean(userName)}
            />
            {userName && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userName)}>
                    {userName.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'닉네임*'}
                name={'userNick'}
                type={'text'}
                register={register}
                required={'닉네임을 입력해 주세요.'}
                error={Boolean(userNick)}
            />
            {userNick && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userNick)}>
                    {userNick.message}
                </FormHelperText>
            )}
            <SignUpTextField label={'해시태그'} name={'extra10'} type={'text'} register={register} required={false} />
            <EmailTextField
                label={'이메일'}
                name={'userEmail'}
                type={'text'}
                register={register}
                pattern={{ value: /^[A-Za-z0-9]([-_.]?[A-Za-z0-9]){2,20}$/, message: '이메일을 다시 입력해주세요.' }}
                required={false}
                error={Boolean(userEmail)}
            />
            {userEmail && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userEmail)}>
                    {userEmail.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'연락처'}
                name={'userPhone'}
                type={'text'}
                register={register}
                required={false}
                pattern={{ value: /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/, message: '전화번호 형식을 맞춰주세요' }}
                error={Boolean(userPhone)}
            />
            {userPhone && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userPhone)}>
                    {userPhone.message}
                </FormHelperText>
            )}
            <SignUpTextField
                label={'휴대폰'}
                name={'userHphone'}
                type={'text'}
                register={register}
                required={'휴대폰 번호를 입력해주세요.'}
                pattern={{
                    value: /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/,
                    message: '휴대폰 번호 형식을 맞춰주세요.',
                }}
                error={Boolean(userHphone)}
            />
            {userHphone && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(userHphone)}>
                    {userHphone.message}
                </FormHelperText>
            )}
            <SignUpTextField label={'팩스 번호'} name={'userFax'} type={'text'} register={register} required={false} />
            <SignUpTextField label={'사원증 번호'} name={'extra9'} type={'text'} register={register} required={false} />
            <SignUpTextField
                label={'상사명*'}
                name={'comName'}
                type={'text'}
                register={register}
                required={'상사명을 입력해주세요.'}
                error={Boolean(comName)}
            />
            {comName && (
                <FormHelperText sx={{ m: 1 }} error={Boolean(comName)}>
                    {comName.message}
                </FormHelperText>
            )}
        </div>
    );
};
export default UserInfoForm;
