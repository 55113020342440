import React from 'react';
import { Box, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import { DragDiv, DropDiv, IconBtn, Input } from 'carManagement/style/DragStyle';
import { cloneDeep, throttle } from 'lodash';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CarOptionDetailItem from 'carManagement/carOption/CarOptionDetailItem';
import arrayMove from 'array-move';
import { useMutation, useQueryClient } from 'react-query';
import { customAxios } from 'axios/customAxios';
import McBackdrop from 'component/McBackdrop';
import McDialog from 'carManagement/component/McDialog';

interface CarOptionDetailProps {
    data: Array<any>;
    optList: Array<string>;
}

const CarOptionDetail = ({ data, optList }: CarOptionDetailProps) => {
    const queryClient = useQueryClient();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [isUpdateOptionList, setIsUpdateOptionList] = React.useState<Array<string>>([]);

    const [isDialog, setIsDialog] = React.useState(false);
    const [selectValue, setSelectValue] = React.useState({
        number: 0,
        optType: '',
    });

    /* 옵션별로 저장할 state */
    const [optionData, setOptionData] = React.useState<{
        [prop: string]: Array<any>;
    }>({
        car_option4: [],
        car_option3: [],
        car_option5: [],
        car_option2: [],
        car_option8: [],
    });

    /* 옵션별 추가할 input 값 */
    const optionRef = React.useRef<any>({
        car_option4: null,
        car_option3: null,
        car_option5: null,
        car_option2: null,
        car_option8: null,
    });

    /**
     * 옵션 저장
     */
    const doSaveCarOpt = useMutation((params: FormData) =>
        customAxios.post(`/meetcha/car/manager/doSaveCarOpt`, params)
    );
    /**
     * 옵션리스트 저장
     */
    const doSaveCarOptList = useMutation(
        (params: FormData) => customAxios.post(`/meetcha/car/manager/doSaveCarOptList`, params),
        {
            onSuccess: () => {
                isUpdateOptionList.forEach((i) => queryClient.invalidateQueries(i));
                setIsUpdateOptionList([]);
            },
        }
    );

    /**
     * 옵션 삭제
     */
    const doDeleteCarOpt = useMutation((number: number) =>
        customAxios.post(`/meetcha/car/manager/doDeleteCarOpt?number=${number}`)
    );

    /**
     * update가 필요한 option 추가
     * @param optTypeList
     */
    const CheckIsUpdateOption = (optTypeList: Array<string>) => {
        const uptOptList = [...isUpdateOptionList];
        optTypeList.forEach((optType) => {
            if (!isUpdateOptionList.some((i) => i === optType)) {
                uptOptList.push(optType);
            }
        });
        setIsUpdateOptionList(uptOptList);
    };

    /**
     * 옵션 삭제 이벤트
     * @param number
     * @param optType
     */
    const handleRemoveItem = (number?: number, optType?: string) => {
        if (isUpdate) {
            const selArr = [...optionData[optType!]];
            setOptionData({
                ...optionData,
                [optType!]: selArr.filter((item) => item.number !== number),
            });

            //리스트를 넘길 option 추가
            CheckIsUpdateOption([optType!]);
        } else {
            setIsDialog(false);

            doDeleteCarOpt.mutate(selectValue.number, {
                onSuccess: () => {
                    queryClient.invalidateQueries(selectValue.optType);
                },
            });
        }
    };

    const handleSelectValue = (number: number, optType: string) => {
        setSelectValue({
            number: number,
            optType: optType,
        });
        setIsDialog(true);
    };

    /**
     * 옵션 추가 이벤트
     * @param optType
     */
    const handleAddItem = (optType: string) => {
        if (optionRef.current[optType]?.value) {
            if (optionData[optType].some((i) => i.title === optionRef.current[optType]?.value)) {
                alert('이미 존재하는 옵션입니다.');
                return;
            }
            if (isUpdate) {
                const selArr = [...optionData[optType]];
                const newValue = {
                    //number : 0,
                    title: optionRef.current[optType]?.value,
                    optType: optType,
                };
                selArr.push(newValue);

                setOptionData({
                    ...optionData,
                    [optType]: selArr,
                });

                //리스트를 넘길 option 추가
                CheckIsUpdateOption([optType]);
            } else {
                const formData = new FormData();
                formData.append('optType', optType);
                formData.append('title', optionRef.current[optType]?.value);

                doSaveCarOpt.mutate(formData, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(optType);
                    },
                });
            }

            optionRef.current[optType].value = '';
        } else {
            alert('문구를 입력해주세요.');
        }
    };

    /**
     * 완료버튼 클릭 이벤트
     */
    const handleCompleteBtn = () => {
        //일괄 저장시 필요한 index
        isUpdateOptionList.forEach((optType: string) => {
            let index = 0;
            const formData = new FormData();
            optionData[optType].forEach((item: any) => {
                if (item?.number) {
                    formData.append(`happyCarOptEntity2ListVO[${index}].number`, item.number);
                }
                formData.append(`happyCarOptEntity2ListVO[${index}].optType`, item.optType);
                formData.append(`happyCarOptEntity2ListVO[${index}].title`, item.title);
                index += 1;
            });
            doSaveCarOptList.mutate(formData);
        });

        resetInput();
        setIsUpdateOptionList([]);
        setIsUpdate(false);
    };

    /**
     * 옵션 문구 수정 이벤트
     * @param index
     * @param value
     * @param optType
     */
    const handleUpdateText = (index: number, value: string, optType: string) => {
        const selArr = [...optionData[optType]];
        if (selArr[index].title !== value) {
            selArr[index].title = value;

            setOptionData({
                ...optionData,
                [optType]: selArr,
            });

            //리스트를 넘길 option 추가
            CheckIsUpdateOption([optType]);
        }
    };

    /**
     * drop 이벤트
     * thottle을 적용하여 무분별한 호출을 제어해줌
     * 같은 옵션끼리 순서변경 가능
     * 외관 -> 내장 옵션 이동도 가능
     */
    const handleDrop = throttle((item: any, index: number, optType: string) => {
        const startArray = [...optionData[item.optType]];
        const selIndex = startArray.findIndex((i) => i.number === item.number);
        if (item.optType === optType) {
            setOptionData({
                ...optionData,
                [item.optType]: arrayMove(startArray, selIndex, index),
            });
            //리스트를 넘길 option 추가
            CheckIsUpdateOption([optType]);
        } else {
            const toArray = [...optionData[optType]];

            const newItem = {
                number: item.number,
                title: item.title,
                optType: optType,
            };
            //이동하려는 값 원래 위치에서 제거
            startArray.splice(selIndex, 1);
            toArray.splice(index, 0, newItem);

            setOptionData({
                ...optionData,
                [item.optType]: startArray,
                [optType]: toArray,
            });
            //리스트를 넘길 option 추가
            CheckIsUpdateOption([optType, item.optType]);
        }
    }, 70);

    /**
     * 옵션 타이틀 - (외관 , 내장 , 안전 , 편의 , 기타)
     * @param number
     * @returns
     */
    const ListTitle = (number: string) => {
        const optionTitle = () => {
            switch (number) {
                case '4':
                    return '외관';
                case '3':
                    return '내장';
                case '5':
                    return '안전';
                case '2':
                    return '편의';
                case '8':
                    return '기타';
                default:
                    return '';
            }
        };
        return (
            <List
                disablePadding
                sx={{ border: 2, borderTop: 1, borderBottom: 1, borderColor: '#f3f3f3', backgroundColor: '#f3f3f3' }}
            >
                <ListItem>
                    <ListItemText
                        primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}
                        primary={optionTitle()}
                    />
                </ListItem>
            </List>
        );
    };

    /**
     * input box 내부 문구 전부 제거
     */
    const resetInput = () => {
        optList.forEach((optType) => {
            if (optionRef.current[optType]?.value) {
                optionRef.current[optType]!.value = '';
            }
        });
    };
    /**
     * 취소버튼 클릭 이벤트
     */
    const resetData = () => {
        const reData = cloneDeep(data);
        const result: any = (key: string) => reData.filter(({ data }: any) => data.queryKey === key)[0].data;
        setOptionData({
            car_option4: result('car_option4').data,
            car_option3: result('car_option3').data,
            car_option5: result('car_option5').data,
            car_option2: result('car_option2').data,
            car_option8: result('car_option8').data,
        });
    };

    React.useEffect(() => {
        resetData();
    }, [data]);

    return (
        <div>
            <Grid sx={{ mt: 2 }} container spacing={2} columns={{ xs: 5, sm: 9, md: 10 }}>
                {optList.map((item) => (
                    <Grid key={item} item xs={5} sm={3} md={2}>
                        <div>
                            {ListTitle(item.split('n')[1])}
                            {optionData[item].map((item: any, index: number) => (
                                <CarOptionDetailItem
                                    key={`${item.number}_${item.title}_${item.index}`}
                                    info={{
                                        optType: item.optType,
                                        title: item.title,
                                        number: item.number,
                                        index: index,
                                    }}
                                    isUpdate={isUpdate}
                                    handleDrop={handleDrop}
                                    handleRemoveItem={isUpdate ? handleRemoveItem : handleSelectValue}
                                    handleUpdateText={handleUpdateText}
                                />
                            ))}
                            <DropDiv>
                                <DragDiv>
                                    <Input ref={(e) => (optionRef.current[item] = e)} />
                                    <IconBtn onClick={() => handleAddItem(item)}>
                                        <AddCircleOutlineIcon color={'success'} />
                                    </IconBtn>
                                </DragDiv>
                            </DropDiv>
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    color={isUpdate ? 'success' : 'primary'}
                    size={'small'}
                    onClick={() => (isUpdate ? handleCompleteBtn() : setIsUpdate(true))}
                >
                    {isUpdate ? '완료' : '수정'}
                </Button>
                <Button
                    sx={{ mr: 1 }}
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => {
                        resetData();
                        setIsUpdate(false);
                    }}
                >
                    취소
                </Button>
                <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => {
                        optList.forEach((i) => {
                            queryClient.invalidateQueries(i);
                        });
                    }}
                >
                    초기화
                </Button>
            </Box>
            {isDialog && (
                <McDialog
                    title="알림"
                    description={'삭제하시겠습니까?'}
                    handleClose={() => setIsDialog(false)}
                    handleConfirm={handleRemoveItem}
                />
            )}
            {(doSaveCarOpt.isLoading || doSaveCarOptList.isLoading || doDeleteCarOpt.isLoading) && <McBackdrop />}
        </div>
    );
};

export default CarOptionDetail;
