import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tabs, Tab, List, Grid, IconButton, ListItem, TextField, Button, Box } from '@mui/material';
import { customAxios } from 'axios/customAxios';
import McBackdrop from 'component/McBackdrop';
import CarConfigItem from 'carManagement/carConfig/CarConfigItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import arrayMove from 'array-move';
import { throttle } from 'lodash';
import McDialog from 'carManagement/component/McDialog';

const CarConfig = () => {
    const queryClient = useQueryClient();

    const carConfListUrl = `/meetcha/car/manager/getListCarConfValue`;
    const carConfSaveUrl = `/meetcha/car/manager/doSaveCarConf`;

    const carConf = [
        { name: 'car_use', label: '용도' },
        { name: 'car_fuel', label: '연료' },
        { name: 'car_color', label: '색상' },
        { name: 'car_color2', label: '색상+코드' },
        { name: 'car_ym', label: '연식' },
        { name: 'car_price', label: '가격' },
        { name: 'car_gas', label: '배기량' },
        { name: 'car_atmt', label: '기어종류' },
        { name: 'car_mileage', label: '주행거리' },
    ];

    //api 호출시 받아온 데이터를 넣어줄 state
    const [configData, setConfigData] = React.useState<Array<string>>([]);
    //탭 키값 state
    const [value, setValue] = React.useState('car_use');
    //추가할 text 값
    const [addText, setAddText] = React.useState('');
    //수정 상태 체크
    const [isUpdate, setIsUpdate] = React.useState(false);

    const [selectValue, setSelectValue] = React.useState('');

    const [isDialog, setIsDialog] = React.useState(false);

    //api get
    const getListCarConfValue = useQuery([value], () => customAxios.get(`${carConfListUrl}?carConfTitle=${value}`), {
        //활성화 시간 60분
        staleTime: 60 * 60 * 1000,
        retry: false,
        onError: (err) => {
            console.log(err);
        },
    });

    //api post
    const doSaveCarConf = useMutation((param: any) => customAxios.post(`${carConfSaveUrl}`, param), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(value);
        },
    });

    const ArrayToFormData = (data: Array<any>) => {
        const formData: any = new FormData();
        formData.append('carConfTitle', value);
        data.forEach((item) => {
            formData.append('carConfList[]', item);
        });
        return formData;
    };

    /**
     * Drop 이벤트
     * @param startName
     * @param toIndex
     */
    const handleDrop = (startName: string, toIndex: number) => {
        const index: any = configData.findIndex((item: any) => item === startName);
        const confData = arrayMove([...configData], index, toIndex);
        setConfigData(confData);

        if (!isUpdate) {
            doSaveCarConf.mutate(ArrayToFormData(confData));
        }
    };

    /**
     * DragEnd 이벤트
     */
    // const handleDragEnd = () => {
    //     if (!isUpdate) {
    //         doSaveCarConf.mutate(ArrayToFormData(configData));
    //     }
    // };

    /**
     * + (추가) 아이콘 클릭 이벤트
     * @returns
     */
    const handleAddButton = () => {
        const data = [...configData];

        if (data.some((item) => item === addText)) {
            alert('이미 존재하는 옵션입니다. 다시 입력해주세요.');
            return;
        }
        data.push(addText);

        if (isUpdate) {
            setConfigData(data);
        } else {
            doSaveCarConf.mutate(ArrayToFormData(data), { onSuccess: () => setConfigData(data) });
        }
        setAddText('');
    };

    /**
     * - (삭제) 아이콘 클릭 이벤트
     * @param value
     */
    const handleRemoveItem = () => {
        setIsDialog(false);

        const data = [...configData];
        const rmData = data.filter((item) => item !== selectValue);
        if (isUpdate) {
            setConfigData(rmData);
        } else {
            doSaveCarConf.mutate(ArrayToFormData(rmData), { onSuccess: () => setConfigData(rmData) });
        }
    };
    /**
     * 취소 버튼 클릭 이벤트
     */
    const handleCancelBtn = () => {
        if (isUpdate) {
            setConfigData(getListCarConfValue.data!.data);
            setIsUpdate(false);
        }
    };

    /**
     * 	수정 버튼 클릭 -> input 창 blur 이벤트
     * @param index
     * @param value
     */
    const handleConfigDataInValue = (index: number, value: string) => {
        const updateConfData = [...configData];
        updateConfData[index] = value;
        setConfigData(updateConfData);
    };

    const handleShowDialog = (value: string) => {
        setSelectValue(value);
        setIsDialog(true);
    };

    /**
     * 완료 버튼 클릭이벤트
     */
    const handleCompleteBtn = () => {
        const data = [...configData];

        doSaveCarConf.mutate(ArrayToFormData(data), {
            onSuccess: () => {
                setIsUpdate(false);
            },
            onError: () => {
                handleCancelBtn();
            },
        });
    };

    /**
     * 탭 변경 이벤트
     * @param event
     * @param newValue
     */
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setAddText('');
    };

    /**
     * api 호출하여 받은 데이터 값이 변경시 setState
     */
    React.useEffect(() => {
        if (getListCarConfValue.data?.data) {
            setConfigData(getListCarConfValue.data!.data);
        }
    }, [getListCarConfValue.data]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Tabs value={value} onChange={handleChange}>
                {carConf.map((item) => (
                    <Tab
                        key={item.name}
                        sx={{ fontWeight: 'bold', fontSize: 17 }}
                        value={item.name}
                        label={item.label}
                    />
                ))}
            </Tabs>
            <Box>
                <Box sx={{ overflow: 'auto', width: '100%', maxWidth: 360, mt: 2 }}>
                    {/* 옵션 리스트 */}
                    {configData.length
                        ? configData.map((item: string, index: number) => (
                              <CarConfigItem
                                  key={item}
                                  info={{ typeName: value, name: item, index: index }}
                                  isUpdate={isUpdate}
                                  handleConfigDataInValue={handleConfigDataInValue}
                                  handleDrop={handleDrop}
                                  handleShowDialog={handleShowDialog}
                                  handleRemoveItem={handleRemoveItem}
                              />
                          ))
                        : null}
                    {/* 옵션 추가 */}
                    <List
                        disablePadding
                        sx={{ border: 2, borderTop: 1, borderBottom: 1, borderColor: '#f3f3f3', p: 1, pr: 0 }}
                    >
                        <ListItem
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    color="success"
                                    onClick={handleAddButton}
                                    disabled={!Boolean(addText)}
                                >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            }
                        >
                            <TextField
                                sx={{ ml: -2 }}
                                name={`${value}`}
                                value={addText}
                                size="small"
                                fullWidth
                                onChange={(e) => setAddText(e.target.value)}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddButton();
                                    }
                                }}
                            />
                        </ListItem>
                    </List>
                    <List
                        disablePadding
                        sx={{ border: 2, borderTop: 1, borderBottom: 1, borderColor: '#f3f3f3', p: 1, pr: 0 }}
                    >
                        <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Button
                                sx={{ mr: 1 }}
                                variant="contained"
                                color={isUpdate ? 'success' : 'primary'}
                                size={'small'}
                                onClick={() => (isUpdate ? handleCompleteBtn() : setIsUpdate((isUp) => !isUp))}
                            >
                                {isUpdate ? '완료' : '수정'}
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                color={'error'}
                                variant="contained"
                                size={'small'}
                                onClick={handleCancelBtn}
                            >
                                취소
                            </Button>
                            <Button
                                color={'error'}
                                variant="contained"
                                size={'small'}
                                onClick={() => queryClient.invalidateQueries(value)}
                            >
                                초기화
                            </Button>
                        </ListItem>
                    </List>
                </Box>
            </Box>
            {isDialog && (
                <McDialog
                    title="알림"
                    description={'삭제하시겠습니까?'}
                    handleClose={() => setIsDialog(false)}
                    handleConfirm={handleRemoveItem}
                />
            )}
            {(getListCarConfValue.isLoading || getListCarConfValue.isFetching || doSaveCarConf.isLoading) && (
                <McBackdrop />
            )}
        </DndProvider>
    );
};

/* 
	// const configList = useQueries(
    //     carConf.map((conf) => {
    //         return {
    //             queryKey: conf.name,
    //             queryFn: async () => {
    //                 const res: any = await customAxios.get(`${carConfListUrl}?carConfTitle=${conf.name}`);
    //                 //configList.data 객체 안에 queryKey를 넣어줌
    //                 res.queryKey = conf.name;
    //                 return res;
    //             },
    //             //활성화 시간 60분
    //             staleTime: 60 * 60 * 1000,
    //             retry: false,
    //         };
    //     })
    // );
    // const configFilter = (key: string) => {
    //     const result = configList.filter(({ data }: any) => data.queryKey === key);
    //     if (result.length) {
    //         return configList.filter(({ data }: any) => data.queryKey === key)[0].data.data;
    //     } else {
    //         return [];
    //     }
    // };
*/

export default CarConfig;
